import React, { lazy, Suspense } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay-ts";

import { RootState } from "Store/mainStore";
import Home from "Pages/Home";
import { Login } from "Pages/Login";
import { Segment } from "Pages/Segment";
import NotFound from "./NotFound";
import DashboardHome from "features/dashboard/pages/DashboardHome";

const UnAuthorizedPage = lazy(() => import("./UnAuthorized"));

const AppRouter = () => {
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const isAuthorized = authSelector?.system?.isAuthorized;

  if (isAuthorized !== undefined && isAuthorized === false) {
    // The user is not authorized to access the page
    return <Suspense fallback={<LoadingOverlay active={true} spinner text="Loading..." />}>
      <UnAuthorizedPage />
    </Suspense>
  }

  return (
    <LoadingOverlay
      active={authSelector.system.isAuthLoading}
      className="scroll"
      spinner
      classNamePrefix="MyLoader_"
      text="Please wait"
    >
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* // Dashboard rouing */}
          {process.env.REACT_APP_OFFERDASHBOARD === 'true' ? <Route path="/" element={<DashboardHome />} /> : <Route path="/" element={<Home />} />}
          <Route path="/dashboard" element={<DashboardHome />} />

          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/home" element={<Home />} />
          <Route path="/segment" element={<Segment />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </LoadingOverlay>

  );
};

export default AppRouter;
