import React, {useState,useEffect}  from "react";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import { Spinner } from '@nokia-csf-uxr/ccfk';
import {TextInputLabelContent} from "@nokia-csf-uxr/ccfk/TextInput";
import { check } from "yargs";
import { HelpText } from "../hooks/useHelpText";
import _ from "lodash";

interface DataList {
    data: Array<any>,
    active_step: string,
    updateDispatchStore?: Function
    isDisabled:boolean,
    errorMessageText?: Function,
    ref:object
    dataStore?:object
    min?:number,
    max?:number,
    steps?:number
}


const SpinnerField = (props:DataList) => {
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const label = props.data[1].required ?  props.data[1].label + ' *' : props.data[1].label
    const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);
    // readonly used only for spinner field , text field and range field 
    const disableState = props.data[1].readonly ? props.data[1].readonly : props.isDisabled;
    const {
        showHelpText
    } = HelpText();
    useEffect(() => {
        if(props.data[1].required){
            if(props.dataStore){
                if(!_.isEmpty(props.dataStore[props.data[0]])){
                    setErrorDisabled(false)
                }
                else {
                    setErrorDisabled(true)
                }
            }
        }
    },[props.dataStore,errorDisabled])

    const getReturnValue = (item) => {
        let value = 0
        //if(dynamicSelector.data.element[props.active_step]){
        if(props.dataStore){
            if(props.dataStore[item]){
                value = (_.isNumber(props.dataStore[item].key) || _.isString(props.dataStore[item].key)) ? props.dataStore[item].key : props.min;
            }
        }
        return value
    }

    const getKeyvalueData = (dataSet,selectkey) => {
        let selectKeyValue = {}
        selectKeyValue['key'] = selectkey
        selectKeyValue['value'] = selectkey
        props.updateDispatchStore(dataSet,selectKeyValue);
    }

    const checkOnInput = (event) => {
        if(isNaN(event.target.value)){
            event.target.value = ""
            return true
        }
        else{
        if(props.data[1].required){
            if(event.target.value === ""){
                props.updateDispatchStore(props.data[0],"");
            }
            else{
                getKeyvalueData(props.data[0],event.target.value)
            }
        }
        }
    }

    return(
        <>
        <div style={{marginTop:'10px'}}>
            <div style={{display: 'flex'}}>
                <TextInputLabelContent>
                    {label}
                </TextInputLabelContent>
                {showHelpText(props.data)}
            </div>
            <Spinner
                onInput={(e) => {
                    checkOnInput(e)
                }}
                ref={props.ref as React.Ref<HTMLElement>}
                variant='outlined'
                id={props.data[1].id}
                value={getReturnValue(props.data[0])}
                disabled={disableState}
                // min={dynamicSelector.data.elementMMS[props.active_step][props.data[0]] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]]['min']}
                min={props.min}
                // min={props.dataStore[props.data[0]] && (props.dataStore[props.data[0]]['min'])}
                // max={dynamicSelector.data.elementMMS[props.active_step][props.data[0]] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]]['max']}
                max={props.max}
                // max={props.dataStore[props.data[0]] && props.dataStore[props.data[0]]['max']}
                // step={props.data[1].steps !== '' ? dynamicSelector.data.elementMMS[props.active_step][props.data[0]] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]]['steps'] : 1}
                step={props.steps}
                // step={props.data[1].steps !== '' ? props.dataStore[props.data[0]] && props.dataStore[props.data[0]]['steps'] : 1}
                error={errorDisabled}
                inputMode='numeric'
                incrementButtonProps={{ 'aria-label': `Value ${props.dataStore ? props.dataStore[props.data[0]] : 1}. Increment` }}
                decrementButtonProps={{ 'aria-label': `Value ${props.dataStore ? props.dataStore[props.data[0]] : 1}. Decrement` }}
                onChange={(e) => {
                    getKeyvalueData(props.data[0],e.value);
                }}
            />
            {(errorDisabled ? <>{props.errorMessageText(props.data[1].label)}</> : '')}
            </div>
        </>
    )
}

export default SpinnerField;

