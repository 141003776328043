import { RootState, store } from "Store/mainStore";
import { useDispatch, useSelector } from "react-redux";
import { setProp } from "Store/Actions";
import _ from 'lodash';
import { getStateOfElement } from "Components/Logics/stateMachine/modules/CONFIG/configurator_statelogics";
import { useDataCallBack } from "./useDataCallback";
import { useEffect, useState } from "react";
import { useConfigurator } from "./useConfigurator";
import { setElement } from "Store/contentState/configurationArea/dynamicSlice";
import { all } from "axios";

export const useStatesOfElement = (allRefs) => {
  const dispatch = useDispatch();
  const {
    getDataCallback
  } = useDataCallBack(allRefs);
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  // const [defaultcallbackValue, setDefaultCallbackValue] = useState(null);
  const { getListOfFields } = useConfigurator();
  let field_ids = [];

  const getModalData = (obj, key) => {
    if (_.has(obj, key)) {
      return _.get(obj, key);
    }
    return _.reduce(obj, (result, value) => {
      if (result) return result; // If found, return immediately
      if (_.isObject(value)) {
        return getModalData(value, key);
      }
      return result;
    }, null);
  }

  const getElementsStates = (fieldData, renderConfig?,activePackages?) => {
    let modalData = {};
    if (renderConfig && renderConfig.dataStore) {
      // get the modal data of current config step and append to state check fields
      modalData = renderConfig.dataStore
    }
    const reduxStore = store.getState();
    const allfieldData = getListOfFields(reduxStore.dynamic.data.element);
    const allfieldSelectedOptions = { ...allfieldData, ...modalData };
    let dynamicElementFieldData = null;
    if (reduxStore.dynamic.data.element[configstepperSelector.data.active_step]) {
      dynamicElementFieldData = reduxStore.dynamic.data.element[configstepperSelector.data.active_step][fieldData.id];
    }
    const allStates = getStateOfElement(fieldData, allfieldSelectedOptions, dynamicElementFieldData);
    const elementStates = getElementStyle(allStates, fieldData, allfieldSelectedOptions, reduxStore,activePackages);
    return elementStates;
  }

  const getElementStyle = (allStates, fieldData, allfieldData, reduxStore,activePackages) => {
    const fieldtype = fieldData.type;
    const field_id = fieldData.id;
    const defaultcallbackValue = store.getState().dynamic.data?.callbackdefaults?.[configstepperSelector.data.active_step];
    // const parentsOfField = _.uniq(_.map(_.flatten(_.map(fieldData.states?.value,'state_details')),'field_name'));
    // console.log(parentsOfField)
    // whenever the display is none then clear the state data from store for that respective field
    if (allStates.visibility === 'none' && reduxStore.dynamic.data.element[configstepperSelector.data.active_step] && reduxStore.dynamic.data.element[configstepperSelector.data.active_step][field_id] !== "" && fieldtype !== 'checkbox') {
      if (!_.includes(['number', 'range'], fieldtype)) {
        const storeDynamicElement = _.cloneDeep(reduxStore.dynamic.data.element);
        // make the field empty only if the field is present in the active step, else it will create a new field
        if (storeDynamicElement[configstepperSelector.data.active_step][field_id] && storeDynamicElement[configstepperSelector.data.active_step][field_id] !== "") {
          storeDynamicElement[configstepperSelector.data.active_step][field_id] = "";
          dispatch(setElement(storeDynamicElement));
        }
        // make the field value null only when its present and some value is there in the field
        if (reduxStore.dynamic.data.callbackdefaults[configstepperSelector.data.active_step] && reduxStore.dynamic.data.callbackdefaults[configstepperSelector.data.active_step][field_id] !== null) {
          dispatch(setProp({ slice: "dynamic", key: `callbackdefaults.${configstepperSelector.data.active_step}.${[field_id]}`, value: null }));
        }
      }
    } else if (allStates.visibility !== 'none') {
      // if element is visible and has default value set it to dynamic element
      const storeDynamicElement = _.cloneDeep(reduxStore.dynamic.data.element);
      if (fieldData.default !== "" && storeDynamicElement[configstepperSelector.data.active_step] && storeDynamicElement[configstepperSelector.data.active_step][field_id] === "") {
        const defaultData = { key: fieldData.default, value: fieldData.default };
        storeDynamicElement[configstepperSelector.data.active_step][field_id] = defaultData;
        dispatch(setElement(storeDynamicElement));
      }
    }

    let elementStates = {
      "visibility": { "display": allStates.visibility },
      "required": allStates['required'],
      "readonly": allStates['readonly'],
      "visibleValue": allStates.visibility,
      "disabled": allStates.disabled
    };

    // todo : KP - checking this undefined is calling 3 times the api , removing is not calling once
    if ((allStates.defaultValue !== "" && dynamicSelector.data.element[configstepperSelector.data.active_step] && dynamicSelector.data.element[configstepperSelector.data.active_step][field_id] !== undefined)) {
      // reset when undo is done so that we can again have a callback for default
      if (allStates.defaultValue && allStates.defaultValue.state_callback && (!defaultcallbackValue || _.includes([null,undefined],defaultcallbackValue?.[field_id]))  && typeof (defaultcallbackValue?.[field_id]) === 'number') {
        setDefaultCallbackValue(field_id,null);
        field_ids = [];
      }
      if (allStates?.visibility !== 'none' && allStates?.defaultValue?.state_callback && !field_ids.includes(field_id) && (!defaultcallbackValue || _.includes([null,undefined],defaultcallbackValue[field_id]))) {
        const defaultval = getdefaultValue(allStates.defaultValue.state_callback, fieldData,allfieldData, field_id,activePackages);
        if (defaultval !== null) {
          setDefaultCallbackValue(field_id,defaultval);
          elementStates["defaultValue"] = defaultval;
        }
      }
      else if (checkDefaultValueConditions(allStates, defaultcallbackValue, field_id)) {
        // if the dynamic ele value is null and defaultvalue condition is set then set the default value
        elementStates["defaultValue"] = allStates.defaultValue;
        setDefaultCallbackValue(field_id,allStates.defaultValue);
      }
      // commented now as we are not going this solution
      // else if (dynamicSelector.data.element[configstepperSelector.data.active_step][field_id].value && allStates.defaultValue === 'null') {
      //   // if the dynamic ele value is not null and defaultvalue condition is false then set the default value to null
      //   elementStates["defaultValue"] = allStates.defaultValue;
      // }
    }

    return elementStates;
  }

  // check if the field is already present in redux with the default value set
  // if not then set the default value in redux with other fields default value and update the redux store
  const setDefaultCallbackValue = (field_id,defaultValue) => {
    dispatch(setProp({ slice: "dynamic", key: `callbackdefaults.${configstepperSelector.data.active_step}.${[field_id]}`, value: defaultValue }));

  }

  const checkDefaultValueConditions = (allStates, defaultcallbackValue, field_id) => {
    const hasDefaultValue = allStates.defaultValue && _.includes(['string', 'number'], typeof allStates.defaultValue);
    const hasCallbackValue = !defaultcallbackValue || !defaultcallbackValue[field_id];
    //check if previous redux store value if not equal to default value to be set, this is required so that second time onwards we need to set the default value
    const prevCurrentValues = (defaultcallbackValue && defaultcallbackValue[field_id]) !== allStates.defaultValue;
    return hasDefaultValue && (hasCallbackValue || prevCurrentValues);
  };

  const getdefaultValue = async (stateDetails, fieldData,allfieldData, field_id,activePackages) => {
    const callbackdata = { 'data_callback': stateDetails, 'fieldType' : fieldData.type };
    const argumentValueList = _.isEmpty(dynamicSelector?.data?.dataSet) ? getArgumentValues(stateDetails,allfieldData,activePackages) : dynamicSelector?.data?.dataSet;
    if(argumentValueList === 'NA') {
      return null;
    }
    const postdata = { 'data': argumentValueList };

    // attach the formula if present in the callback details
    if (stateDetails.payload && stateDetails.payload.formula) {
      postdata['formula'] = stateDetails.payload.formula;
    }

    if (field_ids.includes(field_id)) {
      return store.getState().dynamic.data.callbackdefaults[configstepperSelector.data.active_step][field_id] ? store.getState().dynamic.data.callbackdefaults[configstepperSelector.data.active_step][field_id] : undefined;
      //store.getState().dynamic.data.defaultcallbackValue[field_id];
    }

    field_ids.push(field_id);
    const defaultFlag = true;

    return getDataCallback(callbackdata, allfieldData, postdata, defaultFlag).then((resultval) => {
      let val = store.getState().dynamic.data.callbackdefaults[configstepperSelector.data.active_step][field_id]  ? store.getState().dynamic.data.callbackdefaults[configstepperSelector.data.active_step][field_id] : ( callbackdata['fieldType'] === 'number' ? 1 : 0 );
      const parsedRes = resultval && parseInt(resultval.data);
      // if its not a number then assign whatever in response - for other than toggle default value
      if (_.isNaN(parsedRes)) {
        /* when all the args count is 0 , we are getting different format of data
        this has to be handled in configurator to send same response when there is 
        count or not there count we have to get same format. As of now its handled as
        if the format is different and has 0 value then set as 1 */
        val = (resultval.data).hasOwnProperty('data') ? (resultval.data.data === 0 && callbackdata['fieldType'] === 'number' ? 1 : resultval.data.data) : resultval.data;
      } else {
        val = parsedRes > 0 ? resultval.data : val;
      }
      if (!_.includes([null,undefined],val)) {
        setDefaultCallbackValue(field_id,val);
        // setDefaultCallbackValue(prevState => ({
        //   ...prevState,
        //   [field_id]: val
        // }));
        dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[field_id]}`, value: { 'key': val, value: val } }));

        // set the default value other than minval
        // dispatch(setProp({ slice: "dynamic", key: `callbackdefaults.${configstepperSelector.data.active_step}.${[field_id]}`, value: val }));
      }
      return val;
    });
  }

  // get payload argument values from allfield data when dataset is empty. That means the arguments are in the same step and has not been added to dataset in add to cart.
  const getArgumentValues = (stateDetails,allfieldData,activePackages) => {
    const payloadFormula = stateDetails.payload.formula;
    let regex = /['"]([^'"]+)['"]/g;
    let matches = [];
    let match;
    
    while ((match = regex.exec(payloadFormula)) !== null) {
      matches.push(match[1]);  // Push the value inside the quotes to the array
    }

    const arg_val_json = {};
    const argumentVals = matches.map((arg) => {
      arg_val_json[arg] = allfieldData[arg].value;
      return allfieldData[arg].value;
    });

    const allValuesPresent = argumentVals.every(value => value !== null && value !== undefined && value !== '');
    if(allValuesPresent) {
      const visibilityOfArgumentFields = matches.map((arg) => {
        const allStates = getStateOfElement(activePackages[arg], allfieldData);
        return allStates.visibility;
      });
      const allFieldsAreVisible = visibilityOfArgumentFields.every(value => value !== 'none');
      return allFieldsAreVisible ? arg_val_json : 'NA';
    }
    return {};
  }

  return {
    getElementsStates
  };
}