import React from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { setProp } from "Store/Actions";
import { setValidationMessages } from "Store/general/auth";
import { store } from "Store/mainStore";
import { useConfigurator } from "./useConfigurator";

export const useShowValidateMessages = () => {
  const dispatch = useDispatch();
  const { getPackageFieldList } = useConfigurator();

  const showValidateMessages = (validateDetails) => {
    const warnings = [];
    const info = [];
    const errors = [];
    let nextDisabled = undefined;
    let dispatchFlags = [];

    validateDetails.forEach((detail) => {
      if (detail.type === "warning") {
        warnings.push(detail.message);
      } else if (detail.type === "information") {
        info.push(detail.message);
      } else {
        errors.push(detail.message);
      }
    });

    const warningData = displayMessages(warnings);
    dispatchFlags.push(dispatchMessage(warnings, warningData, "warn"));

    const infoData = displayMessages(info);
    dispatchFlags.push(dispatchMessage(info, infoData, "info"));

    const errorData = displayMessages(errors);
    dispatchFlags.push(dispatchMessage(errors, errorData, "error"));

    if (errors.length > 0) {
      nextDisabled = true;
    }
    if (dispatchFlags.every((flag) => flag)) {
      return nextDisabled;
    }
    // return nextDisabled;
  };

  const displayMessages = (messages) => {
    const listItems = messages.map((message, index) => (
      <li key={index}>{message}</li>
    ));

    return <ul>{listItems}</ul>;
  };

  const dispatchMessage = (messages, data, type) => {
    messages.length > 0 &&
      dispatch(
        setValidationMessages({
          key: type,
          value: {
            errorMsg: data,
          },
        })
      );
    return true;
  };

  /**
   * Sets the validation message state by categorizing validation messages into warnings, information, and errors.
   * It updates the notification messages in the dynamic store based on the current step and substep.
   *
   * @param {Array} validationMessages - The list of validation messages to be processed.
   * @returns {boolean | undefined} - Returns true if there are errors and all dispatches are successful, otherwise undefined.
   */
  const setValidationMessageState = (validationMessages) => {
    // Declare the variable and arrays to store the status and validation messages
    // Get the current state
    const reduxStore = store.getState();
    const dynamicSelector = reduxStore.dynamic;
    const configstepperSelector = reduxStore.config_stepper;

    const warnings = [];
    const info = [];
    const errors = [];
    let nextDisabled = undefined;
    let dispatchFlags = [];

    // Separate the validation messages based on the type
    validationMessages.forEach((detail) => {
      if (detail.type === "warning") {
        warnings.push(detail);
      } else if (detail.type === "information") {
        info.push(detail);
      } else {
        errors.push(detail.message);
      }
    });

    // Handle the error merssages and
    // Set the nextDisabled flag to true if there are errors
    if(errors.length > 0){
      nextDisabled = true;
      const errorData = displayMessages(errors);
      dispatchMessage(errors, errorData, "error");
    }
    
    // Prepare the notification messages stepwise and substepwise and keep it in the dynamic store
    const notification_messages = _.cloneDeep(dynamicSelector?.data?.notification_messages);
    // Get the current step
    const currentStep = configstepperSelector?.data?.stepper_data?.find(
      (item) =>
        item.stepname === configstepperSelector?.data?.active_step &&
        item.status === "current"
    );
    // Instead of taking activeSubStep from the state, validate with currentStep and then take the activeSubStep
    // This is to avoid the stale state value
    const active_step = configstepperSelector?.data?.active_step;
    // Check whether current step has sub step and get the activeSubStep
    const activeSubStep =
      currentStep?.["subSteps"]?.length > 0 &&
      currentStep?.["isChildActive"] === true
        ? configstepperSelector?.data?.active_substep
        : undefined;

    // Initialize the notification messages for the step if not available
    if (!notification_messages[active_step]) {
      notification_messages[active_step] = {};
    }

    // Set the the notification messages for step incase of no substep
    if (active_step && !activeSubStep) {
      notification_messages[active_step]["warn"] = warnings;
      notification_messages[active_step]["info"] = info;
    }

    // Set the the notification messages for substep incase of substep
    if (active_step && activeSubStep) {
      notification_messages[active_step][activeSubStep] = {};
      notification_messages[active_step][activeSubStep]["warn"] = warnings;
      notification_messages[active_step][activeSubStep]["info"] = info;
    }

    // Dispatch the notification messages to the dynamic store
    dispatch(
      setProp({
        slice: "dynamic",
        key: "notification_messages",
        value: notification_messages,
      })
    );

    // Return the next button status
    return nextDisabled;
  };

  /**
   * Triggers validation toast notifications based on the current step and substep in the configuration process.
   * It processes warning and information messages, ensuring they are relevant to the current step's fields,
   * and dispatches them accordingly.
   */
  const triggerValidationToast = () => {
    // Get the current state
    const reduxStore = store.getState();
    const dynamicSelector = reduxStore.dynamic;
    const configstepperSelector = reduxStore.config_stepper;

    // Ensure safe access by providing empty arrays as defaults
    const notificationMessages =
      dynamicSelector?.data?.notification_messages?.[
        configstepperSelector.data.active_step
      ];

    let warnings = notificationMessages?.warn || [];
    let informations = notificationMessages?.info || [];

    // Check whether current step has sub step
    const currentStep = configstepperSelector.data.stepper_data.find(
      (item) =>
        item.stepname === configstepperSelector.data.active_step &&
        item.status === "current"
    );
    // Instead of taking activeSubStep from the state, validate with currentStep and then take the activeSubStep
    // This is to avoid the stale state value
    const activeSubStep =
      currentStep?.["subSteps"]?.length > 0 &&
      currentStep?.["isChildActive"] === true
        ? configstepperSelector.data.active_substep
        : undefined;

    // Get the warnings and informations from the notification messages from the substep if activeSubStep available
    // Else get the warnings and informations from the notification messages from the step
    if (activeSubStep) {
      warnings = [
        ...warnings,
        ...(notificationMessages?.[activeSubStep]?.warn || []),
      ];
      informations = [
        ...informations,
        ...(notificationMessages?.[activeSubStep]?.info || []),
      ];
    }

    // Process in order: First "warn", then "info"
    const orderedNotifications = [...warnings, ...informations];

    // Get allthe fields of the current step
    const currentFieldList = getPackageFieldList(
      configstepperSelector.data.active_step
    );
    // Initialize the arrays to store the warnings and informations
    const warn = [];
    const info = [];
    const currentFieldListIds = Object.keys(currentFieldList);
    // Loop through the ordered notifications
    orderedNotifications.forEach((notification) => {
      // Check if the notification.target is in the current step's field list
      const isValidTarget =
        notification.target &&
        notification.target.every((element) =>
          currentFieldListIds.includes(element)
        );
      // Check if the notification is valid
      if (isValidTarget) {
        // Push the notification message
        if (notification.type === "warning") {
          warn.push(notification.message);
        } else if (notification.type === "information") {
          info.push(notification.message);
        }
      }
    });

    // Process the warnings
    if (warn.length > 0) {
      const warningData = displayMessages(warn);
      dispatchMessage(warn, warningData, "warn");
    }

    // Process the informations
    if (info.length > 0) {
      const infoData = displayMessages(info);
      dispatchMessage(info, infoData, "info");
    }
  };


  return {
    showValidateMessages,
    setValidationMessageState,
    triggerValidationToast,
  };
};
