import React, { useEffect } from "react";
import {
  AppBody,
  AppContent,
  AppContentWrapper,
} from "@nokia-csf-uxr/ccfk/App";
import DotHeader from "Components/Header";
import "../styles/Dashboard.css";
import _ from "lodash";

import HeaderToolbar from "features/dashboard/components/Common/HeaderToolbar";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, store } from "Store/mainStore";
import CaseContent from "./CaseContent";
import CasePagination from "./CasePagination";
// import { fetchUsers } from "../services/dashboaardThunks";
import { fetchOffer } from "../services/dashboaardThunks";
import { LandingPage } from "features/dashboard/components/Common/LandingPage";
import { useAuthState } from "Components/Logics/ensoCommonFunctions";
import AlertDialogModal from "App/AlertDialogModal";

const DashboardHome = () => {
  useAuthState();
  // Sectors
  const dashboardSelector = useSelector((state: RootState) => state.dashboard);
  // Hooks
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (_.includes(['all_cases','my_cases'],dashboardSelector.data?.caseType) && dashboardSelector.data?.["selectedSortOption"]?.["key"]) {
      const offerFilterObj = {
        offerType: dashboardSelector.data?.filterTab,
        caseType: dashboardSelector.data?.caseType,
        itemsPerPage: dashboardSelector.data?.pagination?.["itemsPerPage"],
        currentPage: dashboardSelector.data?.pagination?.["currentPage"],
        sortOption: dashboardSelector.data?.["selectedSortOption"]?.["key"],
        offerSearch: dashboardSelector.data?.["offerSearch"],
      };
      dispatch(fetchOffer(offerFilterObj));
    }
  }, [
    dispatch,
    // dashboardSelector.data?.caseType,
    // dashboardSelector.data?.filterTab,
    dashboardSelector.data?.pagination?.["itemsPerPage"],
    dashboardSelector.data?.pagination?.["currentPage"],
    dashboardSelector.data?.["selectedSortOption"]?.["key"],
    dashboardSelector.data?.["offerSearch"],
  ]);

  return (
    <div>
      <DotHeader />
      <AppBody>
        <AppContentWrapper
          style={{
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <HeaderToolbar />
          <AppContent
            style={{
              padding: "0",
              width: "100%",
              overflow: "hidden",
            }}
          >
            {!dashboardSelector.data?.caseType && (
              <LandingPage />
            )}

            {dashboardSelector.data?.caseType && (
              <>
                <CaseContent />
                <CasePagination />
              </>
            )}
            <AlertDialogModal />
          </AppContent>
        </AppContentWrapper>
      </AppBody>
    </div>
  );
};

export default DashboardHome;
