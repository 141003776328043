import { createSlice, unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import { fetchOffer, updateProperties } from "../services/dashboaardThunks";
import { FILTER_TOOLBAR_TABS, OFFER_STTATUS } from "../utils/consstants";

//
type TcaseAcctions = {
  action: string;
  selectedOffer: {};
};
interface DashboardState {
  data: {
    caseType: string | undefined;
    filterTab: string | undefined;
    viewType: string;
    offerSearch: string | undefined;
    pagination: {};
    caseResults: {} | undefined;
    caseActions: TcaseAcctions | {};
    isLoaderDialogOpen: boolean;
  };
  loading: boolean;
  error: string | null;
  users: string[];
}

const initialState: DashboardState = {
  data: {
    caseType: undefined,
    filterTab:
      FILTER_TOOLBAR_TABS[0] && FILTER_TOOLBAR_TABS[0].id
        ? FILTER_TOOLBAR_TABS[0].id
        : undefined,
    viewType: "grid",
    offerSearch: undefined,
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
    },
    caseResults: {},
    caseActions: {},
    isLoaderDialogOpen: false,
  },
  loading: false,
  error: null,
  users: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setProperty(state, action) {
      state.data[action.payload.key] = action.payload.value;
    },
    updateBookmark(state, action) {
      if (!state.data.caseResults) {
        state.data.caseResults = {};
      }
      if (!state.data.caseResults[action.payload.statusId]) {
        state.data.caseResults[action.payload.statusId] = { data: {} };
      }
      const index = state.data.caseResults[action.payload.statusId].data.findIndex(
        (obj) => obj.offer_id === action.payload.offerId
      );
      if(index !== -1 && state.data.caseResults && state.data.caseResults[action.payload.statusId] && state.data.caseResults[action.payload.statusId].data){
        state.data.caseResults[action.payload.statusId].data[index]["bookmark"] = action.payload.bookmark;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffer.pending || updateProperties.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOffer.fulfilled, (state, action) => {
        state.loading = false;
        state.data.caseResults = action.payload;
      })
      .addCase(updateProperties.fulfilled, (state, action) => {
        state.loading = false;
        const cartSummary = action.payload['cart'].cart_summary;
        const offerId = action.payload['offer_id'] ? action.payload['offer_id'] :action.payload['temp_offer_id'];
        console.log(offerId);
        const caseResults = _.cloneDeep(state.data.caseResults);
        const offer = _.find(_.flattenDeep(_.map(_.values(caseResults), 'data')), { offer_id: offerId });
        if (offer) {
          offer['cartSummaryData'] = cartSummary;
          offer['caseName'] = cartSummary.caseName;
          offer['sCRMID'] = cartSummary.sCRMID;
          offer['co_owner'] = cartSummary.co_owner;
          offer['summary'] = cartSummary.summary;
        }
        state.data.caseResults = caseResults;
      })
      .addCase(fetchOffer.rejected || updateProperties.rejected, (state, action) => {
        state.loading = false;
        state.error = "true";
      });
  },
});

const processOfferData = (data) => {
  console.log("----", data);
  let filteredData = {};
  if (data.length > 0) {
    filteredData = data.reduce((acc, item) => {
      const key = Object.keys(item)[0];
      acc[key] = item[key];
      return acc;
    }, {});
  }
  console.log("filteredData", filteredData);
  return filteredData;
};

export const { setProperty,updateBookmark } = dashboardSlice.actions;

export default dashboardSlice.reducer;
