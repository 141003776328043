import React, {  } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { RootState } from "Store/mainStore";
import { useConfigurator } from "../hooks/useConfigurator";
import RadioSolution from "./RadioSolution";
import { useLocalStateManagement } from "../hooks/useLocalStateManagement";
import PageInlineFeedback from "../Components/PageInlineFeedback";
import { useRenderDynamicElements } from "../hooks/useRenderDynamicElements";
import { useProductDialogModal } from "../hooks/useProductDialogModal";
import { mpwSolutionConfig } from "./Config";

interface IMPW {
  getSwitchStatement?: Function;
  optionsData?: Object;
  // setOptionsData?: Function;
  renderActionButton?: Function;
  allRefs:any
  api_data_call : Object
}

const MPW = React.forwardRef(({ renderActionButton, allRefs, api_data_call }: IMPW) => {
  // Selectors
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  
  // hooks
  const {
    getPackageFieldList,
    preparePackageElementContent,
    parseContainerElements,
  } = useConfigurator();
  const dispatch = useDispatch();
  const { getState } = useLocalStateManagement();
  const { renderDynamicContent } = useRenderDynamicElements(allRefs);

  // local variables
  const activeStep = configstepperSelector.data.active_step;
  const activeSubStep = configstepperSelector.data.active_substep;
  let deviceBlock = {};
  const packageData = getPackageFieldList(activeStep);
  
  /** getBlockContent */
  const getBlockContent = (packageDataDetails) => {
    const { dB, jD } = preparePackageElementContent(packageDataDetails);
    deviceBlock = { ...dB };
    return JSON.parse(JSON.stringify(jD));
  };

  const activePackages = getBlockContent(packageData);
  const {openProductDialogModal} = useProductDialogModal(activePackages,packageData);

  if (
    activeStep === "connectivity" &&
    mpwSolutionConfig.mpwSolutionSteps.includes(activeSubStep)
  ) {
    return <RadioSolution renderActionButton={renderActionButton} allRefs={allRefs}/>;
  }

  if (getState("isLoading")) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <PageInlineFeedback />
      
      {openProductDialogModal(api_data_call)}
      {/** renderDynamicContent */}
      {renderDynamicContent(activePackages)}
      {/** renderDynamicContent */}
      {/** Action button row */}
      {renderActionButton()}
      {/** Action button row */}
    </>
  );
});

MPW.displayName = 'MPW';

export default React.memo(MPW);
