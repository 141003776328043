import axios from "axios";
import { apiTimeout } from "Components/Misc/CaseDetails_System";
import { handleAxiosInterceptorsReponse, showResponseDialog } from "./common";

class DATAMART_CONFIG_API {
  accessToken;
  email;
  idtoken;
  redX;
  configUrl;
  userId;
  constructor() {
    this.interceptRequests();
  }


  /** Init config */
  init({ accessToken, email, idtoken, redX, userId }) {
    if (accessToken && email) {
      this.accessToken = accessToken;
      this.email = email;
      this.idtoken = idtoken;
      this.redX = redX;
      this.userId = userId;
      // this.configUrl = configUrl;
    } else {
      return { msg: "Failed to initiate." };
    }
  }

  // Intercept the requests and responses
  interceptRequests() {
    // Axios request interceptor
    axios.interceptors.response.use((response) => {
      // Handle the response - call common function to handle the response
      return handleAxiosInterceptorsReponse(response, this.redX.dispatch);
    }, (error) => {
      showResponseDialog(this.redX.dispatch, 500, { msg: error.code, panel: { title: 'Failed to connect server', msg: error.message } });
      return Promise.reject(error);
    });
    // AAxios request interceptor
    axios.interceptors.request.use((request) => {
      if (!request?.["skipAuth"]) {
        // Add headers only if `skipAuth` is not set
        request.headers["Content-Type"] = "application/json; charset=UTF-8";
        request.headers["Authorization"] = "Bearer " + this.accessToken;
        delete request.headers["idtoken"];
        request.headers["idtoken"] = this.idtoken;
        request.timeout = 1000 * apiTimeout;
        // request.headers["x-user-id"] = this.userId;
      }
      // Restricting x-user-id to only datamart requests
      // Once proxy server is replaced with data mart, this can be removed and added to all requests
      if (request.url.includes(process.env.REACT_APP_DATAMART)) {
        request.headers["x-user-id"] = this.userId;
      }
      // Return the request
      return request;
    }, (error) => {
      return Promise.reject(error);
    });
  }

  /** saveCartDetails */
  saveCartDetails = async (cartDataList) => {
    let params = `?username=${this.email}`;
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE}/api/datamart/offer/save_to_cart${params}`,
      data: cartDataList
    });
    return res.data
  }

  /** fetchOffer */
  fetchOffer = async (dasboardParam) => {
    let params = `?username=${this.email}`;
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE}/api/datamart/offer/dashboard${params}`,
      data: dasboardParam
    });
    return res.data?.data ? res.data?.data : res.data
  }


  /** edit properties */
  editProperties = async (editPropertiesParam) => {
    let params = `?username=${this.email}`;
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE}/api/datamart/offer/editProperties${params}`,
      data: editPropertiesParam
    });
    return res.data?.data ? res.data?.data : res.data
  }

  /** handleBookmark */
  handleBookmark = async (offer_id, action_type) => {
    let params = `?username=${this.email}`;
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE}/api/datamart/offer/dashboard/bookmark${params}`,
      data: { offer_id, action_type }
    });
    return res.data?.data ? res.data?.data : res.data
  }


 /**
 * Fetches the access role of the current user from the Datamart API.
 * Sends a GET request to the specified endpoint and processes the response
 * to find the user's role based on their user ID. Displays an error dialog
 * if the request fails.
 */
  getUserAccessRole = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DATAMART}/user`
      });

      let result = {}
      // Process the response and return the data
      if (res.data?.data) {
        const data = res.data?.data.find((item) => item.id === this.userId);
        result = data ? data : undefined;
      }
      return result;
    }
    catch (err) {
      showResponseDialog(this.redX.dispatch, err.response.status, { msg: err.code, panel: { title: 'Failed to connect server', msg: err.message } });
    }
  }

}
const DATAMART_API = new DATAMART_CONFIG_API();
export default DATAMART_API;