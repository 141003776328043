import { sCRMRegex } from "Components/Misc/CaseDetails_System";
import { store } from 'Store/mainStore';
import _ from "lodash";
import dotCounters from "Constants/counterVars.json";

const useCommon = () => {
  const reduxStore = store.getState();

  const getGenericStateValue = () => {
    // parameters that are required for eot waterfall api
    const sid = (reduxStore.dynamic.data.sid) ? reduxStore.dynamic.data.sid : "0059ccf0-f9af-4fb6-99b9-8d3dbb554536";
    const market = reduxStore.caseDetails.data.market;
    const currency = reduxStore.caseDetails.data.currency;
    const networkcountry = reduxStore.caseDetails.data.country; // network country
    const scrm_id = !sCRMRegex.test(reduxStore.findCase.data.sCRMID) ? "" : reduxStore.findCase.data.sCRMID;
    const threestar = reduxStore.caseDetails.data.three_star_number;
    const account_name = reduxStore.caseDetails.data.account_name;
    const account_id = reduxStore.caseDetails.data.account_id;
    const sales_channel = reduxStore.caseDetails.data.sales_channel;
    const solutiontype = reduxStore.config_stepper.data.solution_selected;
    return {
      sid,
      market,
      currency,
      country_code: networkcountry,
      networkcountry,
      country: networkcountry,
      scrm_id,
      threestar,
      account_name,
      account_id,
      sales_channel,
      solutiontype
      // [key]: reduxStore[state][key],
    };
  }

  // check if the data starts with dot_count_of
  const startsWithCheck_countOf = (data) => {
    return _.startsWith(data, dotCounters.dot_count_of)
  }

  // check if the data starts with dot_number_of
  const startsWithCheck_number_of = (data) => {
    return _.startsWith(data, dotCounters.dot_number_of)
  }

  // check if the data starts with dot_total_
  const startsWithCheck_total = (data) => {
    return _.startsWith(data, dotCounters.dot_total_)
  }

  // check and add this method to use _consumption check
  // const endsWithConsumption = (data) => {
  //   return _.endsWith(data, dotCounters.dot_consumption)
  // }
  

  return {
    getGenericStateValue,
    startsWithCheck_countOf,
    startsWithCheck_number_of,
    startsWithCheck_total
  };
};

export default useCommon;