import React, { useRef } from "react";
import _ from "lodash";
import { DataGrid, Label } from "@nokia-csf-uxr/ccfk";

const DataGridView = (gridList) => {
  const gridApi = useRef(null);
  // Selectors
  const autoSizeFitAll = (gridOptions) => {
    window["gridOptions"] = gridOptions;
    const allColumnIds = [];
    gridOptions?.columnApi?.getColumns()?.forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridOptions?.columnApi?.autoSizeAllColumns(false);
  };

  return (
    <div id="cart" style={{ width: "100%", height: "100%" }}>
      <div id={gridList.id_key} className="mt mb" key={gridList.id_key}>
        {gridList.label && gridList.label.length > 0 && (
          <Label style={{ fontWeight: "bold" }} className="mb">
            {gridList.label}
          </Label>
        )}
        <DataGrid
          suppressMovableColumns={true}
          isRowDrag={true}
          key={gridList.id_key}
          wrapperProps={{
            style: {
              width: "100%",
            },
          }}
          rowData={gridList.rowData}
          defaultColDef={{
            editable: false,
            sortable: true,
            flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
          }}
          overlayNoRowsTemplate={gridList.overlayNoRowsTemplate}
          isInlineRowActions={gridList.isInlineRowActions}
          domLayout="autoHeight"
          sideBar
          columnDefs={gridList.columnDefs}
          onGridReady={(gridOptions) => {
            gridApi.current = gridOptions.api;
            autoSizeFitAll(gridOptions);
          }}
          onCellFocused={(params) => {
            if (params?.["column"].colId === "rowActionColId") {
              const cellRendererInstance =
                gridApi.current.getCellRendererInstances({
                  rowNodes: [gridApi.current.getRowNode(params?.["rowIndex"])],
                  columns: [params?.["column"]],
                })[0];
              cellRendererInstance.focus();
            }
          }}
          alwaysShowHorizontalScroll={false}
          alwaysShowVerticalScroll={false}
        />
      </div>
    </div>
  );
};

//export default React.memo(DataGridView);
export default DataGridView;
