/**
 * EmptyState component renders a visual representation for empty states in the application.
 * It displays an image, a title, a message, and an optional action button.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.title] - The title text to display. If not provided, the title section is not rendered.
 * @param {string} props.msg - The message text to display.
 * @param {string} [props.actionText] - The text for the action button. If not provided, the button is not rendered.
 * @param {function} [props.actionhref] - The function to call when the action button is clicked. Required if `actionText` is provided.
 * @param {string} [props.src] - The source URL for the image. If not provided, a default image is used.
 *
 * @returns {JSX.Element} The rendered EmptyState component.
 */

import { Button, Typography } from "@nokia-csf-uxr/ccfk";
import emptyBox from "Assets/Icons/emptyBox.svg";
import PropTypes from "prop-types";
import React from "react";

const EmptyState = ({
  title = undefined,
  msg,
  actionText,
  actionhref,
  src,
  description,
}) => {
  return (
    <>
      <div
        style={{
          textAlign: "center",
          margin: "auto",
        }}
      >
        <img alt="nothing-found" src={src ? src : emptyBox} />
        {title && (
          <Typography
            style={{
              color: "var(--g-color-app-banner-primary-content)",
              fontSize: "var(--font-size-medium)",
              fontWeight: "700",
              margin: "20px 0 10px 0",
            }}
          >
            {title}
          </Typography>
        )}
        <p style={{ width: "80%", margin: "1rem auto" }}>
          <Typography>{msg}</Typography>
          {description && (
            <Typography>{description}</Typography>
          )}
        </p>

        {/* <HorizontalDivider  className="m"/> */}
        {actionText && actionhref && (
          <Button variant="call-to-action" autoFocus onClick={actionhref}>
            {actionText}
          </Button>
        )}
      </div>
    </>
  );
};
//EmptyState.displayName = "EmptyState";
export { EmptyState };
EmptyState.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  actionText: PropTypes.string,
  actionhref: PropTypes.func,
  src: PropTypes.string,
  description: PropTypes.element || undefined,
};
