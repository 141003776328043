import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Tooltip } from "@nokia-csf-uxr/ccfk";
import DeleteIcon from "@nokia-csf-uxr/ccfk-assets/legacy/DeleteIcon";
import { RootState } from "Store/mainStore";
import { addCartItem, removeCartItem } from "Store/contentState/cartBoqSlice";
import { setProp } from "Store/Actions";
import ActionBtns from "Components/Elements/ActionBtns";
import QuantityModifier from "Components/Elements/QuantityModifier";
import { isHardwareSLAItem, roundCount } from "Utils";
import useDataGrid from "./useDataGrid";


export const BoqDetails = () => {
  const caseDetailsSelector = useSelector(
    (state: RootState) => state.caseDetails
  );
  const cartBoqSelector = useSelector(
    (state: RootState) => state.undoCartBoq.present
  );
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const businessSelector = useSelector((state: RootState) => state.business);

  const dispatch = useDispatch();
  const { getInlineRowActionsColumnDefs } = useDataGrid();

  const COMMON_BOQ_GRID_COLUMNS = [
    {
      colId: "product_id",
      headerName: "Product ID",
      field: "product_id",
      cellStyle: {
        "border-left": "3px solid",
        "border-left-color": "var(--g-color-app-banner-background)",
      },
      flex: 1,
    },
    {
      colId: "description",
      headerName: "Description",
      field: "product_name",
      flex: 1,
      cellRenderer: (params) =>
        <Tooltip placement="bottom" tooltip={params.value}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
            width: "100%" }}>
            {params.value}
          </div>
        </Tooltip>
    },
    {
      colId: "quantity",
      headerName: "Quantity",
      field: "quantity",
      maxWidth: 150,
      minWidth: cartBoqSelector.data.isBoQEditable ? 130 : 100,
      cellRenderer: (params) =>
        cartBoqSelector.data.isBoQEditable ? (
          <div
            style={{
              width: "140px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {getQuantityGridColumn(params)}
          </div>
        ) : (
          <div style={{ width: "100%", textAlign: "center" }}>
            {params.data?.["quantity"]}
          </div>
        ),
    },
    {
      colId: "list_price",
      headerName: `List Price (${caseDetailsSelector.data.currency})`,
      field: "cost",
      flex: 1,
      cellRenderer: (params) => (
        <div style={{ textAlign: "right" }}>{roundCount(params.value)}</div>
      ),
      headerClass: "ag-right-aligned-header",
    },
  ];

  /**
   * Renders a QuantityModifier component for a given product, allowing the user to update the quantity.
   * Dispatches actions to add or remove items from the cart based on the quantity change.
   *
   * @param {Object} params - The parameters containing the product data and current quantity.
   * @returns {JSX.Element} The rendered QuantityModifier component.
   */
  const getQuantityGridColumn = (params) => {
    return (
      <QuantityModifier
        product={params.data}
        onUpdateQty={(val) =>
          Math.sign(params.value - val) == -1
            ? dispatch(
                addCartItem(
                  new Object({
                    ...params.data,
                    quantity: Math.abs(params.value - val),
                  })
                )
              )
            : dispatch(
                removeCartItem(
                  new Object({
                    ...params.data,
                    quantity: Math.abs(params.value - val),
                  })
                )
              )
        }
        onDecQty={() =>
          dispatch(removeCartItem(new Object({ ...params.data, quantity: 1 })))
        }
        onIncQty={() =>
          dispatch(addCartItem(new Object({ ...params.data, quantity: 1 })))
        }
        value={Math.round(params.value)}
      />
    );
  };

  /**
   * Returns column definitions for a delete action button in a data grid.
   * If the BoQ is editable, it includes a delete button with an onClick handler.
   *
   * @param {string} idKey - The unique identifier key for the delete action.
   * @returns {Object} The column definitions for the delete action button.
   */
  const getDeleteGridColumn = (idKey) => {
    if (!idKey) {
      idKey = `id_${Math.random().toString(36).substring(2, 11)}`;
    }
    return getInlineRowActionsColumnDefs("rowActionColId", [
      {
        id: `delete_${idKey}`,
        ariaLabel: `Delete-action-${idKey}`,
        icon: <DeleteIcon />,
        tooltip: "Delete",
        onClick: (params) => {
          // dispatch action to set the delete flag for deleting the item from the cart
          dispatch(
            setProp({
              slice: "cartBoq",
              key: "boQDeleteKey",
              value: params.data,
            })
          );
        },
      },
    ]);
  };

  /**
   * Decorates the column definitions with a delete column if the BoQ is editable.
   * (JS decorator pattern)
   *
   * @param columnDefs - The array of column definitions.
   * @param columnKey - The key for the delete column.
   * @returns The updated array of column definitions.
   */
  const enableDeleteColumn = (columnDefs, columnKey) => {
    if (
      cartBoqSelector.data.isBoQEditable &&
      cartBoqSelector.data.isBoQEditable === true
    ) {
      const deleteAction = getDeleteGridColumn(columnKey);
      columnDefs.push(deleteAction);
    }
    return columnDefs;
  };

  const cmuBoqGrid = (boqdata) => {
    const hardwareData = boqdata.filter((e) => e.type === "hardware");
    const softwareData = boqdata.filter((e) => e.type === "software");
    let fieldToShow = "product_name";
    let displaySolutionTitle = "Work Item";
    let end_end_servicesData = boqdata.filter(
      (e) => e.type === "end_end_services"
    );

    /**** Important Note  **** 
    if its cmu show title and value as work package , and has to group the servcies on work package 
    ***/
    if (configstepperSelector.data.solution_selected === "cmu") {
      fieldToShow = "work_package";
      displaySolutionTitle = "Work Package";
      end_end_servicesData = get_ServiceDataGroup(
        boqdata.filter((e) => e.type === "end_end_services")
      );
    }
    const product_attached_service_data = get_ServiceDataGroup(
      boqdata.filter((e) => e.type === "product_attached_services")
    );
    const careData = boqdata.filter((e) => e.type === "care");
    const srsData = boqdata.filter((e) => e.type === "srs");

    // Clone the common grid columns
    let cmuGridCommon = _.cloneDeep(COMMON_BOQ_GRID_COLUMNS);
    
    // Make headerName: "SI Code" where colId: "product_id"
    const siCodeColumnIndex = cmuGridCommon.findIndex(
      (col) => col.colId === "product_id"
    );
    cmuGridCommon[siCodeColumnIndex].headerName = "SI Code";

    // Make headerName: `Price (${caseDetailsSelector.data.currency})`, where colId: "list_price"
    const listPriceColumnIndex = cmuGridCommon.findIndex(
      (col) => col.colId === "list_price"
    );
    cmuGridCommon[
      listPriceColumnIndex
    ].headerName = `Price (${caseDetailsSelector.data.currency})`;
    let cmuGridHW = enableDeleteColumn(_.cloneDeep(cmuGridCommon), "hardware");
    
    // Prepare the column definitions for the s/w grid
    let cmuGridSW = enableDeleteColumn(_.cloneDeep(cmuGridCommon), "software");
    // Add duration column after the description column
    let descriptionColumnIndex = cmuGridSW.findIndex(
      (col) => col.colId === "description"
    );
    cmuGridSW.splice(descriptionColumnIndex + 1, 0, {
      colId: "duration",
      headerName: "Duration (Years)",
      field: "duration",
      minWidth: 115,
    });
    
    // Prepare the column definitions for the Care grid
    // Care grid does not have edit/delete functionality
    // let cmuGridCare = enableDeleteColumn(_.cloneDeep(cmuGridCommon), "care");
    let cmuGridCare = _.cloneDeep(cmuGridCommon);
    // Add duration column after the description column
    descriptionColumnIndex = cmuGridCare.findIndex(
      (col) => col.colId === "description"
    );
    //@ts-ignore
    cmuGridCare.splice(descriptionColumnIndex + 1, 0, {
      colId: "duration",
      headerName: "Duration (Years)",
      field: "duration",
      minWidth: 115,
    });

    // Remove the quantity column from the Care grid and add a new quantity column
    let quantityColumnIndex = cmuGridCare.findIndex(
      (col) => col.colId === "quantity"
    );
    // Remove cellRenderer from the quantity column
    cmuGridCare[quantityColumnIndex].cellRenderer = undefined;
    // cmuGridCare.splice(quantityColumnIndex, 1);
    // //@ts-ignore
    // cmuGridCare.splice(quantityColumnIndex, 0, {
    //   colId: "quantity",
    //   headerName: "Quantity",
    //   field: "quantity",
    // });
    
    // Prepare the column definitions for the SRS grid
    // SRS grid does not have edit/delete functionality
    // let cmuGridSRS = enableDeleteColumn(_.cloneDeep(cmuGridCommon), "srs");
    let cmuGridSRS = _.cloneDeep(cmuGridCommon);
    // Add duration column after the description column
    descriptionColumnIndex = cmuGridSRS.findIndex(
      (col) => col.colId === "description"
    );
    // Insert the duration column after the description column
    //@ts-ignore
    cmuGridSRS.splice(descriptionColumnIndex + 1, 0, {
      colId: "duration",
      headerName: "Duration (Years)",
      field: "duration",
      minWidth: 115,
    });

    // Remove the quantity column from the SRS grid and add a new quantity column
    quantityColumnIndex = cmuGridSRS.findIndex(
      (col) => col.colId === "quantity"
    );
    cmuGridSRS[quantityColumnIndex].cellRenderer = undefined;
    // cmuGridSRS.splice(quantityColumnIndex, 1);
    // //@ts-ignore
    // cmuGridSRS.splice(quantityColumnIndex, 0, {
    //   colId: "quantity",
    //   headerName: "Quantity",
    //   field: "quantity",
    // });

    
    
    const cmuGrid = [
      {
        label: "Hardware",
        id_key: "hardware",
        overlayNoRowsTemplate:
          "No Hardware products available for the current selection",
        rowData: hardwareData,
        columnDefs: cmuGridHW,
      },
      {
        label: "Software",
        id_key: "software",
        overlayNoRowsTemplate:
          "No Software products available for the current selection",
        rowData: softwareData,
        columnDefs: cmuGridSW,
      },
      {
        label: "Solution Services",
        id_key: "end_end_services",
        overlayNoRowsTemplate:
          "No Solution services products available for the current selection",
        rowData: end_end_servicesData,
        columnDefs: [
          { headerName: displaySolutionTitle, field: fieldToShow, flex: 3 },
          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            cellRenderer: (params) => (
              <div id={params} style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
        ],
      },
      {
        label: "Product Attached Services",
        id_key: "product_attached_services",
        overlayNoRowsTemplate:
          "No Services products available for the current selection",
        rowData: product_attached_service_data,
        columnDefs: [
          { headerName: "Work Package", field: "work_package", flex: 3 },

          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            minWidth: 115,
            cellRenderer: (params) => (
              <div style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
        ],
      },
      {
        label: "Care",
        id_key: "care",
        overlayNoRowsTemplate:
          "No Care products available for the current selection",
        rowData: careData,
        columnDefs: cmuGridCare
      },
      {
        label: "SRS",
        id_key: "srs",
        overlayNoRowsTemplate: "No SRS available for the current selection",
        rowData: srsData,
        columnDefs: cmuGridSRS,
      },
    ];
    return cmuGrid;
  };

  const servicesGrid = (boqdata) => {
    const services_data = boqdata.filter(
      (e) =>
        e.category !== "sla" &&
        (e.type === "service" || e?.cui_type === "service") &&
        !(isHardwareSLAItem(e))
    );

    let servicesGridColumnDefs = _.cloneDeep(COMMON_BOQ_GRID_COLUMNS);
    servicesGridColumnDefs = enableDeleteColumn(
      servicesGridColumnDefs,
      "services"
    );

    const serviceboq = [
      {
        label: "Services",
        id_key: "services",
        overlayNoRowsTemplate:
          "No Services available for the current selection",
        rowData: services_data,
        columnDefs: servicesGridColumnDefs,
      },
    ];
    return serviceboq;
  };

  const referenceBoqGrid = (boqdata) => {
    const productGrid = productCommonGrid(boqdata);
    const service_grid = servicesGrid(boqdata);
    const sla_warrGrid = slawarrGrid(boqdata);   

    const end_end_servicesData = boqdata.filter(
      (e) => e.type === "end_end_services"
    );

    const solution_servicesGrid = [
      {
        label: "Solution Services",
        id_key: "end_end_services",
        overlayNoRowsTemplate:
          "No Solution services products available for the current selection",
        rowData: end_end_servicesData,
        columnDefs: [
          { headerName: "Work Package", field: "work_package", flex: 3 },
          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            cellRenderer: (params) => (
              <div id={params} style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
        ],
      },
    ];

    const refGrid = [
      ...productGrid,
      ...service_grid,
      ...solution_servicesGrid,
      ...sla_warrGrid,
    ];

    return refGrid;
  };

  const cdmBoqGrid = (boqdata) => {
    return referenceBoqGrid(boqdata);
  };

  const productCommonGrid = (boqdata) => {
    const product_data = boqdata.filter((e) => e.type === "product");
    const subscription_license_data = boqdata.filter(
      (e) => e.category !== "warranty" && e.type === "subscription"
    );

    // Prepare the column definitions for the Subscription grid
    let subscriptionLicenceColDef = _.cloneDeep(COMMON_BOQ_GRID_COLUMNS);
    const descriptionColumnIndex = subscriptionLicenceColDef.findIndex(
      (col) => col.colId === "description"
    );

    // Insert the duration column after the description column
    subscriptionLicenceColDef.splice(descriptionColumnIndex + 1, 0, {
      colId: "duration",
      headerName: "Duration (Months)",
      field: "duration",
      maxWidth: 150,
      minWidth: 100,
      cellRenderer: (params) => (
        <div>
          {params.data.duration}
          {/* {businessSelector.data.view.PTDAC.commercial_model === "capex"
            ? businessSelector.data.view.PTDAC.contract_duration * 12
            : businessSelector.data.view.PTDAC.contract_duration} */}
        </div>
      ),
    });

    const portGrid = [
      {
        label: "Products",
        id_key: "products",
        overlayNoRowsTemplate: "No Product available for the current selection",
        rowData: product_data,
        columnDefs: enableDeleteColumn(
          _.cloneDeep(COMMON_BOQ_GRID_COLUMNS),
          "products"
        ),       
      },
      {
        label: "Subscriptions and licenses",
        id_key: "subscription",
        overlayNoRowsTemplate:
          "No Subscription or License available for the current selection",
        rowData: subscription_license_data,
        columnDefs: enableDeleteColumn(subscriptionLicenceColDef,"subscriptionLicence"),
      },
    ];

    return portGrid;
  };

  const slawarrGrid = (boqdata) => {
    const sla_data = boqdata.filter((e) => e.category === "sla");
    const warranty_data = boqdata.filter(
      (e) => e.category === "warranty" || e.type === "warranty"
    );
   
    let duration_sla = "Duration (Months)";

    // set the title
    duration_sla =
      (businessSelector.data.view.PTDAC.commercial_model === "opex" || businessSelector.data.view.PTDAC.commercial_model === "operating_lease")
        ? "Duration (Months)"
        : "Duration (Years)";

    // warranty duration

    let slaColDef = _.cloneDeep(COMMON_BOQ_GRID_COLUMNS);
    // Find the index of the desciption column
    const descriptionColumnIndex = slaColDef.findIndex(
      (col) => col.colId === "description"
    );
    // Insert the duration column after the description column
    slaColDef.splice(descriptionColumnIndex + 1, 0, {
      colId: "duration",
      headerName: duration_sla,
      field: "duration",
      maxWidth: 150,
      minWidth: 100,
      cellRenderer: (params) => (
        <div>
          {params.data.duration}
        </div>
      ),
    });

    let warrantyColDef = enableDeleteColumn(_.cloneDeep(slaColDef), "warranty");
    let hwSlaColDef = enableDeleteColumn(_.cloneDeep(slaColDef), "hw_sla");
    slaColDef = enableDeleteColumn(slaColDef, "sla");

    // NDAC HW services SLAs 
    const NDAC_HW_SLA = boqdata.filter((e) => isHardwareSLAItem(e));

    const slaWarrGrid = [
      {
        label: "SLA",
        id_key: "sla",
        overlayNoRowsTemplate:
          "No SLA or Warranty products available for the current selection",
        rowData: sla_data,
        columnDefs: slaColDef,
      },
      {
        label: "Warranty",
        id_key: "warranty",
        overlayNoRowsTemplate:
          "No SLA or Warranty products available for the current selection",
        rowData: warranty_data,
        columnDefs: warrantyColDef,
      },
      {
        label: "Hardware SLA",
        id_key: "hardware_sla",
        overlayNoRowsTemplate:
          "No Hardware SLA products available for the current selection",
        rowData: NDAC_HW_SLA,
        columnDefs: hwSlaColDef,
      },
    ];

    return slaWarrGrid;
  };

  const portBoqGrid = (boqdata) => {
    const productGrid = productCommonGrid(boqdata);
    const sla_warrGrid = slawarrGrid(boqdata);
    const service_grid = servicesGrid(boqdata);
    let spares = [];
    const spareData = boqdata.filter((e) => e.type === "spares");
    const end_end_servicesData = boqdata.filter(
      (e) => e.type === "end_end_services"
    );

    if (spareData.length > 0) {
      spares = [
        {
          label: "Spares",
          id_key: "spares",
          overlayNoRowsTemplate:
            "No Spares available for the current selection",
          rowData: spareData,
          columnDefs: [
            {
              headerName: "Product ID",
              field: "cui_product_id",
              cellStyle: {
                borderLeft: "3px solid",
                borderLeftColor: "var(--g-color-app-banner-background)",
              },
              flex: 1,
            },
            { headerName: "Description", field: "product_name", flex: 2 },
            {
              headerName: "Quantity",
              field: "quantity",
              headerClass: "ag-right-aligned-header",
              cellRenderer: (params) => (
                <div style={{ width: "100px" }}>
                  <QuantityModifier
                    product={params.data}
                    onUpdateQty={(val) =>
                      Math.sign(params.value - val) == -1
                        ? dispatch(
                            addCartItem(
                              new Object({
                                ...params.data,
                                quantity: Math.abs(params.value - val),
                              })
                            )
                          )
                        : dispatch(
                            removeCartItem(
                              new Object({
                                ...params.data,
                                quantity: Math.abs(params.value - val),
                              })
                            )
                          )
                    }
                    onDecQty={() =>
                      dispatch(
                        removeCartItem(
                          new Object({ ...params.data, quantity: 1 })
                        )
                      )
                    }
                    onIncQty={() =>
                      dispatch(
                        addCartItem(new Object({ ...params.data, quantity: 1 }))
                      )
                    }
                    value={Math.round(params.value)}
                  />
                </div>
              ),
            },
            {
              headerName: `List Price (${caseDetailsSelector.data.currency})`,
              field: "cost",
              cellRenderer: (params) => (
                <div style={{ textAlign: "right" }}>
                  {roundCount(params.value)}
                </div>
              ),
              headerClass: "ag-right-aligned-header",
            },
            {
              flex: 0.5,
              hide: !true,
              cellRenderer: (params) => (
                <ActionBtns product={params.data} />
              ),
            },
            // { ...getDeleteGridColumn("products") },
          ],
        },
      ];
    }

    const solution_servicesGrid = [
      {
        label: "Solution Services",
        id_key: "end_end_services",
        overlayNoRowsTemplate:
          "No Solution services products available for the current selection",
        rowData: end_end_servicesData,
        columnDefs: [
          { headerName: "Work Package", field: "work_package", flex: 3 },
          {
            headerName: `Price (${caseDetailsSelector.data.currency})`,
            field: "cost",
            cellRenderer: (params) => (
              <div id={params} style={{ textAlign: "right" }}>
                {roundCount(params.value)}
              </div>
            ),
            headerClass: "ag-right-aligned-header",
          },
        ],
      },
    ];
    const portGrid = [
      ...productGrid,
      ...solution_servicesGrid,
      ...service_grid,
      ...sla_warrGrid,
      ...spares,
    ];
    return portGrid;
  };

  const get_ServiceDataGroup = (servicesDataList) => {
    let end_end_services_list = [];
    servicesDataList.forEach((element) => {
      const elemWorkPackage = element.work_package
        ? element.work_package
        : element.workPackage;
      const elemWorkItem = element.work_item
        ? element.work_item
        : element.workItem;
      let aa = elemWorkPackage.split(":");
      let end_end_services_details = {};
      // this cost contains total_pnp_with_incoterm value that is coming from after response calculations in configurator_AddToCart
      end_end_services_details["cost"] = element.cost;
      end_end_services_details["work_package"] = _.trim(aa[0]);
      end_end_services_details["work_item"] = elemWorkItem;
      end_end_services_list.push(end_end_services_details);
    });
    let end_end_groups = {};
    end_end_services_list.forEach((employee) => {
      // let { work_package } = employee;
      if (!end_end_groups[employee.work_package]) {
        end_end_groups[employee.work_package] = [];
      }
      end_end_groups[employee.work_package].push(employee);
    });
    let end_end_servicesData_List = [];
    Object.entries(end_end_groups).map((data, _index) => {
      let data_list = {};
      data_list["work_package"] = data[0];
      data_list["cost"] = end_end_groups[data[0]]
        .map((o) => parseFloat(o.cost))
        .reduce((a, c) => {
          return a + c;
        });
      data_list["work_item"] = end_end_groups[data[0]]
        .map((o) => o.work_item)
        .reduce((a, c) => {
          return a;
        });
      end_end_servicesData_List.push(data_list);
    });
    return end_end_servicesData_List;
  };

  /**
   * Generates the grid configuration for custom added services in the Bill of Quantities (BoQ).
   *
   * @param {Array} boqdata - The array of BoQ data items.
   * @returns {Array} The grid configuration for custom added services.
   */
  const getCustomAddedServicesGrid = (boqdata) => {
    // Prepare Custom: Add services grid
    const custAddServiceGrid = boqdata.filter(
      (e) => e.type === "custom_add_service"
    );
    let custServicesColDef = _.cloneDeep(COMMON_BOQ_GRID_COLUMNS);

    // Add custom delete column
    custServicesColDef.push({
      colId: "delete_custom_add_service",
      headerName: "",
      field: "",
      flex: 1,
      cellRenderer: (params) => (
        <div className="dotCustomDataGridDeleteButtonColWrapper">
          <Tooltip placement="bottom" tooltip={`Delete ${params.data.product_id}`}>
          <DeleteIcon
            onClick={() =>{
              dispatch(
                setProp({
                  slice: "cartBoq",
                  key: "boQDeleteKey",
                  value: params.data,
                })
              )
            }}
          />
          </Tooltip>
        </div>
      ),
      headerClass: "ag-center-header",
    });

    const custServiceGrid = [{
        label: "Custom: Add service",
        id_key: "CustomAddService",
        overlayNoRowsTemplate:
          "No Custom Services are available for the current selection",
        rowData: custAddServiceGrid,
        columnDefs: custServicesColDef,
      },
    ];
    return custServiceGrid;
  };

  const MPWGrid = (boqdata) => {
    // MPW + NDAC boq data
    const productGrid = productCommonGrid(boqdata);
    const sla_warrGrid = slawarrGrid(boqdata);
    const cmuGrid = cmuBoqGrid(boqdata);
    // New gid section for MPW - Custom: Add services
    const custServiceGrid = getCustomAddedServicesGrid(boqdata);
    const refGrid = [
      ...productGrid,
      ...cmuGrid,
      ...sla_warrGrid,
      ...custServiceGrid,
    ];

    return refGrid;
  };

  const get_slaDuration_basedOnCommercialModel = () => {
    const sla_dur = dynamicSelector.data.dataSet[
      "subscription_duration_months_opex"
    ]
      ? dynamicSelector.data.dataSet["subscription_duration_months_opex"]
      : (dynamicSelector.data.dataSet["subscription_duration_years_capex"] ? dynamicSelector.data.dataSet["subscription_duration_years_capex"] : dynamicSelector.data.dataSet["subscription_duration_months_operating_lease"]);
    return sla_dur;
  };

  return {
    portBoqGrid,
    referenceBoqGrid,
    cmuBoqGrid,
    cdmBoqGrid,
    MPWGrid,
    get_slaDuration_basedOnCommercialModel,
  };
};
