import {
  Button,
  ButtonsRow,
  Card,
  Skeleton,
} from "@nokia-csf-uxr/ccfk";
import ChevronLeftIcon from "@nokia-csf-uxr/ccfk-assets/legacy/ChevronLeftIcon";
import ChevronRightIcon from "@nokia-csf-uxr/ccfk-assets/legacy/ChevronRightIcon";
import { ButtonIcon, ButtonText } from "@nokia-csf-uxr/ccfk/Button";
// import { DialogFooter } from "@nokia-csf-uxr/ccfk/Dialog";
import StepForm, {
  Step,
  StepConfigurationArea,
  StepFormArea,
  SubStep,
} from "@nokia-csf-uxr/ccfk/StepForm";
import BusinessIndex from "Components/Content/configurationArea/Business";
import ConfiguratorIndex from "Components/Content/configurationArea/Configurator";
import { showModal } from "Store/general/auth";
import { setDataConfig } from "Store/contentState/configuration_stepperSlice";
import { RootState, store } from "Store/mainStore";
import { serializeFunction } from "Utils";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  identifyMPWRedirect,
  identifySolutionRedirect,
} from "Components/Logics/systemLogics";
import { setProp } from "Store/Actions";
import { setTheme } from "Store/general/theme";
import _ from "lodash";
import { setPTDACTab } from "Store/contentState/stateMachine";
import { useConfigurator } from "Components/Content/configurationArea/Configurator/hooks/useConfigurator";
import { getStateOfElement } from "Components/Logics/stateMachine/modules/CONFIG/configurator_statelogics";
import useDatamart from "Components/Content/configurationArea/Configurator/hooks/useDatamart";
import { cp } from "fs";


enum StepStatus {
  current = "current",
  future = "future",
  error = "error",
  warning = "warning",
  completed = "completed",
}
interface IStepperProps {
  onSubmit: Function;
  onNext: Function;
  onBack: Function;
  isDisable: boolean;
  getStep?: Function;
}
const Stepper = (props: IStepperProps) => {
  const dispatch = useDispatch();
  const businessSelector = useSelector((state: RootState) => state.business);
  const themeSelector = useSelector((state: RootState) => state.theme);
  const configStepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const actionNext = useSelector(
    (state: RootState) => state.dynamic.data.actionNext
  );
  // const authSelector = useSelector((state: RootState) => state.authSlice);
  const isBoQEditable = useSelector(
    (state: RootState) => state.undoCartBoq.present.data.isBoQEditable
  );

  const steps = configStepperSelector.data.stepper_data;
  const stateMachineSelector = useSelector(
    (state: RootState) => state.stateMachine
  );
  const getStepIndex = useCallback(
    (stepName) => steps.findIndex((item) => item.name === stepName),
    [steps]
  );
  const getStep = useCallback(
    (stepName) => steps.find((item) => item.name === stepName),
    [steps]
  );
  const [makeDisable, setDisable] = useState(false);
  const [stepperState, setStepperState] = useState("");
  const [subStepState, setSubStepState] = useState({});

  // let skipSteps = ["devices", "applications"]; //TODO: this has to come dynamically from the api response
  // if (
  //   configStepperSelector.data.solution_selected === "mpw_reference_solution"
  // ) {
  //   skipSteps = ["devices", "applications", "connectivity", "services_mpw"]; //TODO: this has to come dynamically from the api response
  // }
  // Hooks
  const { getListOfFields,handleConfigStepperState } = useConfigurator();
  const { saveBoQInDatamart } = useDatamart();

  useEffect(() => {
    handleConfigStepperState();
  }, [dynamicSelector.data.element]);

  const getConfigurationArea = useCallback(
    (step) => {
      const currentStep = getStep(getCurrentStepName());
      props.getStep({
        index: getStepIndex(getCurrentStepName()),
        step: currentStep,
      });
      let configurationArea = null;
      switch (step) {
        case "Business":
          configurationArea = <BusinessIndex />;
          setDisable(true);
          break;
        default:
          configurationArea = <ConfiguratorIndex />;
          const currentStepIndex = getStepIndex(getCurrentStepName());
          if (currentStepIndex > 1) {
            setDisable(false);
          } else {
            setDisable(true);
          }
      }

      return configurationArea;
    },
    [steps, getStepIndex]
  );
  const NEXT_STEP = "next";
  const PREVIOUS_STEP = "previous";
  const CURRENT_STEP = "current";
  // return the current step name
  const getCurrentStepName = useCallback(() => {
    const currentStepIndex = steps.findIndex(
      (item) => item.status === "current"
    );
    if (currentStepIndex < 0) return steps[steps.length - 1].name;
    return steps[currentStepIndex].name;
  }, [steps]);

  const getIndicatorVariant = (stepName: string): StepStatus => {
    const step = steps[getStepIndex(stepName)];
    if (!step.isValid) return "error" as StepStatus.error;
    if (step.status === "current") return "current" as StepStatus.current;
    if (step.isComplete) return "completed" as StepStatus.completed;

    return step.status as StepStatus;
  };

  /** getSubSteps */
  const getSubSteps = (currentStep) => {
    const childSteps = _.filter(
      steps,
      (obj) =>
        obj.name === currentStep &&
        obj["subSteps"] &&
        obj["subSteps"].length > 0
    );
    if (childSteps && childSteps.length > 0) {
      return childSteps[0]["subSteps"];
    }
    return undefined;
  };

  const currentStepArea = useMemo(
    () => getConfigurationArea(getCurrentStepName()),
    [getConfigurationArea, getCurrentStepName]
  );

  // validate current state and move to next step if validation succeeds
  const handleContinue = async () => {
    const currentStepName = getCurrentStepName();
    // Commenting the below line as Save BOQ in datamart is not temporarIlly required
    const saveBoQResp = await saveBoQInDatamart();
    updateCurrentStep(currentStepName, NEXT_STEP);
    props.onNext();
  };
  // return to previous step
  const handleBack = () => {
    dispatch(
      setTheme({
        element: "button",
        comp: `options_global`,
        prop: "isDisable",
        value: true,
      })
    );
    const currentStepName = getCurrentStepName();
    // Validate step data if this step was previously complete
    // const currentStepIndex = steps.findIndex(item => (item.status === 'current'));

    // if (steps[currentStepIndex].isComplete) {
    //   // const isValidated = validateConfigurationStep(currentStepName);
    //   // if (!isValidated) return;
    // }
    // Set current step complete and set previous step to be next step target
    updateCurrentStep(currentStepName, PREVIOUS_STEP, false);
    dispatch(setProp({ slice: "dynamic", key: "actionNext", value: false }));
    props.onBack();
  };

  const handleActivate = (event) => {
    // validate all steps one last time
    // const isValidated = areaRef && areaRef.current && areaRef.current.validate();
    // if (!isValidated) return;
    // all steps validate successfully, set last step complete
    const currentStepName = getCurrentStepName();
    //updateCurrentStep(currentStepName, CURRENT_STEP);
    // dispatch(setCompleted(true));
    props.onSubmit();
  };



  // Get the next step index
  const getNextStepIndex = (currentStepIndex) => {    
    const currentStep = steps.find((item) => item.name === steps[currentStepIndex]?.name);
    if (!currentStep) return currentStepIndex;
    const nextStep = steps.find((item,stepIndex) => item?.["isHidden"] !== true && stepIndex > currentStepIndex);    
    return steps.findIndex((item) => item.name === nextStep?.name);    
  };

  // Get the previous step index
  const getPreviousStepIndex = (currentStepIndex) => {
    const currentStep = steps.find((item) => item.name === steps[currentStepIndex]?.name);
    if (!currentStep) return currentStepIndex;
    const previousStep = steps
      .slice(0, currentStepIndex) // Get all steps before the current step
      .reverse() // Reverse the order so we search from the most recent step backwards
      .find((item) => item?.["isHidden"] !== true); // Find the first step that is not hidden

    return steps.findIndex((item) => item.name === previousStep?.name); // Return the index of the previous step
  };
  
  
  const getSubStepNumber = (activeStepName,actionType=undefined) => {
    const subSteps = getSubSteps(activeStepName);
    if(!actionType){
      if(subStepState[activeStepName]){
        return subStepState[activeStepName];
      }
      
      if(!subSteps || subSteps.length === 0) return -1;
      // Get the first visible sub step
      const visibleSubSteps = subSteps?.filter(
        (subStep) => subStep?.["isHidden"] !== true
      );
      // Get the first visible sub step - step with minimum step number inside the visible sub steps
      const minStepNumberSubStep = _.minBy(visibleSubSteps, 'stepNumber');
      return minStepNumberSubStep ? minStepNumberSubStep?.["stepNumber"] : -1;
    }
    
    if(actionType === 'first'){
      if(!subSteps || subSteps.length === 0) return -1;
      // Get the first visible sub step
      const visibleSubSteps = subSteps?.filter(
        (subStep) => subStep?.["isHidden"] !== true
      );
      // Get the first visible sub step - step with minimum step number inside the visible sub steps
      const minStepNumberSubStep = _.minBy(visibleSubSteps, 'stepNumber');
      return minStepNumberSubStep ? minStepNumberSubStep?.["stepNumber"] : -1;
    }
    if(actionType === 'next'){
      if(!subStepState[activeStepName]){
        return 1;
      }
      // Apply logic for next sub step
      if(!subSteps || subSteps.length === 0) return 1;

      const activeSubStep = subSteps?.find(
        (subStep) => subStep.status === "current"
      );
      
      const visibleSubSteps = subSteps?.filter(
        (subStep) => subStep?.["isHidden"] !== true
      );
      const nextSubStep = visibleSubSteps.find(
        (subStep, subStepIndex) =>
          subStepIndex > visibleSubSteps.indexOf(activeSubStep)
      );
      return nextSubStep ? nextSubStep.stepNumber : 1;
      // return subSteps.indexOf(nextSubStep); 
    }
    if(actionType === 'prev'){
      if(!subStepState[activeStepName]){
        return 1;
      }
      // Apply logic for prev sub step
      if(!subSteps || subSteps.length === 0) return 1;

      const activeSubStep = subSteps?.find(
        (subStep) => subStep.status === "current"
      );
      const visibleSubSteps = subSteps?.filter(
        (subStep) => subStep?.["isHidden"] !== true
      );
      const prevSubStep = visibleSubSteps.reverse().find(
        (subStep, subStepIndex) =>
          subStep?.stepNumber < subStepState[activeStepName]
      );
      return prevSubStep && prevSubStep?.stepNumber ? prevSubStep.stepNumber : 1;
      // return subSteps.indexOf(prevSubStep);      
    }
    if(actionType === 'last'){
      if(!subSteps || subSteps.length === 0) return -1;
      const visibleSubSteps = subSteps?.filter(
        (subStep) => subStep?.["isHidden"] !== true
      );

      return visibleSubSteps[visibleSubSteps.length - 1].stepNumber;
    }
  };

  /**
   * Retrieves the index of a sub-step based on its step number.
   *
   * @param {string} activeStepName - The name of the active step.
   * @param {number} subStepNumber - The step number of the sub-step.
   * @returns {number} The index of the sub-step.
   */  
  const getSubStepIndexFromStepNumber = (activeStepName,subStepNumber) => {
    const subSteps = getSubSteps(activeStepName);
    const currentSubStep = subSteps.find(
      (subStep) =>
        subStep?.stepNumber === subStepNumber
    );
    const currentSubStepIndex = subSteps.indexOf(currentSubStep);
    return currentSubStepIndex;
  }
 
  // move current status to the appropriate step; only 1 step can be current
  const updateCurrentStep = (
    currentStepName,
    whichStep,
    updateCompletedStatus = true
  ) => {
    const currentStepIndex = getStepIndex(currentStepName);
    // remove status = current from all steps
    const newSteps = steps.map((item) => {
      return {
        ...item,
        status:
          item.name !== currentStepName
            ? item.status
            : updateCompletedStatus
            ? "completed"
            : "future",
        isComplete:
          item.isComplete ||
          (item.name === currentStepName && whichStep === NEXT_STEP),
        selected: false,
      };
    });
    // make sure current step status is set to valid
    newSteps[currentStepIndex].isValid = true;
    // Set the next current step
    let newSelectedStep = currentStepIndex;

    if (whichStep === NEXT_STEP) {
      const activeSubStepCount = getSubStepNumber(newSteps[currentStepIndex]["name"]);
      // Get the visible sub steps
      const visibleSubSteps = newSteps[currentStepIndex]["subSteps"]?.filter(
        (subStep) => subStep?.["isHidden"] !== true
      );
      // last visible sub step number
      const lastSubStep = (visibleSubSteps && visibleSubSteps.length > 0) ? visibleSubSteps[visibleSubSteps.length - 1] : undefined;
      if (
        !newSteps[currentStepIndex]["isChildActive"] ||
        (newSteps[currentStepIndex]["isChildActive"] &&
          lastSubStep && lastSubStep?.stepNumber === activeSubStepCount)
      ) {
        newSelectedStep = getNextStepIndex(currentStepIndex);
        // make the activeSubStepCount as completed
        if(lastSubStep){
          // Get index of last sub step in the current step
          const lastSubStepIndex = newSteps[currentStepIndex]["subSteps"].indexOf(lastSubStep);
          if(lastSubStepIndex > -1){
            const updatedSteps = [...newSteps];
            const updatedSubSteps = [...updatedSteps[currentStepIndex]["subSteps"]];
            updatedSubSteps[lastSubStepIndex] = {
              ...updatedSubSteps[lastSubStepIndex],
              status: "completed",
              selected: false,
            };
            newSteps[currentStepIndex]["subSteps"] = updatedSubSteps;
          }          
        }
      }
      // Implement child step cases logic
      if (
        newSteps[newSelectedStep]["isChildActive"] &&
        newSteps[newSelectedStep]["subSteps"]
      ) {
        newSteps[newSelectedStep].status = "current";
        newSteps[newSelectedStep].selected = true;
        setStepperState(newSteps[newSelectedStep]["name"]);
        // temp
        // ---- Need to make dynamic on NEXT cliks
        let subSteps = [];
        let subStepActive = getSubStepNumber(newSteps[newSelectedStep]["name"],'next');
        
        setSubStepState((currentState) => ({
          ...currentState,
          [newSteps[newSelectedStep]["name"]]: subStepActive,
        }));

        // let substeps_of_previousStep = newSteps[newSelectedStep - 1]["subSteps"] ? [...newSteps[newSelectedStep - 1]["subSteps"]] : [];
        // const prevStepIndex = getPreviousStepIndex(newSelectedStep);

        // Get the first sub step index
        // const firstSubStepNumber = getSubStepNumber(newSteps[newSelectedStep]["name"],'first');
        const lastSubStepNumber = getSubStepNumber(newSteps[newSelectedStep]["name"],'last');

        subSteps = newSteps[newSelectedStep]["subSteps"].map((subStep, i) => {
          const newSubStep = { ...subStep };
          newSubStep.status = "future";
          newSubStep.selected = false;
          // if the current step's previous step has child then make the last substep as completed
          // Below If block is not required as the first item also can be hidden
          // if (
          //   newSubStep.stepNumber === firstSubStepNumber && /// first item also can be hidden ??
          //   newSteps[prevStepIndex]["isChildActive"]
          // ) {
          //   // Remove the active sub step of previous step
          //   // Get the last sub step of previous step
          //   //
          //   console.log("------");
          //   // --- Below logic may not required
          //   //
          //   // const prevSubStepNumber = getSubStepNumber(newSteps[prevStepIndex]["name"],'prev');
          //   // if(prevStepIndex > 1){
          //   //   substeps_of_previousStep[prevSubStepNumber] = {
          //   //     ...substeps_of_previousStep[prevSubStepNumber],
          //   //     status: "completed",
          //   //     selected: false,
          //   //   };
          //   //   substeps_of_previousStep = [...substeps_of_previousStep];
          //   //   // newSteps[newSelectedStep - 1]["subSteps"] = [
          //   //   newSteps[prevStepIndex]["subSteps"] = [
          //   //     ...substeps_of_previousStep,
          //   //   ];
          //   // }
          // }
          if (newSubStep.stepNumber === subStepActive) {
            newSubStep.status = "current";
            newSubStep.selected = true;
            dispatch(
              setDataConfig({
                key: "active_substep",
                value: newSubStep.stepname,
              })
            );
            // set ptdac activestep in state machine
            dispatch(
              setPTDACTab({ index: currentStepIndex, id: newSubStep.stepname })
            );
          } else if (
            newSubStep.stepNumber < subStepActive ||
            // newSteps[newSelectedStep]["subSteps"].length === subStepActive
            lastSubStepNumber === subStepActive
          ) {
            newSubStep.status = "completed";
            newSubStep.selected = false;
          }
          return newSubStep;
        });

        newSteps[newSelectedStep]["subSteps"] = subSteps;
      } else {
        newSteps[newSelectedStep].status = "current";
        newSteps[newSelectedStep].selected = true;
        if (
          newSelectedStep > currentStepIndex &&
          newSteps[currentStepIndex]["isChildActive"]
        ) {
          const __currentActiveSubStep =
            subStepState[newSteps[currentStepIndex]["name"]];
          // Remove the active sub step of previous step
          setSubStepState((currentState) => ({
            ...currentState,
            [newSteps[currentStepIndex]["name"]]: undefined,
          }));

          let currentStepSubSteps = [...newSteps[currentStepIndex]["subSteps"]];

          const prevSubStepNumber = getSubStepNumber(newSteps[currentStepIndex]["name"],'prev');

          currentStepSubSteps[prevSubStepNumber] = {
              ...currentStepSubSteps[prevSubStepNumber],
              status: "completed",
              selected: false,
            };
          newSteps[currentStepIndex]["subSteps"] = currentStepSubSteps;
        }
      }
    } else if (whichStep === PREVIOUS_STEP) {
      dispatch(setDataConfig({ key: "active_substep", value: "" }));
      dispatch(setPTDACTab({ index: newSelectedStep, id: "" }));

      const __currentStepIndex = currentStepIndex;
      const __prevStepIndex = getPreviousStepIndex(currentStepIndex);
      let activeSubStepName = "";
      // Check whether Current Step and Prev step are having sub steps
      if (
        !newSteps[__currentStepIndex]["isChildActive"] &&
        !newSteps[__prevStepIndex]["isChildActive"]
      ) {
        // Normal previous flow - Both steps are not having sub steps
        newSelectedStep = __prevStepIndex;
      } else {
        // if the previous step has substeps
        // Get the last visible sub step number
        let visibleSubSteps = newSteps[__prevStepIndex]["subSteps"]?.filter(
          (subStep) => subStep?.["isHidden"] !== true
        );
        if (
          newSteps[__prevStepIndex]["isChildActive"] &&
          newSteps[__prevStepIndex]["subSteps"] &&
          visibleSubSteps.length > 0
        ) {
          // Handle previous step with sub step
          // Make last sub step as active for prev step          
          // Get the last sub step number from the visible sub steps
          const lastSubStep = visibleSubSteps.length > 0 ? visibleSubSteps[visibleSubSteps.length - 1].stepNumber : undefined;
          // Update local sub step state
          setSubStepState((currentState) => ({
            ...currentState,
            [newSteps[__prevStepIndex].name]: lastSubStep,
          }));

          // Update active sub step state
          let currentStepSubSteps = _.cloneDeep(
            newSteps[__prevStepIndex]["subSteps"]
          ).map((subStep, index) => {
            subStep.status = "completed";
            subStep.selected = false;
            if (subStep?.stepNumber === lastSubStep) {
              // Make last sub step active
              subStep.status = "current";
              subStep.selected = true;
              activeSubStepName = subStep.stepname;
            }
            return subStep;
          });
          // Update stepper state
          newSteps[__prevStepIndex]["subSteps"] = currentStepSubSteps;
          // Make previous step seletion
          newSelectedStep = __prevStepIndex;
        }

        // Current Or Prev step having sub steps
        // Handle current step with sub step
        // Get the visible sub steps
        visibleSubSteps = newSteps[newSelectedStep]["subSteps"]?.filter(
          (subStep) => subStep?.["isHidden"] !== true
        );
        if (
          newSteps[__currentStepIndex]["isChildActive"] &&
          newSteps[__currentStepIndex]["subSteps"] &&
          visibleSubSteps.length > 0
        ) {
          // Current Step having sub step
          // Get active sub step number
          const firstSubStep = getSubStepNumber(newSteps[__currentStepIndex]["name"],'first');
          const activeSubStepCount = subStepState[
            newSteps[__currentStepIndex].name
          ]
            ? subStepState[newSteps[__currentStepIndex].name]
            : firstSubStep;

          if (activeSubStepCount === firstSubStep) {
            // Handle moving back to previous main
            // Unset current step's sub step state
            // Remove the element from local state
            setSubStepState((currentState) => ({
              ...currentState,
              [newSteps[__currentStepIndex].name]: undefined,
            }));

            // Reset active sub step state
            let currentStepSubSteps = [
              ...newSteps[__currentStepIndex]["subSteps"],
            ];
            /// --- need to refactor this logic
            // Get the first sub step index
            const firstSubStepIndex = getSubStepIndexFromStepNumber(newSteps[__currentStepIndex]["name"],firstSubStep);
            currentStepSubSteps[firstSubStepIndex] = {
              ...currentStepSubSteps[firstSubStepIndex],
              status: "completed",
              selected: false,
            };
            if (activeSubStepName === "") {
              activeSubStepName = currentStepSubSteps[firstSubStepIndex].stepname;
            }
            newSteps[__currentStepIndex]["subSteps"] = currentStepSubSteps;
            // Make previous step seletion
            newSelectedStep = __prevStepIndex;
          }
          if (activeSubStepCount > firstSubStep) {
            // Prepare new active sub step
            // Get the previous sub step active from the activeSubStepCount
            const __newSubStepActive = getSubStepNumber(newSteps[__currentStepIndex]["name"],'prev');
            // Update active sub step state
            let currentStepSubSteps = [
              ...newSteps[__currentStepIndex]["subSteps"],
            ];
            // Remove the active sub step of current step
            const currentActiveSubStepIndex = getSubStepIndexFromStepNumber(newSteps[__currentStepIndex]["name"],activeSubStepCount);
            currentStepSubSteps[currentActiveSubStepIndex] = {
              ...currentStepSubSteps[currentActiveSubStepIndex],
              status: "completed",
              selected: false,
            };
            
            // Get previous sub step active from the __newSubStepActive
            // Make prev sub step active
            const __newSubStepActiveIndex = getSubStepIndexFromStepNumber(newSteps[__currentStepIndex]["name"],__newSubStepActive);

            currentStepSubSteps[__newSubStepActiveIndex] = {
              ...currentStepSubSteps[__newSubStepActiveIndex],
              status: "current",
              selected: true,
            };

            activeSubStepName = currentStepSubSteps[__newSubStepActiveIndex].stepname;
            // Update local sub step state
            setSubStepState((currentState) => ({
              ...currentState,
              [newSteps[__currentStepIndex].name]: __newSubStepActive,
            }));

            // Update stepper state
            newSteps[__currentStepIndex]["subSteps"] = currentStepSubSteps;
            // Make previous step seletion
            newSelectedStep = __currentStepIndex;
          }
        }
      }
      // Set the stepper state
      newSteps[newSelectedStep].status = "current";
      newSteps[newSelectedStep].selected = true;
      setStepperState(newSteps[newSelectedStep]["name"]);
      dispatch(
        setDataConfig({ key: "active_substep", value: activeSubStepName })
      );
      dispatch(setPTDACTab({ index: newSelectedStep, id: activeSubStepName }));
    }

    localStorage.setItem("currentStepName", newSteps[newSelectedStep].name);
    const dataConfigData = [
      { key: "stepper_data", value: newSteps },
      { key: "active_step", value: newSteps[newSelectedStep].stepname },
    ];
    dispatch(setDataConfig(dataConfigData));
  };

  

  // Get the active sub step index


  // const getNextStepIndex = (currentStepIndex) => {
  //   const nextStepIndex = currentStepIndex + 1;
  //   if (nextStepIndex >= steps.length) {
  //     return currentStepIndex;
  //   }
  //   return nextStepIndex;
  // };

  

  // const getPreviousStepIndex = (currentStepIndex) => {
  //   const previousStepIndex = currentStepIndex - 1;
  //   if (previousStepIndex < 0) {
  //     return currentStepIndex;
  //   }
  //   return previousStepIndex;
  // };

  /** isCurrentStepStateRequired */
  const isCurrentStepStateRequired = () => {
    let isStepRequired = false;
    const activeStep = configStepperSelector.data.active_step;
    // Enable/disable NEXT button based on state
    // Get active step's state
    const currentStep = steps.find((step) => step.stepname === activeStep);
    const stepState = currentStep["states"] ? currentStep["states"] : undefined;
    if (!stepState || _.size(stepState) === 0) {
      if (currentStep["subSteps"] && _.size(currentStep["subSteps"]) > 0) {
        // Logic for sub step state
        // Get current sub step
        const activeSubStep = configStepperSelector.data.active_substep;
        const currentSubStep = currentStep["subSteps"].find(
          (subStep) => subStep.stepname === activeSubStep
        );
        // Check current sub step's states
        // currentSubStep has items and having key required
        if (
          currentSubStep &&
          currentSubStep["states"] &&
          _.size(currentSubStep["states"]) > 0 &&
          currentSubStep["states"]["required"]
        ) {
          const allfieldData = getListOfFields(dynamicSelector.data.element);
          const allStates = getStateOfElement(
            { ...currentSubStep, required: false },
            allfieldData
          );
          isStepRequired = allStates.required;
        }
      }
    } else {
      // Whether state has a key called required
      if (stepState && stepState["required"]) {
        const allfieldData = getListOfFields(dynamicSelector.data.element);
        const allStates = getStateOfElement(
          { ...currentStep, required: false },
          allfieldData
        );
        isStepRequired = allStates.required;
      }
    }
    return isStepRequired;
  };

  /**
   * Prepares a dynamic suffix string based on the current step or sub-step.
   * This suffix is used to identify and skip the current step or sub-step.
   *
   * @returns {string} The suffix string representing the current step or sub-step.
   */
  const getSkipStepSuffix = () => {
    // Prepare the dynamic appender string from activeStep / activeSubStep
    const currentStepNaame = getCurrentStepName();
    const currentStepObj = getStep(currentStepNaame);
    let skipStepSuffix = currentStepObj?.stepname ? currentStepObj?.stepname : 'INVALID_ACTIVE_STEP';
    // Check whether current step has sub step
    if (!!currentStepObj?.["isChildActive"]) {
      // Get the active sub step
      const currentSubStepObj = currentStepObj?.["subSteps"].find(
        (subStep) => subStep.status === "current"
      );
      if (currentSubStepObj && currentSubStepObj?.stepname) {
        // If sub step is there then skipStepSuffix should be sub step name
        skipStepSuffix = currentSubStepObj?.stepname;
      }
    }
    return skipStepSuffix;
  };

  /** isActionNextDisabled */
  const isActionNextDisabled = () => {
    // Disable the next button in case solution steps are loading
    if (configStepperSelector.data.isStepperLoading) {
      return true;
    }

    // Handle skip current step - skip_current_step_<currentStep / currentSubStep>
    const skipStepSuffix = getSkipStepSuffix();
    const skipStepField = `skip_current_step_${skipStepSuffix}`;
    // Check ${skipStepField} is there in redux dynamic > data > element > <activeSStep>
    // and it is toggled ON then Make the current step Optional
    // Skip the below conditional blocks

    // Get latest store data
    const storeData = store.getState();
    // Get active step
    const activeStep = storeData.config_stepper.data.active_step;
    // Check whether skip field isset and its vaalue is ON
    if (
      storeData.dynamic?.data?.element?.[activeStep]?.[skipStepField]?.[
        "key"
      ] &&
      storeData.dynamic?.data?.element?.[activeStep]?.[skipStepField]?.[
        "key"
      ].toString() === "1"
    ) {
      // Enable NEXT button for the current step // Skip the current step
      return false;
    }
    // ---- end of skip current step logic

    let actionNextDisabled = actionNext;
    if (configStepperSelector.data.isStepperLoading) {
      return true;
    }
    if (
      themeSelector.data.button.isToggle.hasOwnProperty(
        `${stateMachineSelector.PTDAC.activeTab}_undo`
      ) ||
      themeSelector.data.button.isToggle[
        `${stateMachineSelector.PTDAC.activeTab}_undo`
      ]
    ) {
      actionNextDisabled =
        !themeSelector.data.button.isToggle[
          `${stateMachineSelector.PTDAC.activeTab}_undo`
        ];
      if (!actionNextDisabled) {
        // check if validation API flag has error set true/false
        if (
          !_.isEmpty(dynamicSelector.data.validationDetails) &&
          dynamicSelector.data.validationDetails["disableNext"]
        ) {
          return true;
        }

        const isNextButtonEnabled = checkNdacPlannerStatus();
        return isNextButtonEnabled ? false : true;
      }
    }
    // Check current step state is required or optonal
    actionNextDisabled = isCurrentStepStateRequired();

    if (actionNextDisabled) {
      // Do not proceed in case state condtion is true
      return actionNextDisabled;
    }
    const isNextButtonEnabled = checkNdacPlannerStatus();
    actionNextDisabled = actionNextDisabled
      ? actionNextDisabled
      : !isNextButtonEnabled;
    return actionNextDisabled;
  };

  /** checkNdacPlannerStatus */
  const checkNdacPlannerStatus = () => {
    if (
      ["reference_solution", "mining", "manufacturing", "port"].includes(
        businessSelector.data.options.PTDAC.caseIndustry
      ) &&
      configStepperSelector.data.active_step === "connectivity"
    ) {
      // Check whether planner is avaialable and if avaialble plan is linked
      const isPlannerAvailable =
        dynamicSelector.data.reference_solution.ndacPlanner["connectivity"] &&
        dynamicSelector.data.reference_solution.ndacPlanner["connectivity"]
          .length > 0 && typeof dynamicSelector.data.reference_solution.ndacPlanner["connectivity"] !== 'string';

      const linkedPlan = dynamicSelector.data.reference_solution.linkedPlanner
        ? Object.keys(dynamicSelector.data.reference_solution.linkedPlanner)
        : [];
      const isNextButtonEnabled = isPlannerAvailable
        ? linkedPlan.length > 0
        : true;
      return isNextButtonEnabled;
    }
    return true;
  };

  const getStepElements = (item, x) => {
    if (item.isChildActive) {
      return (
        !item.isHidden &&
        <Step
          key={`${item.name}-${x}`}
          variant={getIndicatorVariant(item.name)}
          selected={item.selected}
          stepNumber={item.stepNumber}
          title={item.name == "Review" ? "Review & Submit" : item.name}
          lastStep={x === steps.length - 1}
          isChildActive={item.isChildActive}
          isOpen={stepperState === item.name}
          disabled={item.isDisabled}
          // isOpen={isOpen}
          // onClick={()=> setIsOpen((prevState)=>!prevState)}
          onClick={() =>
            setStepperState((prevSelection) =>
              prevSelection === item.name ? "" : item.name
            )
          }
        >
          {item.subSteps.map((child) => (
            !child.isHidden &&
            <SubStep
              key={`${item.name}-${child.name}`}
              variant={child.status}
              title={child.name}
              selected={child.selected}
              disabled={child.isDisabled}
              internalStepProps={{
                role: "option",
                "aria-selected": child.selected,
              }}
            />
          ))}
        </Step>
      );
    }
    return (
      !item.isHidden &&
      <Step
        key={`${item.name}-${x}`}
        variant={getIndicatorVariant(item.name)}
        selected={item.selected}
        disabled={item.isDisabled}
        details={item.isDisabled ? 'Disabled':''}
        stepNumber={item.stepNumber}
        onClick={() => setStepperState(item.name)}
        title={item.name == "Review" ? "Review & Submit" : item.name}
        lastStep={x === steps.length - 1}
      />
    );
  };

  const submitButtonStatus = () => {
    const currentStepName = getCurrentStepName();
    const isActivate =
      businessSelector.data.view.PTDAC.additionalFeature.length > 0 ||
      currentStepName === "Review"
        ? true
        : false;

    if (
      businessSelector.data.options.PTDAC.caseIndustry === "mining" &&
      currentStepName !== "Review"
    ) {
      const miningStatus = identifySolutionRedirect([
        businessSelector.data.options.PTDAC.ndac_ref.fullSolType,
        businessSelector.data.options.PTDAC.ndac_ref.fullMiningType,
      ]);
      return !miningStatus.next;
    } else if (
      _.includes(
        ["port"],
        businessSelector.data.options.PTDAC.caseIndustry
      ) &&
      currentStepName !== "Review"
    ) {
      const manfStatus = identifyMPWRedirect([
        dynamicSelector.data.reference_solution.solution,
      ]);
      return !manfStatus.next;
    }
    return isActivate;
  };

  // const isActivate = (businessSelec,tor.data.options.PTDAC.caseIndustry === "reference_solution" && dynamicSelector.data.reference_solution.solution === 'MPW') || businessSelector.data.view.PTDAC.additionalFeature.length > 0 || getCurrentStepName() === 'Review' ? true : false;

  return (
    <>
      <div className="stepFormSegment">
        <StepFormArea>
          <StepForm
            style={{
              width: "20%",
              padding: "1rem",
            }}
          >
            {!configStepperSelector.data.isStepperLoading &&
            steps &&
            steps.length >= 1 ? (
              steps && steps.map((item, x) => getStepElements(item, x))
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Skeleton style={{ width: "200px" }} />
              </div>
            )}
          </StepForm>
          <div style={{ width: "100%" }}>
            <StepConfigurationArea style={{ padding: "0 0 0 1rem" }}>
              {currentStepArea}
            </StepConfigurationArea>
          </div>
        </StepFormArea>

        {/* <HorizontalDivider /> */}
        <Card className="ButtonsRowCard">
          {/* borderTopLeftRadius:'0',borderTopRightRadius:'0' */}
          <ButtonsRow
            style={{
              padding:
                "var(--spacing-small) 2rem var(--spacing-small) var(--spacing-small)",
            }}
          >
            <Button
              onClick={() =>
                dispatch(
                  showModal({
                    msg: "Are you sure you want to cancel the Case? All the changes done will be lost.",
                    title: "Cancel Case",
                    variant: "WARNING",
                    show: true,
                    action: [
                      {
                        label: "No",
                        variant: "neutral",
                        onAction: serializeFunction(() => {
                          window["store"].dispatch(
                            window["authSlice"].showModal({
                              msg: "",
                              panel: { title: "", msg: "" },
                              show: false,
                            })
                          );
                        }),
                      },
                      {
                        label: "Yes",
                        variant: "call-to-action",
                        onAction: serializeFunction(() => {
                          window.location.href = "/";
                        }),
                        fullWidth: true,
                      },
                    ],
                    //
                  })
                )
              }
            >
              Cancel
            </Button>
            <Button
              disabled={makeDisable || isBoQEditable}
              onClick={() => {
                handleBack();
              }}
            >
              <ButtonIcon>
                <ChevronLeftIcon />
              </ButtonIcon>
              <ButtonText>Previous</ButtonText>
            </Button>
            {submitButtonStatus() ? (
              <Button
                disabled={props.isDisable}
                variant="call-to-action"
                onClick={handleActivate}
              >
                <ButtonText>Submit</ButtonText>
              </Button>
            ) : (
              // <Button disabled={actionNextCheck()} variant='call-to-action'
              <Button
                disabled={isActionNextDisabled()}
                variant="call-to-action"
                onClick={() => {
                  handleContinue();
                }}
              >
                <ButtonText>Next</ButtonText>
                <ButtonIcon>
                  <ChevronRightIcon />
                </ButtonIcon>
              </Button>
            )}
          </ButtonsRow>
        </Card>
      </div>
    </>
  );
};

export default Stepper;

Stepper.propType = {
  onSubmit: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  isDisable: PropTypes.bool,
};
