import CONFIG_API from "Constants/services/configurator";
import _ from "lodash";
import { setProp } from "Store/Actions";
import { addCartItem } from "Store/contentState/cartBoqSlice";
import { setError, showBoqLoader } from "Store/general/auth";
import useCartUtility from "./useCartUtility";
import { RootState, store } from "Store/mainStore";
import { useDispatch, useSelector } from "react-redux";
import { useConfigurator } from "./useConfigurator";
import { useShowValidateMessages } from "./useShowValidateMessages";

const useAddToCart = () => {
  let isDisabled = false;
  const {
    undoCallBackDefault,
    setNextStepsNull,
    appendRRHConfigMPW,
    appendRadioExcelData,
    get_ServiceDataGroup,
    addStepFormInputs,
    removeStepFormInputs,
    setCartValues,
    addHiddenFieldData,
    setCount_of_parameters,
    prepareEotParamsQueryString
  } = useCartUtility();
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const caseDetailsSelector = useSelector(
      (state: RootState) => state.caseDetails
    );
  const findCaseSelector = useSelector((state: RootState) => state.findCase);
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  ); 
  const {getAllFieldsInPackage}  = useConfigurator();
  const { setValidationMessageState } = useShowValidateMessages();
  
  const dispatch = useDispatch();

  const setDisabled = (val) => {
    isDisabled = val;
  }

  // common add to cart method to decide what logic to run depending on solution and step
  const addToCartLogic = (activePackages, packageData,undo_addCart?) => {
    const activeStep = configstepperSelector.data.active_step;
    let connectivity_count_data = {};
    let datasetData = { ...dynamicSelector.data.dataSet };
    const solutionCategory = configstepperSelector.data.solutionCategory;

    if (_.includes(["NDAC", "MPW"], solutionCategory)) {
      // if connectivity then set the hidden fields count of prod on family
      if (_.includes(['devices', 'connectivity'], activeStep)) {
        connectivity_count_data = setCount_of_parameters(activePackages,undo_addCart);
      }
    }
    undo_addCart ? setUndo_AddCart(packageData, undo_addCart, datasetData, connectivity_count_data) : setAddCardData(packageData, datasetData,connectivity_count_data);
    return isDisabled;
  }


  /** setAddCardData */
  const setAddCardData = (packageData, datasetData,countData?) => {
    const solutionCategory = configstepperSelector.data.solutionCategory;
    let addCartValues = { ...dynamicSelector.data.element[configstepperSelector.data.active_step] };
    const packageDataAllFields = getAllFieldsInPackage();
    let setAddCardValues = {};
    // set hidden fields while adding to cart
    addHiddenFieldData(setAddCardValues, addCartValues, countData);
    setCartValues(setAddCardValues, addCartValues, packageDataAllFields, datasetData);
    let firstValue = { ...datasetData };
    let totalValueSet = setAddCardValues;
    let methodType = "POST";
    // PATCH call in case sid is set and not empty
    if (dynamicSelector.data && dynamicSelector.data.sid && (dynamicSelector.data.sid).length !== 0) {
      methodType = "PATCH";
      totalValueSet = { ...firstValue, ...setAddCardValues }
    }
    // check if MPW solution and has RRH config data 
    // and microwave_configuration, and set then append to the cart data to send to config
    const rrhdata = appendRRHConfigMPW(totalValueSet);
    if (rrhdata && !_.isEmpty(rrhdata)) {
      totalValueSet = { ...totalValueSet, ...rrhdata };
    }

    // check if MPW solution and has Radio Excel uploaded data set then append to the cart data to send
    const radioExcelData = appendRadioExcelData(totalValueSet);
    if (radioExcelData && !_.isEmpty(radioExcelData)) {
      totalValueSet = { ...totalValueSet, ...radioExcelData };
    }

    // Call addStepFormInputs for adding step wise user inputs
    addStepFormInputs(addCartValues, packageData);

    // customise offer add products data
    const dynamicData = { ...dynamicSelector.data.element[configstepperSelector.data.active_step] };
    if (dynamicData.customise_offer) {
      const customise_offer_addProducts = [];
      _.each(dynamicData.customise_offer['All'], (obj) => {
        if (obj.checked) {
          customise_offer_addProducts.push(obj.id);
        };
      });
      const addProdCart = { 'customise_offer': { 'addProducts': customise_offer_addProducts } };
      totalValueSet = { ...totalValueSet, ...addProdCart };
    }
    dispatch(setProp({ slice: "dynamic", key: 'dataSet', value: totalValueSet }));
    dispatch(setProp({ slice: "dynamic", key: 'actionNext', value: false }));
    addCartData(methodType, totalValueSet, packageDataAllFields);
  }


  const addCartData = async (method, cartDataList, package_data, undo = false) => {
    const reduxStore = store.getState();
    const solutionCategory = configstepperSelector.data.solutionCategory;

    setDisabled(true);
    let solutionName: string = configstepperSelector.data.solution_selected
    const sid = dynamicSelector.data.sid !== "" ? dynamicSelector.data.sid : "";

    // let cartData = { ...cartDataList, ...caseDetailsDataFormatted };
    const cartDataSend = { "status": "draft", "segment": solutionName, "data": cartDataList };

    let eotParams = prepareEotParamsQueryString(configstepperSelector, dynamicSelector, dispatch, solutionCategory, solutionName, undo, caseDetailsSelector, findCaseSelector);

    // set loader in boq for configurator
    dispatch(showBoqLoader(true));
    const packageData = package_data;
    await CONFIG_API.segmentFormSubmission(method, solutionName, cartDataSend, sid, eotParams, undo)
      .then((resp) => {
        if (resp.status) {
          // if data is present then process the response
          if (!_.isEmpty(resp.data) || (reduxStore.cartBoq.data && reduxStore.cartBoq.data["customServices"] && reduxStore.cartBoq.data["customServices"].length > 0)) {
            process_response(resp, packageData, solutionCategory, cartDataList);
          }
          // set the validation details
          const validateResponse = resp.validateResponse && resp.validateResponse.length > 0 ? resp.validateResponse : [];
          const disableFlag = setValidationMessageState(validateResponse);
          dispatch(
            setProp({
              slice: "dynamic",
              key: `validationDetails`,
              value: { disableNext: disableFlag, validateResponse: validateResponse },
            })
          );
        } else {
          dispatch(
            setError({
              key: "error",
              value: {
                errorCode: resp.errorCode,
                errorMsg: resp.errorMessage,
              },
            })
          );
          setDisabled(false);
        }
        dispatch(showBoqLoader(false));
      })
      .catch((e) => {
        dispatch(
          setError({
            key: "error",
            value: {
              errorCode: e,
              errorMsg: "Timeout",
            },
          })
        );
        setDisabled(false);
        dispatch(showBoqLoader(false));
      });
    dispatch(setError("reset"));
  }

  
  const process_response = (resp, packageData, solutionCategory, cartDataList) => {
    let productList = [];
    let mpwNDACProductList = [];
    const respConfig = resp.data?.final_response ? resp.data?.final_response : resp.data;
    let responseData = respConfig.boqData?.data?.saleItems ? respConfig.boqData?.data?.saleItems : [];
    // Check and handle custom services list
  
    if (_.includes(['NDAC', 'MPW'], solutionCategory)) {
      // get eot response and store in redux to send to syncoffer
      if (resp.data.eot_response && (resp.data.eot_response).length > 0) {
        dispatch(setProp({ slice: "dynamic", key: 'eot_response_syncoffer', value: resp.data.eot_response }));
      }
      if (_.includes(['services', 'sla_warranty'], configstepperSelector.data.active_step)) {
        // merge configurator services and ndac services
        responseData = responseData.map(obj => {
          if (obj.type === 'end_end_services') {
            return { ...obj, "oot_displaySection": "deployment_technical" }
          } else {
            return obj;
          }
        });
        const end_end_servicesData = get_ServiceDataGroup(responseData.filter((e) => e.type === "end_end_services"));
        responseData = responseData.filter((e) => e.type !== "end_end_services");
        responseData = [...responseData, ...end_end_servicesData];
      }
    }
  
    responseData.map((item, index) => {
      // TODO: make a function for the conditions with proper comments
      if (_.includes(['NDAC'], solutionCategory) && (_.includes([null, 'MXIE', 'NDAC'], item.financialProduct) || _.includes([null, 'MXIE', 'NDAC'], item.financial_product))
        || _.includes(['MPW'], solutionCategory) && (_.includes([null, 'NDAC'], item.financialProduct) || _.includes([null, 'NDAC'], item.financial_product) || ((_.includes(['MXIE'], item.financialProduct) || _.includes(['MXIE'], item.financial_product)) && ((_.startsWith(item.workPackage, 'Application attached services')) || item.workPackage === 'MXIE')))) {
        let productdataListData = _.cloneDeep(item);
        let productlistdata = [];
  
        const existingProducts = dynamicSelector.data['selectedProductsData'] && (dynamicSelector.data['selectedProductsData']).length > 0 ? dynamicSelector.data['selectedProductsData'] : [];
  
        if (configstepperSelector.data.active_step === 'devices' || configstepperSelector.data.active_step === 'connectivity' || configstepperSelector.data.active_step === 'sla_warranty') {
          Object.entries(dynamicSelector.data.element[configstepperSelector.data.active_step]).map((data, _index) => {
            if (packageData[data[0]] && packageData[data[0]]['data_callback'] && packageData[data[0]]['data_callback']['button']) {
              Object.entries(data[1]).forEach(item => {
                if (item[1] && (_.isArray(item[1])) && item[1].length > 0) {
                  const checkedItem = item[1].filter((itm) => itm.checked);
                  productlistdata.push(checkedItem);
                }
              });
              const selectedProducts = _.map(_.flatten(productlistdata), ele => _.pick(ele, ['product_code', 'quantity']));
  
              const finalProductList = _.unionBy(selectedProducts, existingProducts, 'product_code');
              dispatch(setProp({ slice: "dynamic", key: 'selectedProductsData', value: finalProductList }));
            }
          })
        }
  
        if (existingProducts.length > 0) {
          const foundItem = _.find(existingProducts, (prod) => prod.product_code === item['productCode']);
          if (foundItem) {
            item['quantity'] = foundItem['quantity'];
          }
        }
  
        let productdatalist = productlistdata.flat().filter((itm) => itm.product_code === item['productCode'])
        if (productdatalist[0]) {
          productdataListData['quantity'] = productdatalist[0].quantity
        }
        else {
          productdataListData['quantity'] = item.quantity
        }
  
        if (!item.type) {
          const prodType = item.ProductType && (item.ProductType).toLowerCase();
          const prodSubType = item.productSubType && (item.productSubType).toLowerCase();
          if (prodSubType && _.includes(['subscription', 'license', 'maintenance'], prodSubType) && prodType === 'services' && item.deviceProductID !== 'SLA') {
            productdataListData['type'] = 'subscription';
          }
          if (_.includes(['software', 'hardware'], prodType)) {
            productdataListData['type'] = 'product';
          }
          if (prodSubType && _.includes(['professional_services'], prodSubType) && prodType === 'services') {
            productdataListData['type'] = 'service';
          }
        }
        productdataListData['product_name'] = item['productTitle'];
        productdataListData['product_id'] = item['productCode'];
        productdataListData['work_package'] = item['workPackage'];
        productdataListData['work_item'] = item['workItem'];
        if (item.oot_displaySection) {
          productdataListData['oot_displaySection'] = item.oot_displaySection;
        }
        if (_.includes(['sla_warranty', 'care_srs_sla_warranty'], configstepperSelector.data.active_step)) {
          if (item.deviceProductID === 'SLA') {
            productdataListData['category'] = 'sla';
            productdataListData['type'] = 'sla';
          } else if (item.deviceProductID === 'Warranty') {
            productdataListData['category'] = 'warranty';
            productdataListData['type'] = 'warranty';
          }
        }
        //--------------- Pricing calculations ----------------
        const irpValue = item['irp'] ? item['irp'] : item['IRP'];
        productdataListData['list_price'] = irpValue ? parseFloat(irpValue) : 0;
        item['duration'] = item['duration'] !== 'NA' ? parseFloat(item['duration']) : 1;
        productdataListData['cost'] = productdataListData['list_price'] * parseFloat(productdataListData['quantity']) * item['duration'];
        productdataListData['duration'] = item['duration'];
        productList.push(productdataListData);
        mpwNDACProductList.push(item);
      } else {
        // EOT responded already have price ( CMU , MPW connectivity , MPW service items )
        const productDetails = { product_name: "", list_price: 0, cost: 0, product_id: "", quantity: 0, type: "", work_package: "", duration: null, work_item: "" };
        // const productDetails = _.cloneDeep(item);
  
        // add duration logic here to show duration on UI and in BOQ store both
        productDetails.duration = item['duration'] !== 'NA' ? parseFloat(item['duration']) : 1;//processDuration(item, cartDataList);
        item['duration'] = Math.max(1, productDetails.duration);
  
        // for NDAC sale items sent to EOT , type decision is made here based on delivery model , salestype and finanaical product
        if (!item.type && (item.delivery_model === "NA") && (item.financial_sales_type === 'hw3' || item.financial_sales_type === 'hw') && _.includes(['mxie', 'das_pricing'], (item.financial_product).toLowerCase())) {
          item.type = 'product';
        }
        productDetails.product_name = item.work_item ? item.work_item : item.product_name;
        productDetails.product_id = item.si_code ? item.si_code : item.product_id;
        productDetails.quantity = Math.max(1, item.quantity);
        productDetails.type = item.type;
        productDetails.work_package = item.work_package;
        productDetails.work_item = item.work_item;
  
        item['quantity'] = setEOTResponseQuantity(productDetails, configstepperSelector, dynamicSelector);
        productDetails['quantity'] = item['quantity'];
  
        // calculate finalcost based on type ----- Pricing calculations
        let finalPrice = getPriceBasedOnType(item);
  
        productDetails.list_price = finalPrice;
        productDetails.cost = finalPrice;
        // add total_pnp_with_incoterm
        item['total_pnp_with_incoterm'] = finalPrice;
        if (item.oot_displaySection) {
          productDetails['oot_displaySection'] = item.oot_displaySection;
        }
        // add financial product to redux as we want to use it in categorising items
        productDetails['financialProduct'] = item.financialProduct ? item.financialProduct : item.financial_product;
        productList.push(productDetails);
      }
    });
    dispatch(setProp({ slice: "dynamic", key: `sid`, value: respConfig.sid }));
    dispatch(
      addCartItem({
        products: productList,
        history: true,
        configurator: true
      })
    );
    setDisabled(false);
    // add to boq for ndac reference solutions only / cmu and mpw_reference solution add to boq
    addBoqDataToStore(dispatch, solutionCategory, productList, respConfig, mpwNDACProductList);
  }
  
  // --- This is a new function added to handle the price calculations ---
  const getPriceBasedOnType = (item) => {
    // default set total_pnp_with_incoterm as cost
    let finalCost = item.total_pnp_with_incoterm !== undefined ? item.total_pnp_with_incoterm : item.cost;
    let durationMultQuantity = parseFloat(item['duration']) * parseFloat(item['quantity']);
  
    /*ImpNote : For software(lictype = termbased) and  care and srs products we need pnp_with_incoterm and not total_pnp_with_incoterm.
    At EOT end total_pnp_with_incoterm is multiplied with duration and qty so we need not multiply again */
    let finalPrice = parseFloat(finalCost);
  
    //ImpNote : if type of item is DAS then multiply with duration and qty as user inputs the qty on UI and we need to multiply that with unit price
    if(item.financial_product && item.financial_product.toLowerCase() === 'das_pricing'){
      finalPrice = parseFloat(finalCost) * durationMultQuantity;
    }
    // in case of care and srs products we need to multiply with duration only and not qty as response pnp_with_incoterm from EOT already multiplys qty.
    if (_.includes(['care', 'srs'], item.type)) {
      finalCost = item.pnp_with_incoterm !== undefined ? item.pnp_with_incoterm : item.cost;
      finalPrice = parseFloat(finalCost) * parseFloat(item['duration']);
    }
    // for software with license type term based we need to multiply pnp_with_incoterm with duration and qty
    if (item.type === 'software' && store.getState().dynamic.data.dataSet['license_type'] && store.getState().dynamic.data.dataSet['license_type'] === 'Term based') {
      finalCost = item.pnp_with_incoterm !== undefined ? item.pnp_with_incoterm : 0;
      finalPrice = parseFloat(finalCost) * durationMultQuantity;
    }
    return finalPrice;
  }
  
  const setEOTResponseQuantity = (productDetails, configstepperSelector, dynamicSelector) => {
    let quantity = productDetails.quantity;
    if (configstepperSelector.data.solution_selected === 'cdm_ug_ndac' && dynamicSelector.data.localStates.setOptionsDataList) {
     // --- For configstepperSelector.data.active_step other than connectivity - this is not ibwave_input_available/ibwave_input_available_5g will not avaailable 
     // --- So, we need to get the value from the dynamicSelector.data.element directly
     // --- Need to make it dynamic some how
      const ibwaveAvailable = !!(dynamicSelector.data.element["connectivity"]?.["ibwave_input_available"] && dynamicSelector.data.element["connectivity"]["ibwave_input_available"]["key"] && _.toLower(dynamicSelector.data.element["connectivity"]["ibwave_input_available"]["key"])==="yes");
      const ibwave5GAvailable = !!(dynamicSelector.data.element["connectivity"]?.["ibwave_input_available_5g"] && dynamicSelector.data.element["connectivity"]["ibwave_input_available_5g"]["key"] && _.toLower(dynamicSelector.data.element["connectivity"]["ibwave_input_available_5g"]["key"])==="yes");
      const selectedOption = ibwaveAvailable || ibwave5GAvailable;
      if (selectedOption) {
        const optionDataList = store.getState().dynamic.data.localStates.setOptionsDataList;
        const optionValues = _.flatten(_.flatMap(optionDataList, option => _.values(option['data'])))
        // find the quantity of the product_code
        const itemFound = _.find(
          optionValues, // Flatten the data arrays
          item => item['product_code'] === productDetails.product_id // Find the matching prod_code
        );
        quantity = itemFound ? itemFound['quantity'] : (quantity ? quantity : 1);
      }
    }
    return quantity ? quantity : 1;
  }
  
  const addBoqDataToStore = (dispatch, solutionCategory, productList, respConfig, mpwNDACProductList) => {
    // add to boq for ndac reference solutions only
    if (_.includes(['NDAC'], solutionCategory)) {
      dispatch(setProp({ slice: "dynamic", key: `boq`, value: productList }));
    }
    else {
      // cmu and mpw_reference solution add to boq
      let boq_items = respConfig?.boqData?.data?.saleItems;
  
      if (mpwNDACProductList.length > 0) {
        // made this from product code to product title as product codes can be duplicate
        const mpw_items = boq_items.filter(obj1 => !mpwNDACProductList.some(obj2 => obj1['productTitle'] === obj2['productTitle']));
        boq_items = [...mpw_items, ...mpwNDACProductList];
      }
      // boq_items = handleCustomServicesList(_.cloneDeep(boq_items));
      dispatch(setProp({ slice: "dynamic", key: `boq`, value: boq_items }));
    }
  }
  
  
  const setUndo_AddCart = (packageData, undo_addCart, dataset, countData?) => {
    let addCartValues = undo_addCart;
    addCartValues = { ...addCartValues };
    let setAddCardValues = {};
    // set hidden fields while adding to cart
    addHiddenFieldData(setAddCardValues, addCartValues, countData);
    const packageDataAllFields = getAllFieldsInPackage();
    setCartValues(setAddCardValues, addCartValues, packageDataAllFields, dataset);
    let totalValueSet = { ...setAddCardValues };
    let methodType = "PATCH";
    let finalOptions = setNextStepsNull(totalValueSet, dataset);
    dispatch(setProp({ slice: "dynamic", key: 'dataSet', value: finalOptions }));
    // remove the default value set from callbacks
    undoCallBackDefault();
  
    // check if MPW solution and has RRH config data 
    // and microwave_configuration, and set then append to the cart data to send to config
    const rrhdata = appendRRHConfigMPW(finalOptions);
    if (rrhdata && !_.isEmpty(rrhdata)) {
      finalOptions = { ...finalOptions, ...rrhdata };
    }
  
    // check if MPW solution and has Radio Excel uploaded data set then append to the cart data to send
    let radioExcelData = [];
    if (configstepperSelector.data.active_substep !== 'radio_solution_connectivity') {
      radioExcelData = appendRadioExcelData(finalOptions);
    }
    if (radioExcelData && !_.isEmpty(radioExcelData)) {
      finalOptions = { ...finalOptions, ...radioExcelData };
    }
  
    // Remove items from stepFormInputs store
    removeStepFormInputs(finalOptions, packageData);
  
    addCartData(methodType, finalOptions, packageData, true);
  }

  return {
    addToCartLogic,
    process_response
  }
}

export default useAddToCart;