import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Button, TextArea } from "@nokia-csf-uxr/ccfk";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";

import { setProp } from "Store/Actions";
import { RootState } from "Store/mainStore";

import { useConfigurator } from "../hooks/useConfigurator";

import RrhConfigChips from "./RrhConfigChips";
import { useRenderDynamicElements } from "../hooks/useRenderDynamicElements";
import { useLocalStateManagement } from "../hooks/useLocalStateManagement";
import RadioConfigurationModal from "./RadioConfigurationModal";
import DialogModal from "Components/Elements/DialogModal";
import RadioExcelUpload from "./RadioExcelUpload";
import { useDataCallBack } from "../hooks/useDataCallback";
import PageInlineFeedback from "../Components/PageInlineFeedback";
import { mpwSolutionConfig } from "./Config";
import dotCounters from "Constants/counterVars.json";
interface IRadioSolution {
  allRefs: any;
  renderActionButton?: Function;
}

const RadioSolution = React.forwardRef(
  ({ renderActionButton, allRefs }: IRadioSolution) => {
    /** Selectors */
    const themeSelector = useSelector((state: RootState) => state.theme);
    const stateMachineSelector = useSelector(
      (state: RootState) => state.stateMachine
    );

    const configstepperSelector = useSelector(
      (state: RootState) => state.config_stepper
    );
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);

    /** Component state */
    const [configChips, setConfigChips] = useState({});
    const [isConfigured, setIsConfigured] = useState(false);
    const [popupConfig, setPopupConfig] = useState({
      configCount: 0,
      isConfigured: false,
      configuration: {},
      fieldList: {},
    });
    const [deleteConfigKey, setDeleteConfigKey] = useState(0);
    const [editConfigKey, setEditConfigKey] = useState(0);
    const [actionType, setActionType] = useState("");
    const [configItemStore, setConfigItemStore] = useState(undefined);

    /** Hooks */
    const dispatch = useDispatch();
    const {
      getPackageFieldList,
      preparePackageElementContent,
      setPageFeedbacks,
    } = useConfigurator();
    const { renderDynamicContent } = useRenderDynamicElements(allRefs);
    const { setState, getState } = useLocalStateManagement();
    const { addCustomCallBack } = useDataCallBack(allRefs);

    /** local variables */
    const activeStep = configstepperSelector.data.active_step;
    const activeSubStep = configstepperSelector.data.active_substep;
    const packageData = getPackageFieldList(activeStep);
    // Get the configCountField from mpwSolutionConfig
    const configCountField =
      mpwSolutionConfig.config?.[activeSubStep].configCountField;
    const maxConfigCount =
      dynamicSelector.data.elementMMS[activeStep] &&
      dynamicSelector.data.elementMMS[activeStep][configCountField] &&
      dynamicSelector.data.elementMMS[activeStep][configCountField]["max"];
    // ---- Should be removed after testing
    // const maxConfigCount = dynamicSelector.data.elementMMS[activeStep] && dynamicSelector.data.elementMMS[activeStep]["count_of_rrh_types"] && dynamicSelector.data.elementMMS[activeStep]["count_of_rrh_types"]["max"];

    const mpwConnectivitySolutionType =
      mpwSolutionConfig.config[activeSubStep].solutionType;
    const configPackage = mpwSolutionConfig.config[activeSubStep].configPackage;
    const configField = mpwSolutionConfig.config[activeSubStep].configField;
    const configName = mpwSolutionConfig.config[activeSubStep].configName;

    const isUndoButtonEnabled =
      themeSelector.data.button.isToggle[
        `${stateMachineSelector.PTDAC.activeTab}_undo`
      ];

    /** getBlockContent */
    const getBlockContent = (packageDataDetails) => {
      const { jD } = preparePackageElementContent(packageDataDetails);
      return JSON.parse(JSON.stringify(jD));
    };

    const activePackages = getBlockContent(packageData);
    const form_name =
      activePackages?.[configPackage].form_callback.form_identifier;
    const configButtonLabel = activePackages?.[configPackage].form_callback.button;
    // Expected form_name = "RRH-" Or "MW-"
    // Remove the trailing '-' from the form_name
    const configIdentifier = mpwSolutionConfig.config[activeSubStep].configField;
    const configCountStateField =
      mpwSolutionConfig.config?.[activeSubStep]?.configCountStateField;

    /** Component effects */
    useEffect(() => {
      let __isRrhTypesDisabled =
        dynamicSelector.data.modal[activeStep] &&
        dynamicSelector.data.modal[activeStep]?.[configCountStateField]
          ? dynamicSelector.data.modal[activeStep]?.[configCountStateField]
          : false;
      if (!__isRrhTypesDisabled) {
        prepareConfigurationChips(editConfigKey > 0);
      }
      // Set the setConfigChips from store after add to cart and previous button click
      if (
        __isRrhTypesDisabled &&
        actionType !== "add" &&
        dynamicSelector.data.modal[activeStep][activeSubStep] &&
        dynamicSelector.data.modal[activeStep][activeSubStep][configIdentifier]
      ) {
        updatePopupConfigItems();
      }
    }, [dynamicSelector.data.element[activeStep]?.[configCountField]?.key]);

    useEffect(() => {
      let __isRrhTypesDisabled =
        dynamicSelector.data.modal[activeStep] &&
        dynamicSelector.data.modal[activeStep]?.[configCountStateField]
          ? dynamicSelector.data.modal[activeStep]?.[configCountStateField]
          : false;

      // Set the setConfigChips from store after add to cart and previous button click
      if (
        __isRrhTypesDisabled &&
        actionType !== "add" &&
        dynamicSelector.data.modal[activeStep][activeSubStep] &&
        dynamicSelector.data.modal[activeStep][activeSubStep][configIdentifier]
      ) {
        updatePopupConfigItems();
      }

      const __filedsSet = {};
      Object.entries(activePackages).forEach((data) => {
        if (data[1]["type"] !== "hidden" && data[1]["data_callback"]) {
          __filedsSet[data[0]] = data[1];
        }
      });
      if (!_.isEmpty(__filedsSet)) {
        addCustomCallBack(
          false,
          "",
          "",
          __filedsSet,
          dynamicSelector.data.element[activeStep]
        );
      }

      // show pagefeedback warning message when total sum of 4g and 5g sites are grater than 20
      const total_4g_sites = dotCounters.dot_total_+'4g_sites';
      const total_5g_sites = dotCounters.dot_total_+'5g_sites';
      if (
        _.isNumber(
          dynamicSelector.data.element[activeStep]["total_4g_sites"]
        ) &&
        _.isNumber(dynamicSelector.data.element[activeStep]["total_5g_sites"])
      ) {
        const sum_total_sites =
          dynamicSelector.data.element[activeStep]["total_4g_sites"] +
          dynamicSelector.data.element[activeStep]["total_5g_sites"];
        showFeedbackFor_totalSites(sum_total_sites);
      }
    }, [
      configstepperSelector.data.active_step,
      configstepperSelector.data.active_substep,
    ]);

    // Reset the configurations
    useEffect(() => {
      if (
        dynamicSelector.data.element[activeStep]?.[
          mpwConnectivitySolutionType
        ] &&
        dynamicSelector.data.element[activeStep]?.[mpwConnectivitySolutionType]
          .key 
          &&
        ["Radio Blueprint", "Radio Excel Upload"].includes(
          dynamicSelector.data.element[activeStep]?.[
            mpwConnectivitySolutionType
          ].key
        )
      ) {
        // Reset radio configuration - only when clicked on radio excel upload button : fix for undo enable
        if (
          mpwSolutionConfig.config?.common.resetConfigurationFor.includes(
            dynamicSelector.data.element[activeStep]?.[
              mpwConnectivitySolutionType
            ].key
          )
        ) {
          resetConfiguration();
        }
        if (
          mpwSolutionConfig.config?.common.reserExcelFor.includes(
            dynamicSelector.data.element[activeStep]?.[
              mpwConnectivitySolutionType
            ].key
          )
        ) {
          // Reset radio excel data
          const excelJsonObject =
            mpwSolutionConfig.config?.[activeSubStep].excelJsonObject;
          if (excelJsonObject) {
            dispatch(
              setProp({
                slice: "dynamic",
                key: `modal.${activeStep}.${activeSubStep}.${excelJsonObject}`,
                value: [],
              })
            );
          }
        }
      }
    }, [
      dynamicSelector.data.element[activeStep]?.[mpwConnectivitySolutionType],
    ]);

    /** updatePopupConfigItems */
    const updatePopupConfigItems = () => {
      // reset all the previous state
      setEditConfigKey(0);
      setDeleteConfigKey(0);
      const __configChipSaved = _.cloneDeep(
        dynamicSelector.data.modal[activeStep][activeSubStep][configIdentifier]
      );
      const __configItems = {};
      Object.keys(__configChipSaved).forEach((rrhKey) => {
        __configItems[`${configIdentifier}-${rrhKey}`] = {
          status: "done",
          fields: __configChipSaved[rrhKey],
        };
      });
      setIsConfigured(true);
      setConfigChips((_) => ({ ...__configItems }));
      // Update popupConfig with new chip configuration
      const configPackage = mpwSolutionConfig.config[activeSubStep].configPackage;
      
      const configFieldDetails = activePackages[configPackage]
        .field_details[`${configIdentifier}-1`]
        ? activePackages[configPackage].field_details[`${configIdentifier}-1`]
        : undefined;
      const ___popupConfig = popupConfig;
      ___popupConfig.configCount = Object.keys(__configItems).length;
      ___popupConfig.configuration = __configItems;
      ___popupConfig.isConfigured = true;
      ___popupConfig.fieldList = configFieldDetails;
      setPopupConfig((_) => ___popupConfig);
    };

    /** prepareConfigurationChips */
    const prepareConfigurationChips = (isSaved = false) => {
      const configCount =
        dynamicSelector.data.element[activeStep]?.[configCountField]?.key;
      const configItems = {};
      for (let i = 1; i <= configCount; i++) {
        configItems[`${configIdentifier}-${i}`] = {
          status: isSaved ? "done" : "pending",
          fields: isSaved
            ? dynamicSelector.data.modal[activeStep][activeSubStep][configIdentifier][i]
            : {},
        };
      }
      setIsConfigured(isSaved);
      setConfigChips((_) => ({ ...configItems }));
      return configItems;
    };

    /** configureRadio */
    const configureRadio = () => {
      const configItemsCount = Object.keys({ ...configChips }).length;

      const form_name =
        activePackages[configPackage].form_callback.form_identifier;
      
      const configFieldDetails = activePackages[configPackage]
        .field_details[`${configField}-1`]
        ? activePackages[configPackage]
        .field_details[`${configField}-1`]
        : undefined;

      
      const configName = mpwSolutionConfig.config[activeSubStep].configName;
      // Prepare custom nodal store
      if (!_.isEmpty(configFieldDetails)) {
        const rrhConfig = {};
        const configVal = getRRHFieldData();
        for (let i = 1; i <= configItemsCount; i++) {
          rrhConfig[i] = configVal;
          rrhConfig[i][configName] = {
            key: form_name + i,
            value: form_name + i,
          };
        }
        dispatch(
          setProp({
            slice: "dynamic",
            key: `modal.${activeStep}.${[activeSubStep]}.${configField}`,
            value: rrhConfig,
          })
        );
      }

      setPopupConfig(() => ({
        configCount: configItemsCount,
        isConfigured: false,
        configuration: {},
        fieldList: configFieldDetails,
        form_name: form_name,
      }));
    };

    /** getRRHFieldData */
    const getRRHFieldData = () => {
      const configFieldDetails = activePackages[configPackage]
        .field_details[`${configField}-1`]
        ? activePackages[configPackage].field_details[`${configField}-1`]
        : undefined;
      const configVal = {};
      if (!_.isEmpty(configFieldDetails)) {
        Object.keys(configFieldDetails).forEach((key) => {
          configVal[key] = {
            key: "",
            value: "",
          };
        });
      }
      return configVal;
    };

    /** updateRadioConfiguration */
    const updateRadioConfiguration = (status) => {
      let __popupConfig = _.cloneDeep(popupConfig);
      // Check action type
      if (
        (actionType === "" && status === "saved") ||
        (actionType === "edit" && status === "saved") ||
        (actionType === "add" && status === "saved")
      ) {
        // Handle configuration save logic
        toggleRrhTypesState(true);
        __popupConfig.isConfigured = true;
        __popupConfig.configuration = prepareConfigurationChips(true);
      }
      if (actionType === "" && status === "cancel") {
        // Handle cancel logic for the configuration
        __popupConfig.isConfigured = false;
        __popupConfig.configuration = {};
        __popupConfig.configCount = 0;
      }
      if (actionType === "edit" && status === "cancel") {
        // Handle cancel logic for the configuration - Edit
        const __currentStore = _.cloneDeep(
          dynamicSelector.data.modal[activeStep][activeSubStep][configField]
        );
        __currentStore[editConfigKey] = configItemStore;
        __popupConfig.isConfigured = true;
        __popupConfig.configuration = __currentStore;
        // Update the redux store
        dispatch(
          setProp({
            slice: "dynamic",
            key: `modal.${activeStep}.${[activeSubStep]}.${configField}`,
            value: __currentStore,
          })
        );
      }
      if (actionType === "add" && status === "cancel") {
        // Handle cancel logic for the configuration - Add
        // Delete the newly added config
        deleteConfigurationByKey(editConfigKey);
        __popupConfig = _.cloneDeep(popupConfig);
      }
      // Reset the edit config key
      setEditConfigKey(0);
      setPopupConfig((_) => __popupConfig);

      // save the count of 4g,5g datas of modal
      calculateTotalHiddenFields();
    };

    /** calculateTotalHiddenFields */
    const calculateTotalHiddenFields = () => {
      // Check if the activeSubStep is present in the totalCalculation array
      if(mpwSolutionConfig.config?.common.totalCalculation.includes(activeSubStep)){
        let total_4g_sites = 0;
        let total_5g_sites = 0;
        let total_4g_cells = 0;
        let total_5g_cells = 0;
        const sectorSums = {};
        let total_sites = {};
        const modal_data =
          dynamicSelector.data.modal[activeStep][activeSubStep][configIdentifier];
        _.each(modal_data, (config, index) => {
          const radioModule = _.toLower(config.radio_module.key);
          //  cells and site counts
          if (config.radio_technology.key === "4G") {
            total_4g_sites = total_4g_sites + config.rrh_type_site_count.key;
            total_4g_cells =
              total_4g_cells +
              config.rrh_type_site_count.key *
                config.sector_quantity_per_site.key;
          } else {
            total_5g_sites = total_5g_sites + config.rrh_type_site_count.key;
            total_5g_cells =
              total_5g_cells +
              config.rrh_type_site_count.key *
                config.sector_quantity_per_site.key;
          }
          if (!total_sites[radioModule]) {
            total_sites[radioModule] = 0;
          }
          // micro / macro counts
          total_sites[radioModule] =
            total_sites[radioModule] + config.rrh_type_site_count.key;

          // Add rrh_count to the sum corresponding to sector_quantity
          if (sectorSums[config.sector_quantity_per_site.key]) {
            sectorSums[config.sector_quantity_per_site.key] +=
              config.rrh_type_site_count.key;
          } else {
            sectorSums[config.sector_quantity_per_site.key] =
              config.rrh_type_site_count.key;
          }
        });

        Object.keys(sectorSums).forEach((key) => {
          dispatch(
            setProp({
              slice: "dynamic",
              key: `element.${configstepperSelector.data.active_step}.${dotCounters.dot_total_}${key}_sector_sites`,
              value: sectorSums[key],
            })
          );
        });
        dispatch(
          setProp({
            slice: "dynamic",
            key: `element.${configstepperSelector.data.active_step}.${dotCounters.dot_total_}4g_sites`,
            value: total_4g_sites,
          })
        );
        dispatch(
          setProp({
            slice: "dynamic",
            key: `element.${configstepperSelector.data.active_step}.${dotCounters.dot_total_}5g_sites`,
            value: total_5g_sites,
          })
        );
        dispatch(
          setProp({
            slice: "dynamic",
            key: `element.${configstepperSelector.data.active_step}.${dotCounters.dot_total_}4g_cells`,
            value: total_4g_cells,
          })
        );
        dispatch(
          setProp({
            slice: "dynamic",
            key: `element.${configstepperSelector.data.active_step}.${dotCounters.dot_total_}5g_cells`,
            value: total_5g_cells,
          })
        );

        _.map(total_sites, (val, eachmodule) => {
          // console.log("module ",eachmodule)
          dispatch(
            setProp({
              slice: "dynamic",
              key: `element.${configstepperSelector.data.active_step}.${dotCounters.dot_total_}${eachmodule}_sites`,
              value: val,
            })
          );
        });
        const sum_total_sites = total_4g_sites + total_5g_sites;
        showFeedbackFor_totalSites(sum_total_sites);
      }      
    };

    /** showFeedbackFor_totalSites */
    const showFeedbackFor_totalSites = (sum_total_sites) => {
      const feebackmessages = _.filter(dynamicSelector.data.pageFeedbacks, {
        type: "errorStatus_radio_totalSites",
      });

      if (
        sum_total_sites > 20 &&
        feebackmessages &&
        feebackmessages.length === 0
      ) {
        setPageFeedbacks(
          "errorStatus_radio_totalSites",
          true,
          `Offer contains more than 20 sites. Please note that MN deploy and MN NPO efforts are only approved till 20 sites. Please take appropriate approvals from MN Services team`
        );
      } else {
        setPageFeedbacks("errorStatus_radio_totalSites", false);
      }
    };

    /** resetConfiguration */
    const resetConfiguration = () => {
      // Enable RrhTypes
      toggleRrhTypesState(false);
      // Update count_of_rrh_types
      updateCountOfRRHTypes("");
      const configItems = {};
      setConfigChips(configItems);
      resetReduxStore();
    };

    /** resetReduxStore */
    const resetReduxStore = () => {
      // Reset the Redux store modal > RRH
      dispatch(
        setProp({
          slice: "dynamic",
          key: `modal.${activeStep}.${[activeSubStep]}.${configIdentifier}`,
          value: {},
        })
      );

      // For Updating Options data - store
      const optionsData = _.cloneDeep(getState("optionsData"));
      let stepOption = {
        ...optionsData[activeStep],
      };

      // Remove rrh_<config_number> keys from options
      const configFieldRegex = configField.toLowerCase();
      const newStepOptionData = {};
      Object.keys(stepOption).forEach((key) => {
        if (!key.startsWith(`${configFieldRegex}_`)) {
          newStepOptionData[key] = stepOption[key];
        }
      });
      // remove rrh entry from the optiondata store
      optionsData[activeStep] = newStepOptionData;
      setState("setOptionsData", optionsData);
    };

    /** handleDeleteConfiguration */
    const handleDeleteConfiguration = () => {
      deleteConfigurationByKey(deleteConfigKey);
      // Reset deleteConfigKey to 0 to close the confirm dialog
      setDeleteConfigKey(0);
    };

    /** deleteConfigurationByKey */
    const deleteConfigurationByKey = (deleteKey) => {
      // Take the deleteConfigKey state value and remove it from the configuration local state and also from store
      // Remove 'RRH-<deleteKey>' from configChips
      const newConfigChips = {};
      let __chipIndex = 1;
      Object.keys(configChips).forEach((chipKey) => {
        if (chipKey !== `${configField}-${deleteKey}`) {
          newConfigChips[`${configField}-${__chipIndex}`] = configChips[chipKey];
          __chipIndex++;
        }
      });
      setConfigChips((_) => newConfigChips);

      // Update popupConfig with new chip configuration
      const ___popupConfig = popupConfig;
      ___popupConfig.configCount = Object.keys(newConfigChips).length;
      ___popupConfig.configuration = newConfigChips;
      setPopupConfig((_) => ___popupConfig);

      // Update count_of_rrh_types
      updateCountOfRRHTypes(___popupConfig.configCount);

      // Check the count of configurations
      if (___popupConfig.configCount === 0) {
        // Enable the RrhTypes
        toggleRrhTypesState(false);
      }

      // Update redux store
      // Remove dynamic > data > modal > connectivity <activeStep> > radio_solution_connectivity <activeSubStep> > RRH > <deleteKey>
      const rrhStore =
        dynamicSelector.data.modal[activeStep][activeSubStep][configField];
      const newRrhConfig = {};
      let i = 1;
      // For Updating Options data - store
      const optionsData = _.cloneDeep(getState("optionsData"));
      let stepOption = {
        ...optionsData[activeStep],
      };

      // Remove rrh_<config_number> keys from options
      const configFieldRegex = configField.toLowerCase();
      const newStepOptionData = {};
      Object.keys(stepOption).forEach((key) => {
        if (!key.startsWith(`${configFieldRegex}_`)) {
          newStepOptionData[key] = stepOption[key];
        }
      });

      // Update redux store
      const form_name =
        activePackages[configPackage].form_callback.form_identifier;

      Object.keys(rrhStore).forEach((rrhKey) => {
        if (parseInt(rrhKey) !== deleteKey) {
          newRrhConfig[i] = _.cloneDeep(rrhStore[rrhKey]);
          newRrhConfig[i][configName] = {
            key: `${form_name}${i}`,
            value: `${form_name}${i}`,
          };
          newStepOptionData[`${configFieldRegex}_${i}`] = stepOption[`${configFieldRegex}_${rrhKey}`];
          i++;
        }
      });
      // remove deleted/unwanted entry from the optiondata store - Pending
      optionsData[activeStep] = newStepOptionData;
      setState("setOptionsData", optionsData);

      // Disptatch new RRH Config
      dispatch(
        setProp({
          slice: "dynamic",
          key: `modal.${activeStep}.${[activeSubStep]}.${configField}`,
          value: newRrhConfig,
        })
      );

      setActionType("");
      // save the count of 4g,5g datas of modal
      calculateTotalHiddenFields();
    };

    /** updateDeleteConfigurationKey */
    const updateDeleteConfigurationKey = (configChip) => {
      // configChip will be like "RRH-<stepnumber>"
      let match = configChip.match(/\d+/);
      const __configChip = match ? parseInt(match[0], 10) : 0;
      if (__configChip > 0) {
        // Set action type
        setActionType((_) => "delete");
      }
      setDeleteConfigKey(__configChip);
    };

    /** updateEditConfigurationKey */
    const updateEditConfigurationKey = (configChip) => {
      // configChip will be like "RRH-<stepnumber>"
      let match = configChip.match(/\d+/);
      const __configChip = match ? parseInt(match[0], 10) : 0;
      if (__configChip > 0) {
        // Set action type
        setActionType((_) => "edit");
        // save current state of the key <__configChip> from store
        const __savedConfig =
          dynamicSelector.data.modal[activeStep][activeSubStep][configField][
            __configChip
          ];
        console.log("savedConfig", __savedConfig);  
        setConfigItemStore(__savedConfig);
      }
      console.log("configChip", __configChip);
      setEditConfigKey(__configChip);
    };

    /** handleAddMoreConfiguration */
    const handleAddMoreConfiguration = () => {
      // Set action type
      setActionType((_) => "add");
      // save current state of RRH from store
      const __savedConfig =
        dynamicSelector.data.modal[activeStep][activeSubStep][configField];
      setConfigItemStore(__savedConfig);
      // Get current configCount from popupConfig and increment by 1
      const __popupConfig = _.cloneDeep(popupConfig);
      __popupConfig.configCount = __popupConfig.configCount + 1;

      // Update redux store
      const form_name =
        activePackages[configPackage].form_callback.form_identifier;
      // Prepare custom nodal store

      const rrhConfig = _.cloneDeep(
        dynamicSelector.data.modal[activeStep][activeSubStep][configField]
      );
      const configVal = getRRHFieldData();

      rrhConfig[__popupConfig.configCount] = configVal;
      rrhConfig[__popupConfig.configCount][configName] = {
        key: form_name + __popupConfig.configCount,
        value: form_name + __popupConfig.configCount,
      };
      dispatch(
        setProp({
          slice: "dynamic",
          key: `modal.${activeStep}.${[activeSubStep]}.${configField}`,
          value: rrhConfig,
        })
      );

      const configItems = _.cloneDeep(configChips);
      configItems[`${configField}-${__popupConfig.configCount}`] = {
        status: "pending",
        fields: getRRHFieldData(),
      };
      setConfigChips((_) => ({ ...configItems }));

      __popupConfig.configuration = configItems;
      setPopupConfig(() => __popupConfig);
      // Set edit key
      setEditConfigKey(__popupConfig.configCount);
      // Update count_of_rrh_types
      updateCountOfRRHTypes(__popupConfig.configCount);
    };

    /** toggleRrhTypesState  - isRrhTypesDisabled = true / false*/
    const toggleRrhTypesState = (status = false) => {
      // Get the mpwSolutionConfig.configCountStateField state
      const configCountStateField =
        mpwSolutionConfig.config?.[activeSubStep].configCountStateField;
      dispatch(
        setProp({
          slice: "dynamic",
          key: `modal.${activeStep}.${configCountStateField}`,
          value: status,
        })
      );
    };

    /** updateCountOfRRHTypes */
    const updateCountOfRRHTypes = (configCount) => {
      const configCountField =
        mpwSolutionConfig.config?.[activeSubStep].configCountField;
      dispatch(
        setProp({
          slice: "dynamic",
          key: `element.${activeStep}.${configCountField}`,
          value: {
            key: configCount,
            value: configCount,
          },
        })
      );
    };

    /** isRadioConfigurationModalOpen */
    const isRadioConfigurationModalOpen = () => {
      if (editConfigKey > 0) {
        return true;
      }
      return (
        !_.isEmpty(popupConfig) &&
        popupConfig["configCount"] > 0 &&
        !popupConfig["isConfigured"]
      );
    };

    if (getState("isLoading")) {
      return <p>Loading...</p>;
    }
    return (
      <div>
        <PageInlineFeedback />
        {renderDynamicContent(activePackages)}
        {dynamicSelector.data.element[activeStep] &&
          dynamicSelector.data.element[activeStep]?.[mpwConnectivitySolutionType] &&
          mpwSolutionConfig.config.common.userConfig.includes(dynamicSelector.data.element[activeStep]?.[mpwConnectivitySolutionType].key) && (
            <>
              {isRadioConfigurationModalOpen() && (
                <RadioConfigurationModal
                  isOpen={true}
                  configNumber={editConfigKey}
                  popupConfig={popupConfig}
                  actionType={actionType}
                  allRefs={allRefs}
                  updateRadioConfiguration={updateRadioConfiguration}
                />
              )}
              {!_.isEmpty(configChips) && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      gap: "0 10px",
                      marginTop: "20px",
                      padding: "20px 0",
                    }}
                  >
                    <Button
                      onClick={configureRadio}
                      variant={"secondary"}
                      disabled={isConfigured || isUndoButtonEnabled}
                    >
                      <ButtonText>
                        {configButtonLabel ? configButtonLabel : "Configure"}
                      </ButtonText>
                    </Button>
                    {isConfigured && (
                      <Button
                        disabled={isUndoButtonEnabled}
                        onClick={resetConfiguration}
                        variant={"secondary"}
                      >
                        <ButtonText>Reset</ButtonText>
                      </Button>
                    )}
                  </div>
                  <div style={{ display: "flex", flexFlow: "wrap" }}>
                    <RrhConfigChips
                      configChips={configChips}
                      maxConfigCount={maxConfigCount}
                      isConfigured={isConfigured}
                      isUndoButtonEnabled={isUndoButtonEnabled}
                      onEditConfiguration={updateEditConfigurationKey}
                      onDeleteConfiguration={updateDeleteConfigurationKey}
                      onAddMoreConfiguration={handleAddMoreConfiguration}
                    />
                    <DialogModal
                      isOpen={deleteConfigKey && deleteConfigKey > 0}
                      popInfo={{
                        title: "Delete configuration?",
                        msg: `Are you sure you want to delete the Configuration - ${deleteConfigKey} ?`,
                      }}
                      type="popup"
                      actionBtns={[
                        {
                          label: "Cancel",
                          variant: "neutral",
                          onAction: async () => {
                            setDeleteConfigKey(0);
                          },
                        },
                        {
                          label: "Delete",
                          variant: "destructive",
                          onAction: async () => {
                            handleDeleteConfiguration();
                          },
                        },
                      ]}
                    />
                  </div>
                </>
              )}
            </>
          )}
        {dynamicSelector.data.element[activeStep] &&
          dynamicSelector.data.element[activeStep]?.[
            mpwSolutionConfig.config[activeSubStep].solutionType
          ] &&
          mpwSolutionConfig.config.common.userExcelUpload.includes(
            dynamicSelector.data.element[activeStep]?.[
              mpwSolutionConfig.config[activeSubStep].solutionType
            ].key
          ) && (
            <>
              {/** Radio Excel Upload
               * Not updating the Radio Excel Upload component by using the custom configuration
               * Instead, using the common configuration for all the solution types
               * Should be updated based on the requirement
               */}
              <RadioExcelUpload allRefs={allRefs} />
            </>
          )}
        {renderActionButton()}
      </div>
    );
  }
);

RadioSolution.displayName = "RadioSolution";
export default React.memo(RadioSolution);
