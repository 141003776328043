
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useDynamicElementMap } from "./useDynamicElementMap";
import { useStatesOfElement } from "./useStatesOfElement";
import { setProp } from "Store/Actions";
import { RootState, store } from "Store/mainStore";
import { getCheckField, getStateOfElement } from "Components/Logics/stateMachine/modules/CONFIG/configurator_statelogics";
import { useDataCallBack } from "./useDataCallback";
import { useLocalStateManagement } from "./useLocalStateManagement";
import { useProductDataList } from "./useProductDataList";
import { useConfigurator } from "./useConfigurator";
import { useActivePackages } from "./useActivePackages";
import { mpwSolutionConfig } from "../mpw/Config";

export const useLoadUI = (allRefs, customUpdateMethod = undefined) => {
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dispatch = useDispatch();
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const listStyle = {
    marginBottom: '15px'
  }
  const {
    getDynamicElement
  } = useDynamicElementMap();
  const { getElementsStates } = useStatesOfElement(allRefs);
  const { addCallBack, checkDataCallback, addAllCallbacks } = useDataCallBack(allRefs);
  const { setRef, setState, getState } = useLocalStateManagement();
  const { getDataProductCataLog } = useProductDataList();
  const { getListOfFields, getPackageFieldList, getFieldStateBasedOnStepState } = useConfigurator();
  const themeSelector = useSelector((state: RootState) => state.theme);
  const fieldsDisableEnable = themeSelector.data.button.isDisable.options_global;
  const solutionCategory = configstepperSelector.data.solutionCategory;
  const solutionType = configstepperSelector.data.solutionType;

  const { getDeviceBlockData,getActivePackagesData } = useActivePackages();

  const [callBackList, updateCallBackDoneList] = React.useState([]);

  // let allProductDetailsList = dynamicSelector.data.allProducts;
  let expandVisibility = false;
  let radioExpandPanel = false;

  if (
    configstepperSelector.data.fieldsInformation[
    configstepperSelector.data.active_step
    ]
  ) {
    expandVisibility =
      configstepperSelector.data.fieldsInformation[
        configstepperSelector.data.active_step
      ].expand_panel;
    radioExpandPanel =
      configstepperSelector.data.fieldsInformation[
      configstepperSelector.data.active_step
      ]["radio_expand_panel"];
  }

  /** updateRadioButton */
  const updateRadioButton = (item, value, product) => {
    getDataProductCataLog(product);
    // setConsumptionGraph(true)
    setState('setConsumptionGraph', true);
  }

  const checkStatesFields = (item, value, packageData) => {
    if (_.isObject(packageData)) {
      Object.keys(packageData).forEach(function (itm) {
        if (packageData[itm].default != "" || _.includes(['range', 'number', 'text'], packageData[itm].type)) {
          if (_.keys(packageData[itm].states).length != 0) {
            for (let stateData in packageData[itm].states) {
              if (_.find(packageData[itm].states[stateData].state_details, (obj) => obj.field_name === item)) {
                const flatFieldData = getListOfFields(dynamicSelector.data.element);
                let checkField = getCheckField(packageData[itm], stateData, value, flatFieldData, item);
                let checkFieldValue;
                if (checkField.length > 0) {
                  checkFieldValue = eval(checkField);
                }
                if (checkFieldValue) {
                  if (packageData[itm].default === "" && (_.includes(['range', 'number', 'text'], packageData[itm].type))) {
                    let minval = packageData[itm].min;
                    if (packageData[itm].min.data_callback) {
                      // to fix the toggle button function for api default value
                      if (typeof dynamicSelector.data.element[configstepperSelector.data.active_step][itm] === "object") {
                        minval = dynamicSelector.data.element[configstepperSelector.data.active_step][itm] && dynamicSelector.data.element[configstepperSelector.data.active_step][itm].key ? dynamicSelector.data.element[configstepperSelector.data.active_step][itm].key : minval
                      }
                      else {
                        minval = dynamicSelector.data.element[configstepperSelector.data.active_step][itm] ? dynamicSelector.data.element[configstepperSelector.data.active_step][itm] : value['value'];
                      }
                    } else if (packageData[itm].type === 'text') {
                      let defaultText = packageData[itm].default;
                      minval = dynamicSelector.data.element[configstepperSelector.data.active_step][itm] ? dynamicSelector.data.element[configstepperSelector.data.active_step][itm] : defaultText;
                    }
                    dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[itm]}`, value: { 'key': minval, 'value': minval } }));
                  }
                  else {
                    if (Array.isArray(packageData[itm].default)) {
                      let valueSetArray = []
                      Object.keys(packageData[itm].default).forEach(function (dafultset) {
                        let valueSetArrayDetails = { key: '', value: '' }
                        valueSetArrayDetails['key'] = dafultset
                        valueSetArrayDetails['value'] = packageData[itm].default[dafultset]
                        valueSetArray.push(valueSetArrayDetails)
                        // Check it the multiple == true
                        // And then create key value as same for checking the default values
                        // Just like in addElement() method
                        if (packageData[itm].multiple) {
                          valueSetArrayDetails['key'] = packageData[itm].default[dafultset]
                          valueSetArrayDetails['value'] = packageData[itm].default[dafultset]
                        }
                      })
                      dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[itm]}`, value: valueSetArray }));
                    }
                    else if (typeof packageData[itm].default === 'object') {
                      let valueSetObject = {}
                      Object.keys(packageData[itm].default).forEach(function (dafult) {
                        valueSetObject['key'] = dafult
                        valueSetObject['value'] = packageData[itm].default[dafult]
                      })
                      dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[itm]}`, value: valueSetObject }));
                    }
                    else {
                      let valueSetDefaultObj = {}
                      valueSetDefaultObj['key'] = packageData[itm].default
                      valueSetDefaultObj['value'] = packageData[itm].default
                      dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[itm]}`, value: valueSetDefaultObj }));
                    }
                  }
                }
              }
            }
          }
        }
      });
    }
  }

  const updateDispatchStore = (item, value) => {
    const packageData = dynamicSelector.data.dataFromPackage['packageData'];
    const deviceBlock = dynamicSelector.data.dataFromPackage['deviceBlock'];
    dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[item]}`, value: value }));
    triggerCallback(item, value, packageData, deviceBlock);
  }

  const triggerCallback = (item, value, packageData, deviceBlock) => {
    const refval = value !== "" ? true : false;
    setRef(allRefs, item, refval);
    checkStatesFields(item, value, packageData);
    let callBackList = dynamicSelector.data.callBack;
    if (Object.keys(callBackList).length > 0) {
      Object.keys(dynamicSelector.data.callBack).forEach(function (itm) {
        Object.keys(dynamicSelector.data.callBack[itm]).forEach(function (itemkey) {
          if (dynamicSelector.data.callBack[itm][itemkey] === item || item === "connectivity_devices" || item === "connectivity_services") {
            addCallBack(false, value, item, deviceBlock)
          }
        })
      })
    }

    if (configstepperSelector.data.active_step === 'mxie') {
      if (item === 'georedundancy_1_1_units' || item === 'connector_switch') {
        if (dynamicSelector.data.element[configstepperSelector.data.active_step]) {
          if (dynamicSelector.data.element[configstepperSelector.data.active_step]['georedundancy_1_1_units'] !== '' || dynamicSelector.data.element[configstepperSelector.data.active_step]['connector_switch']) {
            let dataSet = ["mxie_server"]
            const __packageData = store.getState().dynamic.data.dataFromPackage['packageData'];
            dataSet.push(__packageData['mxie_server']);
            checkDataCallback(dataSet, value, item, true)
          }
        }
      }
    }
  }

  const getReturnValue = (slice, value) => {
    let callBackList = dynamicSelector.data.callBack
    Object.keys(callBackList).forEach(function (itemkey) {
      if (dynamicSelector.data.callBack[itemkey].includes(slice)) {
        dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[itemkey]}`, value: value }));
      }
    })
  }

  /**  getSwitchStatement */
  const LoadElements = (elementData, activePackages, renderConfig = undefined) => {
    return Object.entries(elementData).map((data, index) => {
      const allStatesForElement = getElementsStates(data[1], renderConfig,activePackages);
      const visibilitystyle = allStatesForElement['visibility'] as React.CSSProperties;
      if (data[0] === 'ndac_accessories') {
        visibilitystyle['display'] = 'none'
        //this is static for toggle right algin
      }

      if (configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step] && configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step].wrapAfter && (configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step].wrapAfter).some(r => (_.includes(data[0], r)))) {
        listStyle['flexBasis'] = '100%';
      } else {
        listStyle['flexBasis'] = '';
      }

      let allStyles = { ...listStyle, ...visibilitystyle };
      // set the required for the elements

      if (_.includes(["NDAC", "MPW"], solutionCategory) && solutionType !== 'Standalone') {
        if (_.includes(["use_cases_apps", "applications", "services"], configstepperSelector.data.active_step)) {
          if (data[1]['subtype'] && data[1]['subtype'] === 'toggle') {
            allStyles['marginBottom'] = "0px"
            allStyles['marginTop'] = "20px"
          }
          else if (configstepperSelector.data.active_step !== "services" && data[1]['type'] !== "markup") {
            allStyles['marginLeft'] = '8%'
          }
        } else if (configstepperSelector.data.active_step === 'devices' && data[1]['type'] === 'select' && data[1]['multiple']) {
          allStyles['width'] = '25%'
        }
      }

      data[1]['required'] = allStatesForElement['required'];
      //  set readonly or not for all the elements
      data[1]['readonly'] = allStatesForElement['readonly'];
      //  set disabled or not for all the elements
      if (!data[1]['disabled']) {
        data[1]['disabled'] = allStatesForElement['disabled'];
      }

      // Set count_of_rrh_types enable / diabled
      let isRrhTypesDisabled = false;
      // Get configCountField = count_of_rrh_types / count_of_microwave_configs
      if (configstepperSelector.data.active_substep &&
        configstepperSelector.data.active_substep.length > 0 && 
        data[0] === mpwSolutionConfig.config[configstepperSelector.data.active_substep]?.configCountField) {
        // data[1]['readonly']
        // Get the configCountStateField = isRrhTypesDisabled / isMicrowaveTypesDisabled
        const sliderStateField = mpwSolutionConfig.config[configstepperSelector.data.active_substep].configCountStateField;
        isRrhTypesDisabled = (dynamicSelector.data.modal[configstepperSelector.data.active_step] && dynamicSelector.data.modal[configstepperSelector.data.active_step]?.[sliderStateField]) ? dynamicSelector.data.modal[configstepperSelector.data.active_step][sliderStateField] : false;        
      }
      if (allStatesForElement['defaultValue']) {
        data[1]['defaultValue'] = allStatesForElement['defaultValue'] === 'null' ? null : allStatesForElement['defaultValue'];
        setRef(allRefs, data[1]['id'], true);
        const item = data[0];
        const value = { key: data[1]['defaultValue'], value: data[1]['defaultValue'] };
        if (renderConfig && renderConfig.customUpdateMethod && typeof renderConfig.customUpdateMethod === "function") {
          renderConfig.customUpdateMethod(item, value)
        }
        else {
          updateDispatchStore(item, value);
        }
      }
      activePackages[data[1]['id']] = data[1];
      const type = data[1]['type'];
      let displayLayout = 'flex';
      if (solutionCategory === "NDAC" && configstepperSelector.data.active_step === 'connectivity') {
        displayLayout = 'inline-flex';
      }

      const isLoading = getState('isLoading');
      // Handle option data
      const __optionsData = getState('optionsData');
      const optionsDataList = getState('optionsDataList',data[1]['id']);
      let optionsData = (__optionsData && __optionsData[configstepperSelector.data.active_step]) ? __optionsData[configstepperSelector.data.active_step] : {};
      if (renderConfig.optionDataKey) {
        optionsData = optionsData[renderConfig.optionDataKey]
      }

      // Handle the auto select value for select element
      if (data[1]['type'] === 'select' &&
        allStatesForElement?.visibleValue !== 'none' &&
        data[1]['defaultValue'] === undefined
      ) {
        handleAutoValueSelection(renderConfig, data, optionsData);
      }

      // Handle Field Disable state
      // Check active step is whether disabled
      const otherFieldState = (isRrhTypesDisabled) ? isRrhTypesDisabled : fieldsDisableEnable;
      // Need to implement the sub step disable state logic here
      const isStepDisabled = getFieldStateBasedOnStepState();
      let __fieldsDisableEnable = isStepDisabled === true ? isStepDisabled : otherFieldState;
      
      // Check the container state for the field
      // If the container is disabled, then renderConfig.isContainerDisabled will be true
      if (renderConfig && renderConfig.isContainerDisabled) {
        __fieldsDisableEnable = true;
      }

      const elementConfig = {
        isLoading,
        data,
        optionsData,
        optionsDataList,
        updateDispatchStore: renderConfig && renderConfig.customUpdateMethod && typeof renderConfig.customUpdateMethod === "function" ? renderConfig.customUpdateMethod : updateDispatchStore,
        fieldsDisableEnable: __fieldsDisableEnable,
        allRefs,
        getReturnValue,
        radioExpandPanel,
        updateRadioButton,
        expandVisibility,
        displayLayout,
        dataStore: (renderConfig && renderConfig.dataStore) ? renderConfig.dataStore : undefined,
        sliceProperty: (renderConfig && renderConfig.sliceProperty) ? renderConfig.sliceProperty : undefined,
        actionType: (renderConfig && renderConfig.actionType) ? renderConfig.actionType : undefined
      }

      const elemFunction = getDynamicElement(type, elementConfig);
      //return <p key={data[1]['id']}>{type} | {data[0]} </p>
      return (
        <div style={allStyles} key={index}>
          {(typeof elemFunction === 'function') ? elemFunction() : <></>}
        </div>
      );
    });
  }

  /**
   * Get the latest data store based on the slice path and store selector
   * 
   * @param {String} slicePath - The slice path to the data store
   * @param {object} storeSelector - The store selector object
   * @returns {dataStore} - The latest data store based on the slice path
   */
  const getLatestDataStore = (slicePath, storeSelector) => {
    let dataStore = slicePath.split('.').reduce((currentState, key) => {
      return currentState ? currentState[key] : undefined;
    }, storeSelector);
    return dataStore;
  }


  /**
   * Handles the automatic selection of values based on the provided configuration and data.
   *
   * @param {Object} renderConfig - The configuration object that may contain a custom update method and data store.
   * @param {Array} data - An array where the first element is the key and the second element contains options and other properties.
   * @param {Object} optionsData - Additional options data that may be used for selection.
   *
   * The function performs the following steps:
   * 1. Checks if a custom update method is provided in the renderConfig.
   * 2. Determines the appropriate data store based on the configuration and active step.
   * 3. If the data store is empty or invalid, it attempts to auto-select a value based on the options provided.
   * 4. If a single option is available, it selects that option automatically.
   * 5. Updates the store or calls the custom update method with the selected value.
   */
  const handleAutoValueSelection = (renderConfig, data, optionsData) => {
    let isCustomConfig = renderConfig && renderConfig.customUpdateMethod && typeof renderConfig.customUpdateMethod === "function";
    let slicePath = (isCustomConfig) ? `data.${renderConfig.sliceProperty}.${data[0]}` : `data.element.${configstepperSelector.data.active_step}.${data[0]}`;
    let dataStore = getLatestDataStore(slicePath, dynamicSelector);
    // Get the package data and device block data and store it in the dataFromPackage slice
    const packageData = getPackageFieldList(configstepperSelector.data.active_step);
    getActivePackagesData();
    let deviceBlock = getDeviceBlockData();

    if (dataStore && data[1]['default'] && !_.includes(callBackList, data[0])) {
      updateCallBackDoneList([...callBackList,data[0]]);
      triggerCallback(data[0], dataStore, packageData, deviceBlock);
    } else if (
      !dataStore || // checks if dataStore is null, undefined, or falsey
      (typeof dataStore === "string" && dataStore.trim() === "") || // if it's a string, check if it's empty or whitespace
      (Array.isArray(dataStore) && dataStore.length === 0) || // if it's an array, check if it's empty
      (typeof dataStore === "object" && !Array.isArray(dataStore) && Object.keys(dataStore).length === 0) || // if it's an object, check if it's empty
      (typeof dataStore === "object" && !Array.isArray(dataStore) && dataStore.key === "") // if it's an object, check if dataStore.key is empty        
    ) {
      // If datastore is empty/invalid
      let autoSelectValue = null;
      // Get options data
      if (data[1]['options'] && Object.keys(data[1]['options']).length === 1) {
        const itemKey = Object.keys(data[1]['options'])[0];
        const itemValue = data[1]['options'][itemKey];
        autoSelectValue = { key: itemKey, value: itemValue };
      }
      else if (optionsData?.[data[0]] && optionsData?.[data[0]] !== "" && Object.keys(optionsData?.[data[0]]).length === 1) {
        autoSelectValue = { key: optionsData?.[data[0]][0]?.id, value: optionsData?.[data[0]][0]?.value };
      }

      let value = data[1]['multiple'] && autoSelectValue ? [autoSelectValue] : autoSelectValue;

      if (value && Object.keys(value).length > 0) {
        dispatch(
          setProp({
            slice: "dynamic",
            key: "dataFromPackage",
            value: { packageData: packageData, deviceBlock: deviceBlock },
          })
        );
        // Update the store or call the custom update method with the selected value
        if (isCustomConfig) {
          renderConfig.customUpdateMethod(data[0], value);
        }
        else {
          updateDispatchStore(data[0], value);
        }
      }
    }
  }


  /**
   * Retrieves the state status of a container based on the provided container data.
   *
   * @param containerData - The data of the container to evaluate.
   * @returns The state status of the container.
   */
  const getContainerStateStatus = (containerData) => {
    const reduxStore = store.getState();
    const allfieldData = getListOfFields(reduxStore.dynamic.data.element);
    const active_step = reduxStore.config_stepper?.data?.active_step;
    let dynamicElementFieldData = null;
    if (reduxStore.dynamic.data.element[active_step] && reduxStore.dynamic.data.element[active_step]?.[containerData.id]) {
      dynamicElementFieldData = reduxStore.dynamic.data.element[active_step]?.[containerData.id];
    }
    const allStates = getStateOfElement(containerData, allfieldData, dynamicElementFieldData);
    const isHidden = allStates?.visibility === 'none';
    const isDisabled = allStates?.disabled === true;
    return {
      isHidden,
      isDisabled
    };
  }

  return {
    checkStatesFields,
    LoadElements,
    triggerCallback,
    getContainerStateStatus
  }
}