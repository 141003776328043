//@ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import { commericalModel } from 'Components/Content/configurationArea/Business/system';
// import { subscriptionPlansOptions } from "Components/Content/configurationArea/Warranty/system";
import { annualCapacity, mandatoryApp, segmentSolutions } from "Components/Logics/stateMachine/modules/PTDAC/system";
import { addCartItem, removeCartItem, updateCartItem } from "Store/Actions";
import { roundCount, updateExistingProduct } from "Utils";
import _ from "lodash";


/*
EXCEPTION: Currently the Configurator STATE are beign configured directly from API
-----------------------------------------------------------------------------------------
This FrameWork was created to co-op with backend, hence state machine  = partial fronted+ partial backend;

@refs: will contain references to rules/helpers in calculating actions & setting rules. can be used in Fronted for getting values.
@rules: will be **Business rules** name, which are set using @refs, These state  are also set from API'S | (partial fronted+ partial backend)
@selected: Contains the Real Time Calulations / Functions -  StateMachine of PTDAC/HOME etc.
*/

const initialState = {
    'HOME': {/* TODO: To be Migrated */ },
    'PTDAC': {
        activeTab: 'business',
        activeIndex: 0,
        history: {

        },
        selected: {

        }
    }
}
const stateMachineSlice = createSlice({
    name: 'stateMachine',
    initialState: {
        ...initialState,

    },
    reducers: {
        undoHistory(state, action) {
            _.set(state, action.payload.key, []);  //PTDAC.history should be sent
        },
        setPTDACHistory(state, action) {
            _.set(state.PTDAC.history, action.payload.key, action.payload.value);
        },
        /* Defines reducers for the above state which will be expecuted async */
        setPTDACLogics(state, action) {
            state.PTDAC.selected = action.payload;
        },
        setPTDACTab(state, action) {
            state.PTDAC.activeTab = action.payload.id;
            state.PTDAC.activeIndex = action.payload.index;
        }
    },
    extraReducers(builder) {
        builder.addCase(addCartItem, (state, action) => {
            if (!(state.PTDAC.history[state.PTDAC.activeTab])) {
                state.PTDAC.history[state.PTDAC.activeTab] = [];
            }
            let unWrapItems = [...(state.PTDAC.history[state.PTDAC.activeTab])];
            const parentSync = Array.isArray(action.payload) ? false : action.payload.hasOwnProperty('parentSync')
            const itemsToAdd = Array.isArray(action.payload) ? action.payload : action.payload.products;
            const history = Array.isArray(action.payload) ? false : action.payload.hasOwnProperty('history')
            if (!history) {
                return;
            }
            //@ts-ignore
            if ((itemsToAdd)) {
                itemsToAdd.forEach(product => {
                    unWrapItems = updateExistingProduct(unWrapItems, product, "+", parentSync, true)
                });
                //@ts-ignore
                if(action.payload.configurator) {
                    state.PTDAC.history[state.PTDAC.activeTab] = [...(unWrapItems)];
                } else {
                    state.PTDAC.history[state.PTDAC.activeTab] = _.uniqBy([...(unWrapItems)], v => [v.product_id, v.type].join());
                }
            }

        }).addCase(removeCartItem, (state, action) => {
            if (!(state.PTDAC.history[state.PTDAC.activeTab])) {
                state.PTDAC.history[state.PTDAC.activeTab] = [];
            }
            let unWrapItems = [...(state.PTDAC.history[state.PTDAC.activeTab])];
            const parentSync = Array.isArray(action.payload) ? false : action.payload.hasOwnProperty('parentSync')
            const itemsToAdd = Array.isArray(action.payload) ? action.payload : action.payload.products;
            const history = Array.isArray(action.payload) ? false : action.payload.hasOwnProperty('history')
            if (!history) {
                return;
            }
            //@ts-ignore
            if ((itemsToAdd)) {
                itemsToAdd.forEach(product => {
                    unWrapItems = updateExistingProduct(unWrapItems, product, "-", parentSync, true)
                });
                //@ts-ignore
                state.PTDAC.history[state.PTDAC.activeTab] = _.uniqBy([...(unWrapItems)], v => [v.product_id, v.type].join())
            }

        }).addCase(updateCartItem, (state, action) => {
            const tabItems = state.PTDAC.history[state.PTDAC.activeTab];

            if (Array.isArray(action.payload.products) && tabItems) {
                action.payload.products.forEach(product => {
                    const existingProductIndex = _.findIndex(tabItems, { product_id: product.product_id });
                    if (existingProductIndex !== -1) {
                        tabItems[existingProductIndex].quantity = action.payload.quantity;
                        tabItems[existingProductIndex].cost = action.payload.quantity * roundCount(tabItems[existingProductIndex].list_price);
                    } else {
                        //@ts-ignore
                        tabItems.push(new Object({ ...product, quantity: action.payload.quantity, cost: action.payload.quantity * roundCount(product.list_price) }))
                    }
                });
                state.PTDAC.history[state.PTDAC.activeTab] = tabItems;
            }

        })
    }
});

export default stateMachineSlice.reducer;
export const { setApplicationsTabRules, setPTDACLogics, setPTDACTab, undoHistory } = stateMachineSlice.actions;