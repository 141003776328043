import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from "Auth/authConfig";
import { showModal } from "Store/general/auth";

/**
 * Handles the response from Axios interceptors.
 * If the response contains a specific error code or message, it triggers appropriate dialogs.
 * 
 * @param {Object} response - The Axios response object.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 * @returns {Object} - The processed response object.
 */
export const handleAxiosInterceptorsReponse = (response,dispatch) => {
    // Response interceptor to handle the response
    if (response.config?.["skipAlert"]) {
        return response;
    }
    if (response.data.errorCode === 401 &&
        (
            response.data.errorMessage.trim().toUpperCase().includes("TOKEN ERROR") ||
            response.data.errorMessage.trim().toUpperCase().includes("TOKEN EXPIRED")
        )
    ) {
        disptachTokenExpiredModal(dispatch, response.data.errorMessage.trim().toUpperCase());
    }
    else if (response.data.errorCode == 401) {
        showResponseDialog(dispatch, 401);
        // this.speak(401);
    }
    if (!response.data.status) {
        // this.speak(500, { msg: response.data.errorMessage, panel: { title: 'More details', msg: response.data.stacktrace } })
        showResponseDialog(dispatch, 500, { msg: response.data.errorMessage, panel: { title: 'More details', msg: response.data.stacktrace } });
    }
    return response;
}

/**
 * Dispatches a token expired modal.
 * 
 * @param {Function} dispatch - The dispatch function to trigger actions.
 * @param {String} title - The title of the modal.
 */
export const disptachTokenExpiredModal = (dispatch, title) => {
    dispatch(showModal({ msg: "Please log in again now...", variant: "ERROR", title, show: true }))
    // Show the message 5 second and redirect the page to login
    setTimeout(() => {
        const msalInstance = new PublicClientApplication(msalConfig);
        //@ts-ignore
        msalInstance.loginRedirect({
            redirectStartPage: process.env.REACT_APP_REDIRECT_URI
        });
    }, 3000);
}

/**
 * Shows a response dialog based on the response code.
 * 
 * @param {Function} dispatch - The dispatch function to trigger actions.
 * @param {Number} code - The response code.
 * @param {Object} info - The response info.
 */
export const showResponseDialog = (dispatch, code, info = { msg: '', panel: { title: '', msg: '' } }) => {
    switch (code) {
        case 401:
            dispatch(showModal({ msg: "Please Reload to continue , If you have any unsaved data, close this message and copy the data before logging in again. If not, please log in again now.", variant: "ERROR", title: "Session Timed Out", show: true }))
            break;
        case 403:
            dispatch(showModal({
                msg: info.msg, variant: "ERROR", title: "Something went wrong in response", show: true, panel: {
                    msg: info.panel.msg,
                    title: info.panel.title
                }
            }))
        case 200:
            break;
        case 500:
            dispatch(showModal({
                msg: info.msg, variant: "ERROR", title: "Something went wrong in response", show: true, panel: {
                    msg: info.panel.msg,
                    title: info.panel.title
                }
            }))
            break;
    }
}
