

import { RootState, store } from "Store/mainStore";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCatalogDialog from "../Components/ProductCatalogDialog";
import { useLocalStateManagement } from "./useLocalStateManagement";
import { setTheme } from "Store/general/theme";
import { useConfigurator } from "./useConfigurator";
import useAddToCart from "./useAddToCart";
export const useProductDialogModal = (activePackages,packageData) => {

  const themeSelector = useSelector((state: RootState) => state.theme);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dispatch = useDispatch();
  const { getState } = useLocalStateManagement();
  const {resetDeviceAccessories} = useConfigurator();
  const { addToCartLogic } = useAddToCart();

  /** onProductCatalogDialogCancel */
  const onProductCatalogDialogCancel = (api_data_call) => {
    resetDeviceAccessories(
      getState("productDataList"),
      configstepperSelector.data.active_step,
      api_data_call[configstepperSelector.data.active_step]
    );
  };

  /** onProductCatalogDialogAdd */
  const onProductCatalogDialogAdd = (api_data_call) => {
    dispatch(
      setTheme({
        element: "modal",
        comp: "connectivity_catalog",
        prop: "isOpen",
        value: false,
      })
    );
    // Set options_global theme to disabled
    dispatch(
      setTheme({
        element: "button",
        comp: `options_global`,
        prop: "isDisable",
        value: true,
      })
    );
    // Set undo button
    dispatch(
      setTheme({
        element: "button",
        comp: `config_${configstepperSelector.data.active_step}_undo`,
        prop: "isToggle",
        value: true,
      })
    );
    // setisDisabled(addToCartLogic(activePackages,packageData,selectors));
    addToCartLogic(activePackages, packageData);
  };

  const openProductDialogModal = (api_data_call) => {
    return (
      <>
        {/** ProductCatalogDialog*/}
        {themeSelector.data.modal.isOpen.connectivity_catalog && (
          <ProductCatalogDialog
            onProductCatalogDialogCancel={() => onProductCatalogDialogCancel(api_data_call)}
            onProductCatalogDialogAdd={() => onProductCatalogDialogAdd(api_data_call)}
            apitocall={api_data_call[configstepperSelector.data.active_step]}
            // productDataList={reduxStore.dynamic.data.localStates["productDataList"]}
            productDataList={getState("productDataList")}
          />
        )}
      </>
    )
  }

  return {
    openProductDialogModal
  }
}