import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import { RootState } from "Store/mainStore";

import DashboardGridSkelton from "features/dashboard/components/Common/DashboardGridSkelton";
import DataGrid from "./DataGrid";
import { NoContentPage } from "features/dashboard/components/Common/NoContentPage";
import nothingToshow from "features/dashboard/assets/svg/nothing-to-show.svg";
import { IGridListProps } from "features/dashboard/types";
import useDashboard from "features/dashboard/hooks/useDashboard";

/**
 * DashboardGrid component
 * @returns {JSX.Element}
 */
const DashboardGrid = () => {
  const [tempLoading, setTempLoading] = useState(true);
  const [casesListData, setcasesListData] = useState<IGridListProps>({
    rowData: [],
    id_key: "",
    overlayNoRowsTemplate: "",
    columnDefs: []
  });
  const dashboardSelector = useSelector((state: RootState) => state.dashboard);
  const { getFormatedGridData } = useDashboard();

  useEffect(() => {
    // after the data is loaded then check the redux and update the grid
    if (!dashboardSelector.loading) {
      let rowData;
      if (
        dashboardSelector.data?.caseType &&
        !_.isEmpty(dashboardSelector.data?.caseResults)
      ) {
        rowData = dashboardSelector.data?.caseResults;
      }
      if (rowData) {
        if (dashboardSelector.data["caseType"] === "my_cases") {
          rowData = rowData[dashboardSelector.data["selectedStatus"]]?.["data"];
        } 
        else if (dashboardSelector.data["caseType"] === "favourites") {
          // console.log("---------",dashboardSelector.data["selectedStatus"])
          rowData = rowData[dashboardSelector.data["selectedStatus"]]?.["data"];
        }
        else {
          // NOTE : this is a temporary fix to handle the draft case data in all offers grid
          // Change this to fetch all offer data and show
          rowData = rowData['draft']?.["data"];
        }
        // hideAction should be decide based on the caseType
        let hideAction = ["draft","pending_approval"].includes(dashboardSelector.data["selectedStatus"])
        // Action coloumn and action buttons should be decide based on PI response data 
        const gridformattedData = getFormatedGridData(rowData, hideAction);
        setcasesListData(_ => gridformattedData);
      } else {
        rowData = [];
      }
      setTempLoading(false);
    }
  }, [
    dashboardSelector.data?.caseType,
    dashboardSelector.data?.["selectedStatus"],
    dashboardSelector.data?.pagination?.["itemsPerPage"],
    dashboardSelector.data?.pagination?.["currentPage"],
    dashboardSelector.data?.caseResults,
    dashboardSelector.loading,
  ]);

  return (
    <div>
      {tempLoading ? (
        <DashboardGridSkelton />
      ) : casesListData &&
        casesListData?.["rowData"] &&
        casesListData?.["rowData"].length > 0 ? (
        <div>
          <DataGrid {...casesListData} />
        </div>
      ) : (
        <NoContentPage
          // title="Nothing to show"
          title="No offers available"
          src={nothingToshow}
          msg=""
        />
      )}
    </div>
  );
};

export default DashboardGrid;
