import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { InteractionType } from "@azure/msal-browser";
import { ErrorBoundary } from "react-error-boundary";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";


import "./App.css";
import "@nokia-csf-uxr/nokia-design-system-tokens/css/_variables.css";
import { AdvancedTheme, App } from "@nokia-csf-uxr/ccfk";


import ErrorFallback from "./ErrorFallback";
import AppRouter from "./AppRouter";
import { Login } from "Pages/Login";


const EnsoBp = () => {
  useMsalAuthentication(InteractionType.Redirect); //to auto redired login
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnWindowFocus: false },
    },
  });
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AdvancedTheme
          advancedTheme={"CCFK FreeForm - Light"}
          addGlobalStyle={true}
          addGlobalVariables={true}
        >
          {process.env.REACT_APP_NODE_ENV === "development" ? (
            <App disableAnimation={false}>
              <AuthenticatedTemplate>
                <AppRouter />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Login />
              </UnauthenticatedTemplate>
            </App>
          ) : (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <App disableAnimation={false}>
                <AuthenticatedTemplate>
                  <AppRouter />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Login />
                </UnauthenticatedTemplate>
              </App>
            </ErrorBoundary>
          )}
        </AdvancedTheme>
      </QueryClientProvider>
    </>
  );
};

export default EnsoBp;
