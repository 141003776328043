import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Snackbar } from "@nokia-csf-uxr/ccfk";
import { SnackbarContent } from "@nokia-csf-uxr/ccfk/Snackbar";

import { showModal, showPopup } from "Store/general/auth";
import { RootState } from "Store/mainStore";
import DialogModal from "Components/Elements/DialogModal";
import { setTheme } from "Store/general/theme";
import { deserializeFunction } from "Utils";

const AlertDialogModal = () => {
    const dispatch = useDispatch();
    const themeSelector = useSelector((state: RootState) => state.theme);
    const authSelector = useSelector((state: RootState) => state.authSlice);
    return (<>
        <Snackbar onClick={() => dispatch(showPopup({ msg: "", show: !authSelector.system.popup.show }))} autoCloseDuration={2000} open={authSelector.system.popup.show} autoClose={true} >
            <SnackbarContent>{authSelector.system.popup.msg}</SnackbarContent>
        </Snackbar>
        <DialogModal
            type="popup"
            isOpen={authSelector.system.modal.show}

            popInfo={{
                msg: authSelector.system.modal.msg,
                title: authSelector.system.modal.title,
                variant: authSelector.system.modal.variant,
                panel: {
                    msg: authSelector.system.modal.panel.msg,
                    title: authSelector.system.modal.panel.title,
                    show: themeSelector.data.panel.isOpen.global,
                    onExpand: () => {
                        dispatch(
                            setTheme({
                                element: "panel",
                                comp: "global",
                                prop: "isOpen",
                                value: !themeSelector.data.panel.isOpen.global,
                            })
                        );
                    }
                }
            }}
            //@ts-ignore
            actionBtns={authSelector.system.modal.action.length === 0 ? [{
                label: "Close", variant: "call-to-action", onAction: () => {
                    dispatch(showModal({
                        msg: "",
                        panel: { title: '', msg: '' },
                        show: !authSelector.system.modal.show,
                    }))
                }
            }] : authSelector.system.modal.action.map((payload) => new Object({ ...payload, onAction: deserializeFunction(payload.onAction) }))} />
    </>);

};
export default AlertDialogModal;