import React, { useEffect, useState } from "react";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import TextInput, {
  TextInputLabelContent,
} from "@nokia-csf-uxr/ccfk/TextInput";
import SliderComponent from "Components/Elements/Slider";
import { HelpText } from "../hooks/useHelpText";

interface DataList {
  data: Array<any>;
  active_step: string;
  updateDispatchStore?: Function;
  isDisabled: boolean;
  errorMessageText?: Function;
  ref: object;
  dataStore?:Object;
}

const RangeField = (props: DataList) => {
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const label = props.data[1].required
    ? props.data[1].label + " *"
    : props.data[1].label;
  const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);
  // readonly used only for spinner field , text field and range field
  const disableState = props.data[1].readonly
    ? props.data[1].readonly
    : props.isDisabled;
  const { showHelpText } = HelpText();

  useEffect(() => {
    if (props.data[1].required) {
      if (props.dataStore) {
        if (
          props.dataStore[props.data[0]] &&
          props.dataStore[props.data[0]] !==
            "" &&
          props.dataStore[props.data[0]].key !==
            0
        ) {
          setErrorDisabled(false);
        } else {
          setErrorDisabled(true);
        }
      }
    }
  }, [props.dataStore]);

  const getReturnRangeValue = () => {
    let selectedValue = dynamicSelector.data.elementMMS[props.active_step] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]] && dynamicSelector.data.elementMMS[props.active_step][
      props.data[0]
    ]["min"]
      ? dynamicSelector.data.elementMMS[props.active_step][props.data[0]]["min"]
      : 0;
    if (
      props.dataStore &&
      props.dataStore[props.data[0]] &&
      props.dataStore[props.data[0]].key
    ) {
      selectedValue =
        props.dataStore[props.data[0]].key;
    }
    return parseInt(selectedValue);
  };

  const getKeyvalueData = (dataSet, selectkey) => {
    let selectKeyValue = {};
    selectKeyValue["key"] = selectkey;
    selectKeyValue["value"] = selectkey;

    props.updateDispatchStore(dataSet, selectKeyValue);
  };

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <div style={{ display: "flex" }}>
          <TextInputLabelContent>{label}</TextInputLabelContent>
          {showHelpText(props.data)}
        </div>
        <SliderComponent
          ref={props.ref as React.Ref<HTMLElement>}
          minDuration={
            dynamicSelector.data.elementMMS[props.active_step] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]][
              "min"
            ]
          }
          maxDuration={
            dynamicSelector.data.elementMMS[props.active_step] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]][
              "max"
            ]
          }
          stepBy={
            dynamicSelector.data.elementMMS[props.active_step] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]][
              "steps"
            ]
          }
          disabled={disableState}
          sliderStateValue={getReturnRangeValue()}
          handleChange={(e) => {
            getKeyvalueData(props.data[0], e.value);
          }}
        />
        <div style={{
            color:'var(--g-color-global-secondary-placeholder)',
            fontFamily: 'var(--ff-typography-body)',
            fontStyle: 'italic',
            textAlign:'right'
        }}>
            [Slider value will be multiple of stepper value (<b>{dynamicSelector.data.elementMMS[props.active_step] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]] && dynamicSelector.data.elementMMS[props.active_step][props.data[0]]['steps']}</b>)]
        </div>
      </div>
      {errorDisabled ? <>{props.errorMessageText(props.data[1].label)}</> : ""}
    </>
  );
};

export default RangeField;
