import React from "react";
import _ from "lodash";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

import { DataGrid, Label } from "@nokia-csf-uxr/ccfk";

import { RootState } from "Store/mainStore";

interface DataGridList {
  boqGridList: any
}

const BoqDataGrid = (props: DataGridList) => {
  // Selectors
  const cartBoqSelector = useSelector(
    (state: RootState) => state.undoCartBoq.present
  );
  const autoSizeFitAll = (gridOptions) => {
    window['gridOptions'] = gridOptions;
    const allColumnIds = [];
    gridOptions?.columnApi?.getColumns()?.forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridOptions?.columnApi?.autoSizeAllColumns(false);
  };


  return (
    <div id='cart' style={{ width: '100%', height: '100%' }}>
      {
        (props.boqGridList).map((eachSection, index) => {
          // loop each from list to show different sections of data grid
          return <div id={eachSection.id_key} className='mt mb' key={index}>
            <Label style={{ fontWeight: 'bold' }} className='mb'>{eachSection.label}</Label>
            <DataGrid
              suppressMovableColumns={true}
              isRowDrag={true}
              key={eachSection.id_key}
              wrapperProps={{
                style: {
                  width: '100%'
                }
              }}

              rowData={eachSection.rowData}
              defaultColDef={{
                editable: false,
                sortable: true,
                flex: 1,
                minWidth: 100,
                filter: true,
                resizable: true,
              }}
              overlayNoRowsTemplate={eachSection.overlayNoRowsTemplate}
              isInlineRowActions={cartBoqSelector.data.isBoQEditable && cartBoqSelector.data.isBoQEditable === true ? true : false}
              domLayout='autoHeight'
              sideBar
              columnDefs={eachSection.columnDefs}
              onGridReady={(gridOptions) => {
                autoSizeFitAll(gridOptions)
              }}
              alwaysShowHorizontalScroll={false}
              alwaysShowVerticalScroll={false}
            />
          </div>
        })
      }
    </div>
  )
}

export default React.memo(BoqDataGrid);
BoqDataGrid.propTypes = {
  boqGridList: PropTypes.array
}