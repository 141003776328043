import { PublicClientApplication } from '@azure/msal-browser';
import { OOT_GLOBAL, apiTimeout } from "Components/Misc/CaseDetails_System";
import { showModal } from "Store/general/auth";
import { msalConfig } from "Auth/authConfig";
import axios from "axios";
import { handleAxiosInterceptorsReponse, showResponseDialog } from './common';
//https://corsproxy.io/? if this is the issue lets this will integrate from the OOT.

class OOT_API {
  accessToken;
  email;
  idtoken;
  redX;
  constructor() {
    this.interceptRequests();
  }
  init({ accessToken, email, idtoken, redX }) {
    if (accessToken && email) {
      this.accessToken = accessToken;
      this.email = email;
      this.idtoken = idtoken;
      this.redX = redX;
    } else {
      return { msg: "Failed to initiate." };
    }
  }

  // Intercept the requests and responses
  interceptRequests() {
    // Axios request interceptor
    axios.interceptors.response.use((response) => {
      // Response interceptor to handle the response
      return handleAxiosInterceptorsReponse(response, this.redX.dispatch);
    }, (error) => {
      // Response interceptor to handle the error
      showResponseDialog(this.redX.dispatch,500, { msg: error.code, panel: { title: 'Failed to connect server', msg: error.message } });
      return Promise.reject(error);;
    });
    // AAxios request interceptor
    axios.interceptors.request.use((request) => {
      if (!request?.["skipAuth"]) {
        // Add headers only if `skipAuth` is not set
        request.headers["Content-Type"] = "application/json; charset=UTF-8";
        request.headers["Authorization"] = "Bearer " + this.accessToken;
        delete request.headers["idtoken"];
        request.headers["idtoken"] = OOT.idtoken;
        request.timeout = 1000 * apiTimeout;
      }
      return request;
    }, (error) => {
      return Promise.reject(error);
    });
  }
  
  // Mehotd to sync the offer
  syncOffer = async ({ data }) => {
    const params = `?username=${this.email}`;
    const res = await axios({
      method: "post",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.syncOffer + params,
      data: data,
    });
    return res.data;
  };

  // Method to get the opportunity
  getOpportunity = async ({ scrm_id }) => {
    const params = `?_format=json&username=${this.email}&scrm_id=${scrm_id}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getOpportunity + params,
    });
    return res.data;
  };

  // Method to get the customer
  getCustomer = async ({ account_nid }) => {
    if (!account_nid) {
      //Added-protection not fire fire for empty acc_nid.
      return;
    }
    //this runs when scrm is invalid;
    const params = `?_format=json&username=${this.email}&account_nid=${account_nid}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getCustomer + params,
    });

    return res.data;
  };

  // Method to get the account customer
  getAccountCustomer = async ({ account_nid }) => {
    //this runs when scrm is invalid;
    const params = `?_format=json&username=${this.email}&nid=${account_nid}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getAccountCustomer + params,
    });

    return res.data;
  };

  // Method to get the country bands
  getCountryBands = async ({ country }) => {
    //this runs when scrm is invalid;
    const params = `?_format=json&country=${country}&username=${this.email}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getCountryBands + params,
      // url:"http://localhost:3000/country-bands"
    });
    return res.data;
  }

  getProducts = async (category, account, selector ) => {
    const conditionalparam = `account=${account}&sales_channel=${selector.data.sales_channel}&currency=${selector.data.currency}`;
    //this runs when scrm is invalid;
    const params = `?_format=json&category=${category}&` + conditionalparam + `&username=${this.email}&flatten=true`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getProducts + params,
    });

    return res.data;
  }
  getApplicationTab = async ({ account, selector }) => {
    const conditionalparam = `account=${account}&sales_channel=${selector.data.sales_channel}&currency=${selector.data.currency}`;

    //http://localhost:5000/api/get-applicationTab?_format=json&username=nitesh.7.kumar@nokia.com&account=1000001115
    const params = `?_format=json&` + conditionalparam + `&username=${this.email}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getApplicationTab + params,
      //url:"http://localhost:3000/applications"
    });

    return res.data;
  }
  getConnectivityTab = async ({ account, radioBand, selector }) => {
    const conditionalparam = `account=${account}&sales_channel=${selector.data.sales_channel}&currency=${selector.data.currency}`;
    //http://localhost:5000/api/get-connectivityTab?_format=json&username=nitesh.7.kumar@nokia.com&account=1000001115&radioBand=B48
    const params = `?_format=json&` + conditionalparam + `&username=${this.email}&radioBand=${radioBand}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getConnectivitytab + params,
      //url:"http://localhost:3000/connectivity" enjoy
    });

    return res.data;
  }

  getEdgeTab = async ({ account, selector }) => {
    const conditionalparam = `account=${account}&sales_channel=${selector.data.sales_channel}&currency=${selector.data.currency}`;
    //http://localhost:5000/api/get-edgeTab?_format=json&username={{email}}&account=1000001115
    const params = `?_format=json&` + conditionalparam + `&username=${this.email}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getEdgeTab + params,
      //url:"http://localhost:3000/edge"
    });
    return res.data;
  }
  getDevicesTab = async ({ account, selector }) => {
    const conditionalparam = `account=${account}&sales_channel=${selector.data.sales_channel}&currency=${selector.data.currency}`;
    //http://localhost:5000/api/get-deviceTab?_format=json&username={{email}}&account=1000001115
    const params = `?_format=json&` + conditionalparam + `&username=${this.email}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getDevicesTab + params,
    });
    return res.data;
  }
  getServicesTab = async ({ account, caseData, shipping_country_code, currency_code, selector, plaftform = 'PTDAC' }) => {
    const conditionalparam = `account=${account}&sales_channel=${selector.data.sales_channel}&currency=${selector.data.currency}`;

    const params = `?_format=json&` + conditionalparam + `&username=${this.email}&shipping_country_code=${shipping_country_code}&currency_code=${currency_code}&platform=${plaftform}`;
    const res = await axios({
      method: "POST",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getServicesTab + params,
      data: caseData
    });
    // const res = await axios.all([ndacServices, ndacProfServices]);
    return {
      mpw_services: res?.data.data,
    };
  }

  getWarrantyTab = async ({ account, selector }) => {
    const conditionalparam = `account=${account}&sales_channel=${selector.data.sales_channel}&currency=${selector.data.currency}`;
    //http://localhost:5000/api/get-sla_warranty?_format=json&username={{email}}&account=1000001115
    const params = `?_format=json&` + conditionalparam + `&username=${this.email}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getWarrantyTab + params,
    });
    return res.data;
  }



}
const OOT = new OOT_API();
export default OOT;
