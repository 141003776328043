export const BREADCRUMB_ACTIONS = [
  {
    id: "my_cases",
    name: "My cases",
  },
  {
    id: "all_cases",
    name: "All",
  },
  {
    id: "favourites",
    name: "Favourites",
  },
];

export const FILTER_TOOLBAR_TABS = [
  {
    id: "DOTOffers",
    name: "DOT offers",
  },
  {
    id: "OOTOffers",
    name: "OOT offers",
  },
  {
    id: "Archived",
    name: "Archived",
  },
];

export const OFFER_SORT_OPTIONS = [
  {
    key: "-createdAt",
    value: "Created date",
  },
  {
    key: "total_boq_price",
    value: "Price (low to high)",
  },
  {
    key: "-total_boq_price",
    value: "Price (high to low)",
  }
];

export const OFFER_STTATUS = [
  {
    id: "draft",
    name: "Draft",
    avatar: "D",
    color: "var(--color-status-fault)",
    // --color-status-fault: #c7cdd6;
  },
  {
    id: "order",
    name: "Order",
    avatar: "O",
    color: "var(--color-brand-primary-dark)",
    // --color-info-dark
    // --color-brand-primary-dark: #005aff;
  },
  {
    id: "pendingapproval",
    name: "Pending approval",
    avatar: "PA",
    color: "var(--color-status-major)",
    // --color-warning: #f7b737;
  },
  {
    id: "approved",
    name: "Approved",
    avatar: "A",
    color: "var(--color-status-success)",
    // --color-status-success: #25b04d;
  },
  {
    id: "requesteddelivery",
    name: "Delivery requested",
    avatar: "DR",
    color: "var(--color-status-minor)",
  },
  {
    id: "accepteddelivery",
    name: "Delivery accepted",
    avatar: "DA",
    color: "var(--color-info)",
    // --color-info: #0049b4;
  },
  {
    id: "Confirmed Delivery",
    name: "Delivery confirmed",
    avatar: "DC",
    color: "var(--color-status-warning)",
    // #006076
  },
  {
    id: "subscription_license_released",
    name: "Subscription & License released",
    avatar: "SLR",
    color: "var(--color-status-maintenance)",
  },
];

export const PAGINATION_ITEMS_PER_PAGE = [
  { key: 10, value: 10 },
  { key: 25, value: 25 },
  { key: 50, value: 50 },
  { key: 100, value: 100 },
];
