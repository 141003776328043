import { setProp } from "Store/Actions";
import { setCount_of_data, setDataSet,setDynamicBatchData } from "Store/contentState/configurationArea/dynamicSlice";
import _ from "lodash";
import { RootState, store } from "Store/mainStore";
import { sCRMRegex } from "Components/Misc/CaseDetails_System";
import { useDispatch, useSelector } from "react-redux";
import { useStatesOfElement } from "./useStatesOfElement";
import React from "react";
import dotCounters from "Constants/counterVars.json";
import useCommon from "./useCommon";

const useCartUtility = () => {
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const configstepperSelector = useSelector((state: RootState) => state.config_stepper);
  const findSelector = useSelector((state: RootState) => state.findCase);
  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const allRefs = React.useRef([]);
  const { getElementsStates } = useStatesOfElement(allRefs);
  const dispatch = useDispatch();
  const { startsWithCheck_countOf } = useCommon();

  const undoCallBackDefault = () => {
    const currentStep = configstepperSelector?.data?.active_step;
    const callbackDefaults = dynamicSelector?.data?.callbackdefaults || {};
    const allOtherSteps = _.pull(_.keys(callbackDefaults), currentStep);
    let callbackForEachStep = {};
    _.forEach(allOtherSteps, (eachStepName) => {
      callbackForEachStep[eachStepName] = null;
    });
    dispatch(setDynamicBatchData({ slice: `callbackdefaults`, data: callbackForEachStep }));
    // set value of defaultcallbackValue as null
    // dispatch(setProp({ slice: "dynamic", key: `defaultcallbackValue`, value: null }));
  }

  const setNextStepsNull = (currentOptions, dataset) => {
    _.forEach(dataset, (value, key) => {
      if (!_.includes(['commercial_model', 'contract_duration', 'country', 'currency', 'case_type', 'case_sub_type'], key)) {
        if (!currentOptions.hasOwnProperty(key)) {
          dataset[key] = null;
        }
      }
    });
    return dataset;
  };

  const findModalObject = (obj, findKey) => {
    if (_.isArray(obj)) {
      return null;
    }
    if (obj && typeof obj === 'object') {
      if (obj[findKey]) {
        return obj[findKey];
      }
      for (let key in obj) {
        const result = findModalObject(obj[key], findKey);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  /**
   * Appends RRH and Microwave configuration data to the given cart data.
   * 
   * @param {Object} cartData - The current cart data to which the configuration will be appended.
   * @returns {Object} - The updated cart data with appended RRH and Microwave configuration.
   */
  const appendRRHConfigMPW = (cartData,) => {
    if (dynamicSelector.data.modal && !_.isEmpty(dynamicSelector.data.modal)) {
      // RRH Configuration
      const rrhObj = findModalObject(dynamicSelector.data.modal, 'RRH');
      let config_data = {};
      if (rrhObj && !_.isEmpty(rrhObj)) {
        const rrhconfig = _.values(rrhObj);
        // const rrhdata =  _.map(rrhconfig, obj => _.mapValues(obj,each => each.key ));
        const rrhdata = _.map(rrhconfig, obj => {
          return _.mapValues(obj, each => {
            if (_.isArray(each)) {
              return _.map(each, item => item.key);
            }
            return each.key;
          });
        });
        config_data["rrh_configuration"] = rrhdata ? rrhdata : [];
      }

      // microwave_configuration
      const mwObj = findModalObject(dynamicSelector.data.modal, 'MW');
      if (mwObj && !_.isEmpty(mwObj)) {
        const mwconfig = _.values(mwObj);
        // const rrhdata =  _.map(rrhconfig, obj => _.mapValues(obj,each => each.key ));
        const mwdata = _.map(mwconfig, obj => {
          return _.mapValues(obj, each => {
            if (_.isArray(each)) {
              return _.map(each, item => item.key);
            }
            return each.key;
          });
        });
        config_data["microwave_configuration"] = mwdata ? mwdata : [];
      }
      cartData = { ...cartData, ...config_data };
    }
    return cartData;
  }
  /** appendRadioExcelData */
  const appendRadioExcelData = (cartData,) => {
    const radioExcelJson = findModalObject(dynamicSelector.data.modal, 'radioExcelJson');
    if (radioExcelJson && !_.isEmpty(radioExcelJson)) {
      let config_data = { 'radioExcelJson': radioExcelJson };
      cartData = { ...cartData, ...config_data };
    }
    return cartData;
  }

  // common method for product list preparation to loop the items and push to given variable if item is array
  const prepareProductList = (data, type = 'array') => {
    let productlist = [];
    if (type === 'array') {
      Object.entries(data[1]).forEach(item => {
        if (item[1] && (_.isArray(item[1])) && item[1].length > 0) {
          const checkitems = item[1].filter((itm) => itm.checked);
          if (checkitems) {
            productlist.push(item[1].filter((itm) => itm.checked).map((ite) => ite.id))
          }
        }
      });
    } else {
      Object.entries(data[1]).forEach(item => {
        if (item[1] && _.isArray(item[1])) {
          productlist = (item[1].filter((itm) => itm.checked)
            .map((ite) => ({ id: ite.id, product_code: ite.product_code })));
        }
      });
    }
    return productlist;
  }

  // function for services to send as group in ndac ref solution
  const get_ServiceDataGroup = (servicesDataList) => {
    let end_end_services_list = [];
    servicesDataList.forEach(element => {
      // if ':' is present and whatever is previous to ':' are same it will be grouped as one service
      let aa = element.work_package.split(":");
      let end_end_services_details = {}
      end_end_services_details['total_pnp_with_incoterm'] = element.total_pnp_with_incoterm;
      end_end_services_details['work_package'] = _.trim(aa[0]);
      end_end_services_details['duration'] = element.duration ? element.duration : 1;
      end_end_services_list.push(end_end_services_details)
    });
    let end_end_groups = {}
    end_end_services_list.forEach((employee) => {
      // let { work_package } = employee;
      if (!end_end_groups[employee.work_package]) {
        end_end_groups[employee.work_package] = [];
      }
      end_end_groups[employee.work_package].push(employee);
    });
    let end_end_servicesData_List = []
    Object.entries(end_end_groups).map((data, _index) => {
      let data_list = {};
      data_list['work_package'] = data[0];
      data_list['cost'] = end_end_groups[data[0]].map(o => o.total_pnp_with_incoterm).reduce((a, c) => { return a + c });
      data_list['type'] = 'end_end_services';
      data_list['oot_displaySection'] = 'deployment_technical';
      data_list['duration'] = end_end_groups[data[0]][0].duration;
      end_end_servicesData_List.push(data_list);
    })
    return end_end_servicesData_List
  }

  const getPackageCount = (configstepperSelector, dynamicSelector, dispatch, undo) => {
    let packageCount = store.getState().dynamic.data.package_options_count;
    if (configstepperSelector.data.active_substep) {
      if (undo) {
        const stepNumber = _.chain(configstepperSelector.data.stepper_data)
          .filter((e) => {
            const substep = e.subSteps && _.filter(e.subSteps, (sub) => sub.stepname === configstepperSelector.data.active_substep);
            return substep && substep.length > 0;
          })
          .flatMap((e) => _.filter(e.subSteps, (sub) => sub.stepname === configstepperSelector.data.active_substep))
          .map((sub) => sub.stepNumber)
          .first() // Get the first match
          .value();

        const undoCount = dynamicSelector.data.package_options_count < stepNumber ? dynamicSelector.data.package_options_count - 1 : stepNumber - 1;
        packageCount = undoCount >= 0 ? undoCount : 0;
      } else {
        packageCount = dynamicSelector.data.package_options_count + 1;
      }
      dispatch(setProp({ slice: "dynamic", key: 'package_options_count', value: packageCount }));
    } else {
      if (undo) {
        packageCount = packageCount - 1;
      } else {
        packageCount = packageCount + 1;
      }
    }
    return packageCount;
  }

  /** addStepFormInputs
   * Method for addiing step wise user input details
   */
  const addStepFormInputs = (addCartValues, packageData) => {
    const activeStep = configstepperSelector.data.active_step;
    // Get all the steps
    const stepperData = configstepperSelector.data.stepper_data;
    // Get current stepObject
    const currentStepObject = stepperData.find((e) => e.stepname === activeStep);
    // Take current StepFormInputs data from store
    let newStepFormInputs = _.cloneDeep(dynamicSelector.data.stepFormInputs);
    if (!newStepFormInputs[activeStep] || !(currentStepObject['isChildActive'] === true)) {
      newStepFormInputs[activeStep] = {};
    }
    // Get all field keys for the active step
    Object.keys(addCartValues).forEach(fieldKey => {
      if (packageData?.[fieldKey]) {
        const fieldValue = prepareFormatedFieldValue(addCartValues?.[fieldKey]);
        newStepFormInputs[activeStep][fieldKey] = {
          type: packageData?.[fieldKey]?.type,
          id: packageData?.[fieldKey]?.id,
          label: packageData?.[fieldKey]?.label,
          value: fieldValue
        }
      }
    });
    dispatch(setProp({ slice: "dynamic", key: 'stepFormInputs', value: newStepFormInputs }));
  }
  /** prepareFormatedFieldValue */
  const prepareFormatedFieldValue = (inputValue) => {
    if (Array.isArray(inputValue) && inputValue.every(obj => typeof obj === 'object' && 'key' in obj && 'value' in obj)) {
      return inputValue;
    }
    if (inputValue === null || inputValue === undefined) {
      return null;
    }
    if (typeof inputValue === 'string' ||
      typeof inputValue === 'number' ||
      (Object.keys(inputValue).includes('key') && Object.keys(inputValue).includes('value'))) {
      return inputValue;
    }
    let formattedInputValue = {};
    Object.keys(inputValue).forEach(iFr => {
      if (inputValue[iFr] && _.isArray(inputValue[iFr])) {
        formattedInputValue[iFr] = inputValue[iFr].filter(iFrEl => iFrEl?.checked === true);
      }
      // --- Some more logic may be needed here ??
    })
    return formattedInputValue;
  }
  /** removeStepFormInputs
   * Method for removing step wise user input details On undo action
   */
  const removeStepFormInputs = (cartItems, packageData) => {
    // Get current active step
    const activeStep = configstepperSelector.data.active_step;
    // Get all the steps
    const stepperData = configstepperSelector.data.stepper_data;

    // Get current stepObject
    const currentStepObject = stepperData.find((e) => e.stepname === activeStep);
    // Get all further step list
    const furtherStepList = [];
    stepperData.forEach(step => {
      if (_.toInteger(step.stepNumber) > _.toInteger(currentStepObject.stepNumber)) {
        furtherStepList.push(step.stepname)
      }
    });

    // Take the curent stepFormInputs store copy
    let newStepFormInputs = _.cloneDeep(dynamicSelector.data.stepFormInputs);

    // Check whether current active step is having sub steps
    if (currentStepObject['isChildActive'] === true) {
      // Get active sub step
      const activeSubstep = configstepperSelector.data.active_substep;
      const subSteps = currentStepObject['subSteps'];

      // Get current active sub step object
      const currentSubStepObject = subSteps.find((e) => e.stepname === activeSubstep);
      let furtherSubStepFieldList = [];

      subSteps.forEach(subStep => {
        // Take sub step till current active sub step - to keep in the store
        if (parseInt(subStep.stepNumber) >= parseInt(currentSubStepObject.stepNumber)) {
          const __fields = getSubStepFieldList(activeStep, subStep.stepname, configstepperSelector);
          furtherSubStepFieldList = [...furtherSubStepFieldList, ...Object.keys(__fields)];
        }
      });

      // Loop through the current step store and prepare new store by excluding the furtherSubStepFieldList
      let updatedStepStore = {};
      Object.keys(newStepFormInputs[activeStep]).forEach(storeField => {
        if (!furtherSubStepFieldList.includes(storeField)) {
          updatedStepStore[storeField] = newStepFormInputs[activeStep][storeField];
        }
      });
      // Update store with updated state
      newStepFormInputs[activeStep] = updatedStepStore;
    }
    else {
      // Take all the further steps + current step to the list for remove state
      furtherStepList.unshift(currentStepObject.stepname)
    }
    // Exclude all the step in the list - furtherStepList
    const updatedStepFormInputs = {}
    Object.keys(newStepFormInputs).forEach(stepName => {
      if (!furtherStepList.includes(stepName)) {
        updatedStepFormInputs[stepName] = newStepFormInputs[stepName];
      }
    });
    // Dispatch updated store data 
    dispatch(setProp({ slice: "dynamic", key: 'stepFormInputs', value: updatedStepFormInputs }));
  }

  /** getSubStepFieldList */
  const getSubStepFieldList = (stepName, subStepName, configstepperSelector) => {
    const packageData = configstepperSelector.data.solution_form_data["packages"];
    let field_details = {};
    if (
      packageData &&
      packageData[stepName] &&
      packageData[stepName].field_details
    ) {
      let fieldList = packageData[stepName].field_details;
      if (fieldList[subStepName] && fieldList[subStepName].field_details) {
        // Handle the sub step field lis
        fieldList = fieldList[subStepName].field_details
      }
      Object.keys(fieldList).forEach((field) => {
        if (fieldList[field].type === "container") {
          field_details = { ...field_details, ...fieldList[field].field_details };
        }
        else {
          field_details = { ...field_details, ...fieldList[field] };
        }
      });
    }
    return field_details;
  }

  /** setCartValues */
  const setCartValues = (setAddCardValues, addCartValues, packageData, datasetData) => {
    let considerValArrMxie = [];
    Object.entries(addCartValues).map((data, _index) => {
      // add this if required later - for cmu
      const stateOfElement = packageData[data[0]] ? getElementsStates(packageData[data[0]]) : undefined;
      // const stateOfElement = selector.dynamicSelector.stateInfo[selector.configstepperSelector.data.active_step][packageData[data[0]]];
      if (stateOfElement && stateOfElement['visibleValue'] !== 'none') {
        if (data[1] !== '') {
          let productlist = [];

          if (data[0] && packageData[data[0]] && packageData[data[0]]['data_callback'] && packageData[data[0]]['data_callback']['button']) {
            productlist = prepareProductList(data);
            setAddCardValues[data[0]] = productlist.flat()
          }
          else {
            if (Array.isArray(data[1])) {
              setAddCardValues[data[0]] = data[1].map((item) => item.key)
            }
            else if (typeof data[1] === 'object') {
              if (packageData[data[0]] && packageData[data[0]]['data_callback'] && packageData[data[0]]['data_callback']['target_field'] === 'mxie_server') {
                productlist = prepareProductList(data, 'object');
                if (productlist && productlist.length > 0) {
                  const selectedMXIE = productlist[0].product_code;
                  setAddCardValues[data[0]] = productlist[0].id;
                  // add mxie step attributes for selected mxie server
                  if (_.includes(_.keys(dynamicSelector.data.element[configstepperSelector.data.active_step]), 'selected_mxie_server')) {
                    dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.selected_mxie_server`, value: selectedMXIE }));
                    datasetData['selected_mxie_server'] = selectedMXIE;
                  }
                }
              }
              else {
                if (configstepperSelector.data.active_step === 'mxie') {
                  considerValArrMxie = ['vcpu', 'storage', 'memory', 'pod'];// TODO: Fix this hardcoded
                }
                // if the step  mxie and type is hidden then send values and not keys
                setAddCardValues[data[0]] = data[1] !== undefined && data[1] !== null ? (_.includes(considerValArrMxie, data[1]['key']) ? data[1]['value'] : data[1]['key']) : null;
              }
            }
            else {
              if (data[0] === 'selected_mxie_server') {
                setAddCardValues[data[0]] = datasetData['selected_mxie_server'];
              } else {
                setAddCardValues[data[0]] = data[1]
              }
            }
          }
        }
      } else {
        if (startsWithCheck_countOf(data[0])) {
          setAddCardValues[data[0]] = data[1]['key'];//data[1].map((item) => item.key)
        }
      }
    });

    Object.keys(packageData).forEach(function (itm) {
      const stateOfElement = getElementsStates(packageData[itm]);
      // const stateOfElement = selector.dynamicSelector.stateInfo[selector.configstepperSelector.data.active_step][packageData[itm]];
      if (stateOfElement['visibleValue'] === 'none') {
        if (datasetData[itm] && (packageData[itm].type !== 'hidden')) {
          datasetData = _.omit(datasetData, itm);
          dispatch(setProp({ slice: "dynamic", key: 'dataSet', value: datasetData }));
        } else if (packageData[itm].type === 'hidden' && startsWithCheck_countOf(itm)) {
          dispatch(setProp({ slice: "dynamic", key: 'dataSet', value: datasetData }));
        }
      }
    });
  }

  const addHiddenFieldData = (setAddCardValues, addCartValues, countData,) => {
    // send curreny and country in payload in all solutions
    // prepare data to send to config api
    const caseData = prepareCaseDetailsData();
    setAddCardValues = Object.assign(setAddCardValues,caseData );
    const solutionCategory = configstepperSelector.data.solutionCategory;

    if (_.includes(['NDAC', 'MPW'], solutionCategory)) {
      const commercialModel = _.toUpper(dynamicSelector.data.reference_solution.commercial_model);
      setAddCardValues['commercial_model'] = dynamicSelector.data.reference_solution.commercial_model;
      setAddCardValues['contract_duration_months'] = (commercialModel === "OPEX" || commercialModel === "OPERATING_LEASE") ? dynamicSelector.data.reference_solution.contract_duration : "";
      setAddCardValues['contract_duration_years'] = commercialModel === "CAPEX" ? dynamicSelector.data.reference_solution.contract_duration : "";

      _.map(countData, (counter, key) => {
        setAddCardValues[key] = counter;
        addCartValues[key] = counter;
      });
    }
  }

  const setCount_of_parameters = (activePackages, undo_addCart) => {
    let countArg = dotCounters.dot_count_of;
    const activestep = configstepperSelector.data.active_step;
    const cartData = dynamicSelector.data.element[activestep];
    let items = [];
    if (cartData && cartData.connectivity_ndac_items) {
      items = cartData.connectivity_ndac_items;
    } else if (cartData && cartData.ndac_device_items) {
      items = cartData.ndac_device_items;
    } else if (cartData && cartData.das_items) {
      items = cartData.das_items;
    }

    let catalogItems = [];
    if (cartData && cartData.catalog_items) {
      catalogItems = cartData.catalog_items;
    } else if (cartData && cartData.ndac_accessories) {
      catalogItems = cartData.ndac_accessories;
    }


    // let catalogItems = cartData.catalog_items ? cartData.catalog_items : cartData.ndac_accessories;
    if (_.keys(items).length > 0 && _.keys(catalogItems).length > 0) {
      items = { ...items, ...catalogItems }
    } else if (_.keys(items).length === 0 && _.keys(catalogItems).length > 0) {
      items = catalogItems;
    }

    const countData = {};
    let count_of_variables = {};
    if (!_.isEmpty(items) && items['All']) {
      const groupedData = _.groupBy(items['All'], 'product_id');
      items = { ...items, ...groupedData };
      delete items['All'];
    }
    for (let eachFamily in items) {
      if (typeof count_of_variables[activestep] !== 'object' || count_of_variables[activestep] === null) {
        count_of_variables[activestep] = {};
      }
      const eachFamilyName = eachFamily.toLowerCase();
      let valueset = { 'key': 0, 'value': 0 };
      if (!undo_addCart) {
        const data = _.filter(items[eachFamily], (each) => _.some(each, (item) => item === true));
        if (data.length > 0) {
          const countof_family = _.sumBy(data, obj => _.get(obj, 'quantity', 0));
          valueset = { 'key': countof_family, 'value': countof_family };
        }
      }

      if (valueset['key'] > 0) {
        const countArgument = countArg + eachFamilyName;
        countData[countArg + eachFamilyName] = valueset;
        count_of_variables[activestep][countArgument] = valueset;
      }
    }
    // total count of radio access points calculate and store in redux
    let sum_of_radioKits = 0;
    if (activestep === 'connectivity') {
      sum_of_radioKits = _.sum(_.map(countData, 'value'));
      dispatch(setProp({ slice: "dynamic", key: `element.${activestep}.${dotCounters.dot_number_of_access_points}`, value: { 'key': sum_of_radioKits, 'value': sum_of_radioKits } }));
    }
    countData[dotCounters.dot_number_of_access_points] = sum_of_radioKits;
    // group all the count variables and dispatch at once
    dispatch(setCount_of_data(count_of_variables));
    return countData;
  }

  const prepareCaseDetailsData = (addElementDispatch?) => {
    const cartValues = {};
    const currstep = configstepperSelector.data.active_step;
    const currstep_keys = _.keys(dynamicSelector.data.element[currstep]);
    currstep_keys.push("networkcountry"); // name in the configurator package is network_country
    const caseDetailsData = _.cloneDeep(caseDetailsSelector.data);
    // filter out properties that are not required to be sent to configurator
    const excludeProps = ['account', 'extensionTool','total_boq_price'];
    const caseDetails = _.omit(caseDetailsData, excludeProps);

    caseDetails['networkcountry'] = caseDetails['country'];
    cartValues['contract_duration_months'] = (caseDetails['commercial_model'] === "opex" || caseDetails['commercial_model'] === "operating_lease") ? dynamicSelector.data.reference_solution.contract_duration : "";
    cartValues['contract_duration_years'] = caseDetails['commercial_model'] === "capex" ? dynamicSelector.data.reference_solution.contract_duration : "";
    delete caseDetails['contract_duration'];

    caseDetails['commercial_model'] = _.upperFirst(caseDetails['commercial_model']);
    const caseDataEle = {};
    caseDataEle[currstep] = {}
    // replace keynames of original casedetails with what is required to be sent to configurator
    _.map(caseDetails, (value, key) => {
      const lowercaseKey = _.toLower(key);
      if (_.includes(currstep_keys, lowercaseKey)) {
        // if the key is present in the caseDetailsAttributes then replace the key with lowercase key 
        cartValues[lowercaseKey] = value;
        if(addElementDispatch) {
          const elemValue  = {'key': value, 'value': value};
          caseDataEle[currstep][lowercaseKey] = elemValue;
          // dispatchToUpdateElementData(currstep, lowercaseKey,elemValue);
        }
      }
    });
    if(!_.isEmpty(caseDataEle)) {
      dispatchToUpdateElementData(caseDataEle);
    }
    const findcaseEle = {};
    const findcaseDetails = _.cloneDeep(findSelector.data);
    delete findcaseDetails['extOffer'];
    _.map(findcaseDetails, (value, key) => {
      // if the key is present in the caseDetailsAttributes then replace the key with lowercase key 
      const lowercaseKey = _.toLower(key);
      if (_.includes(currstep_keys, lowercaseKey)) {
        cartValues[lowercaseKey] = value;

        if(addElementDispatch) {
          const elemValue  = {'key': value, 'value': value};
          findcaseEle[currstep][lowercaseKey] = elemValue;
          
          // dispatchToUpdateElementData(currstep, lowercaseKey,elemValue);
        }
      }
    });

    if(!_.isEmpty(findcaseEle)) {
      dispatchToUpdateElementData(findcaseEle);
    }
    
    return cartValues;
  }

  const dispatchToUpdateElementData = (elemData) => { 
    dispatch(setDataSet(elemData))
  }

  const prepareEotParamsQueryString = (configstepperSelector, dynamicSelector, dispatch, solutionCategory, solutionName, undo, caseDetailsSelector, findCaseSelector) => {
    let function_operated = setFlagForEOTAPI(solutionName, solutionCategory, configstepperSelector, undo);

    // parameters that are required for eot waterfall api
    const currency = caseDetailsSelector.data.currency;
    const networkcountry = caseDetailsSelector.data.country; // network country
    const market = caseDetailsSelector.data.market;
    const scrmid = !sCRMRegex.test(findCaseSelector.data.sCRMID) ? "" : findCaseSelector.data.sCRMID;
    const threestar = caseDetailsSelector.data.three_star_number;
    const account_name = caseDetailsSelector.data.account_name;
    const account_id = caseDetailsSelector.data.account_id;
    const sales_channel = caseDetailsSelector.data.sales_channel;

    // parameters required by EOT api , send along with config api call
    let eotParams = "market=" + market + "&currency=" + currency + "&country_code=" + networkcountry + "&scrm_id=" + scrmid + "&threestar=" + threestar + "&account_name=" + account_name + "&operation=" + function_operated + "&account_id=" + account_id + "&sales_channel=" + sales_channel;

    if (_.includes(['NDAC'], solutionCategory) && (_.includes(['services', 'sla_warranty'], configstepperSelector.data.active_step) && solutionName !== 'cdm_ug_ndac')) {
      eotParams = eotParams + "&solutiontype=ndac_services";
    } else if (_.includes(['NDAC'], solutionCategory) && solutionName === 'cdm_ug_ndac') {
      eotParams = eotParams + "&solutiontype=ndac_services";
    }

    if (solutionCategory === 'MPW' && configstepperSelector.data.solution_selected !== 'cmu') {
      eotParams = eotParams + "&solutiontype=mpw";
    } else if (configstepperSelector.data.solution_selected === 'cmu') {
      eotParams = eotParams + "&solutiontype=cmu";
    }

    // if the step is connectivity and solution is MPW then every add to cart increment the package count and send to backend
    if (solutionCategory === 'MPW' && configstepperSelector.data.solution_selected !== 'cmu') {
      if (configstepperSelector.data.active_step === 'connectivity') {
        let packageCount = getPackageCount(configstepperSelector, dynamicSelector, dispatch, undo);
        eotParams = eotParams + "&package_count=" + packageCount;
      } else {
        eotParams = eotParams + "&package_count=" + dynamicSelector.data.package_options_count;
      }
    }

    return eotParams;
  }

  // decide if eot api has to be called or not
  const setFlagForEOTAPI = (solutionName, solutionCategory, configstepperSelector, undo) => {
    let function_operated = "add_to_cart";

    // we dont need eot api call for other add to cart
    if (_.includes(['NDAC'], solutionCategory) && (!_.includes(['services', 'sla_warranty'], configstepperSelector.data.active_step))) {
      function_operated = "";
    }

    // if(solutionName === 'mpw_reference_solution' && !_.includes(['connectivity','mxie','services_mpw','care_srs_sla_warranty'],configstepperSelector.data.active_step)) {
    //   function_operated = "";
    // }
    // when step is in services and we do undo no need to call EOT waterfall api
    if ((_.includes(['NDAC'], solutionCategory) && (configstepperSelector.data.active_step === 'services')) && undo) {
      function_operated = "";
    }

    if (solutionName === 'cdm_ug_ndac') {
      function_operated = "add_to_cart";
    }

    // when step is in connectivity and substep is cmu and we do undo no need to call EOT waterfall api
    if ((_.includes(['MPW'], solutionCategory) && _.includes(['connectivity'], configstepperSelector.data.active_step) && configstepperSelector.data.active_substep === 'cmu_connectivity') && undo) {
      function_operated = "";
    }
    return function_operated;
  }

  return {
    undoCallBackDefault,
    setNextStepsNull,
    appendRRHConfigMPW,
    appendRadioExcelData,
    get_ServiceDataGroup,
    addStepFormInputs,
    removeStepFormInputs,
    setCartValues,
    addHiddenFieldData,
    setCount_of_parameters,
    prepareCaseDetailsData,
    prepareEotParamsQueryString
  }
}

export default useCartUtility;