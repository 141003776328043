// import { AppToolbar, Breadcrumbs, IconButton, Snackbar } from "@nokia-csf-uxr/ccfk";
import { AppToolbar, Breadcrumbs } from "@nokia-csf-uxr/ccfk";
// import OpenInNewIcon from "@nokia-csf-uxr/ccfk-assets/legacy/OpenInNewIcon";
import {
  AppBody,
  AppContent,
  AppContentWrapper,
  AppHeader
} from "@nokia-csf-uxr/ccfk/App";
import {
  AppToolbarTitle,
  AppToolbarTitleContainer
} from "@nokia-csf-uxr/ccfk/AppToolbar";
import {
  BreadcrumbCompacts,
  BreadcrumbCompactsHeader,
  LinkSeparator
} from "@nokia-csf-uxr/ccfk/Breadcrumbs";
import { RootState } from "Store/mainStore";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useScrollTop } from "./Content/configurationArea/Configurator/hooks/useScrollTop";
import { NavLink } from "react-router-dom";
import AlertDialogModal from "App/AlertDialogModal";

export const  Body = (prop) => {
  const configuratorSelector = useSelector((state: RootState) => state.config_stepper);

  // Auto scroll to top on step change
  const element = document.getElementById('dot-app-content');  
  const {isScrolled} = useScrollTop(element);
  useEffect(() => {
    if(isScrolled && element){
      element.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [configuratorSelector.data.active_step,configuratorSelector.data.active_substep]);
  // ---- Need to move the scroll logic effect to hook
  // --- Will check and pick later
  // setScrollDependency([configuratorSelector.data.active_step,configuratorSelector.data.active_substep])
  
  
  return (
    <>
      <AppBody>
        <AppContentWrapper>
          {/* <div className="p"> */}
          {prop.crumbs && (
            <div style={{
              paddingLeft: '1rem',
              paddingRight: '1rem',
            }}>
              <AppHeader>
                <AppToolbar elevationProps={{ elevationIndex: 0, }}>
                  <AppToolbarTitleContainer style={{maxWidth:'100%',padding:'0',margin: '2px 0 0 2px'}}>
                    <AppToolbarTitle>
                      <Breadcrumbs className="dot__Breadcrumbs">
                        <BreadcrumbCompacts>
                          <BreadcrumbCompactsHeader>
                          <div className="dot-beadcrumb-navbar">  
                          {process.env.REACT_APP_OFFERDASHBOARD === 'true' && 
                          <><NavLink className="dot-beadcrumb-nav-item" to="/dashboard">Dashboard</NavLink> 
                           <LinkSeparator className="dot-beadcrumb-nav-item" key={`dashboaard`} /> </> }
                            {[
                              prop.crumbs.map((crum, index, array) => {
                                return (
                                  <div className="dot-beadcrumb-nav-item" key={index}>
                                    {crum}
                                    {index !== array.length - 1 && <LinkSeparator key={index} />}
                                  </div>
                                );
                              }),
                            ]}
                            </div>
                          </BreadcrumbCompactsHeader>
                        </BreadcrumbCompacts>
                      </Breadcrumbs>
                    </AppToolbarTitle>
                    {/* <AppToolbarSubtitle>{prop.subtitle}</AppToolbarSubtitle> */}
                  </AppToolbarTitleContainer>
                  {/* <AppToolbarButtonRow><Typography variant={'BODY'}>{prop.subtitle}</Typography></AppToolbarButtonRow> */}
                </AppToolbar>
              </AppHeader>
            </div>
          )}
          <AppContent
            id="dot-app-content"
            style={{
              paddingLeft: '1rem',
              paddingRight: '1rem',
              paddingTop: "1rem",
              width: "100%",
            }}
          >
            {prop.bodyContent}
            <AlertDialogModal />
          </AppContent>
          {/* </div> */}
        </AppContentWrapper>
      </AppBody>
    </>
  );
};
