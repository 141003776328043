//@ts-nochec
import React, { useEffect, useState, memo } from "react";
import { RootState, store } from "Store/mainStore";
import { useDispatch, useSelector } from "react-redux";
import { addTheme } from "Store/general/theme";
import { setProp } from "Store/Actions";
import { setElement } from "Store/contentState/configurationArea/dynamicSlice";
import _ from "lodash";
import ReviewIndex from "Components/Content/configurationArea/Review";
import { useConfigurator } from "./hooks/useConfigurator";
import PageInlineFeedback from "./Components/PageInlineFeedback";
import ActionButtons from "./Components/ActionButtons";
import { useStatesOfElement } from "./hooks/useStatesOfElement";
import { useAddElement } from "./hooks/useAddElements";
import MPW from "./mpw/MPW";
import { useRenderDynamicElements } from "./hooks/useRenderDynamicElements";
import { useDataCallBack } from "./hooks/useDataCallback";
import { useLocalStateManagement } from "./hooks/useLocalStateManagement";
import { useProductDialogModal } from "./hooks/useProductDialogModal";
import { getStateOfElement } from "Components/Logics/stateMachine/modules/CONFIG/configurator_statelogics";
import NDACPlanner from "./NDACRefereceSolution/NDACPlanner";
import { useActivePackages } from "./hooks/useActivePackages";
import { useShowValidateMessages } from "./hooks/useShowValidateMessages";
import GenericTabView from "./fieldsetView/GenericTabView";
import { mpwSolutionConfig } from "./mpw/Config";
import useAddToCart from "./hooks/useAddToCart";
import useCartUtility from "./hooks/useCartUtility";

const Options = () => {
  /** Selectors */
  const themeSelector = useSelector((state: RootState) => state.theme);
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const stateMachineSelector = useSelector(
    (state: RootState) => state.stateMachine
  );
  const caseDetailsSelector = useSelector(
    (state: RootState) => state.caseDetails
  );
  const findCaseSelector = useSelector((state: RootState) => state.findCase);
  /** Hooks */
  const dispatch = useDispatch();
  const {
    setPageFeedbacks,
    getHiddenFieldDataCallBack,
    getDataCallbackMMS,
    getPackageFieldList,
    setOptionsGlobalState,
    getListOfFields,
    getAllPackageFields,
    getAllFieldsInPackage,
    getTabularFieldList,
  } = useConfigurator();

  /** Component states */
  const allRefs = React.useRef([]);

  const { renderDynamicContent } = useRenderDynamicElements(allRefs);
  const { getElementsStates } = useStatesOfElement(allRefs);
  const { addElement } = useAddElement();
  const { addCallBack } = useDataCallBack(allRefs);

  const [applicationSelectedCount, setAppCount] = useState({
    key: null,
    value: null,
  });
  const [dummyAddCart, setDummyAddCart] = useState(null);

  const [cartButtons, setCartButtons] = useState([]);
  const [addCart, setAddCart] = useState(true);
  const { setState, getState } = useLocalStateManagement();

  /** Component variables */
  const api_data_call = {
    devices: "ndac_accessories",
    connectivity: "catalog_items",
    mxie: "mxie_server_consumption",
  };
  const packageData = getPackageFieldList(
    configstepperSelector.data.active_step
  );
  const { getDeviceBlockData, getActivePackagesData } = useActivePackages();

  const activePackages = getActivePackagesData();
  let deviceBlock = getDeviceBlockData();

  // Call the function to get the Tab view fieldslist from active packages
  const { isTabView, tabViewFieldList } = getTabularFieldList(activePackages);

  const steps = configstepperSelector.data.stepper_data;
  const getCurrentStep = steps.find((item) => item.status === "current");

  const solutionCategory = configstepperSelector.data.solutionCategory;
  const solutionType = configstepperSelector.data.solutionType;

  // Hooks for tiggering validation messages
  const { triggerValidationToast } = useShowValidateMessages();

  const { openProductDialogModal } = useProductDialogModal(
    activePackages,
    packageData
  );

  const { prepareCaseDetailsData } = useCartUtility();
  const { addToCartLogic } = useAddToCart();

  // Component Side effects
  useEffect(() => {
    __onComponentMount();
  }, []);

  // this useeffect will trigger when applicationcount changes/ dummycart flag value changes
  // dummyaddcart flag changes when add to cart button is clicked everytime.
  useEffect(() => {
    if (dummyAddCart !== null || applicationSelectedCount.key !== null) {
      // setisDisabled(addToCartLogic(activePackages, packageData, selectors));
      addToCartLogic(activePackages, packageData);
    }
  }, [applicationSelectedCount, dummyAddCart]);

  useEffect(() => {
    __onDynamicElementStoreChange();
    updateSearchBlock();
  }, [dynamicSelector.data.element[configstepperSelector.data.active_step]]);

  // Reset catalog apiload indicator to false
  useEffect(() => {
    setHiddenCaseDetails();
    setState("setProdCatalogApiLoad", false);
    addCallBack(true, "", "", deviceBlock);
  }, [configstepperSelector.data.active_step]);

  useEffect(() => {
    setOptionsGlobalState();
    // addCallBack(true, '', '', deviceBlock);
    if (
      configstepperSelector.data.active_substep !==
      "radio_solution_connectivity"
    ) {
      setPageFeedbacks("errorStatus_radio_totalSites", false);
    }
  }, [configstepperSelector.data.active_substep]);

  // Validation notification for the active step
  // Also on the change of notification_messages
  useEffect(() => {
    triggerValidationToast();
  }, [configstepperSelector.data.active_step,configstepperSelector.data.active_substep,dynamicSelector.data.notification_messages[configstepperSelector.data.active_step]]);
  
  
  const updateSearchBlock = () => {
    // -----store the packagedata into store to use globally-----//
    if (deviceBlock !== dynamicSelector.data.dataFromPackage["deviceBlock"]) {
      dispatch(
        setProp({
          slice: "dynamic",
          key: "dataFromPackage",
          value: { packageData: packageData, deviceBlock: deviceBlock },
        })
      );
    }
  };

  /** onComponentMount */
  const __onComponentMount = () => {
    getDataTest();
    getFieldTypeSelect();
    const elementData = addElement(allRefs);
    setState("setOptionsData", elementData.storeElement);
    dispatch(setElement(elementData.element));
    dispatch(setProp({ slice: "dynamic", key: "actionNext", value: true }));
  };

  /** onDynamicElementStoreChange */
  const __onDynamicElementStoreChange = () => {
    addCartButtonStatus();
    setHiddenField();
    callDataCallHiddenField();
    const consumptionValue =
      configstepperSelector.data.active_step === "mxie" ? true : false;
    setState("setConsumptionGraph", consumptionValue);
    setOptionsGlobalState();
    setOptionData();
    setState("setOptionsData", getState("optionsData"));
  };

  /** setOptionData */
  const setOptionData = () => {
    const dataInCurrentStep =
      dynamicSelector.data.element[configstepperSelector.data.active_step];
    const optionsData = getState("optionsData");
    if (dataInCurrentStep) {
      let validatedCheck = [];
      Object.entries(activePackages).map((data, _index) => {
        const optionsDataList = getState("optionsDataList", data[0]);
        const allStatesForElement = getElementsStates(activePackages[data[0]]);
        if (
          allStatesForElement.visibleValue !== "none" &&
          data[1]["required"] &&
          dataInCurrentStep[data[0]] === ""
        ) {
          validatedCheck.push(data[0]);
        }
        if (_.includes(["NDAC", "MPW"], solutionCategory)) {
          if (
            (configstepperSelector.data.active_step === "devices" &&
              data[0] == "ndac_device_items") ||
            (configstepperSelector.data.active_step === "connectivity" &&
              data[0] == "connectivity_ndac_items") ||
            (configstepperSelector.data.active_step === "connectivity" &&
              data[0] == "das_items")
          ) {
            if (optionsDataList) {
              let optionsDataListData = optionsDataList;
              optionsDataListData = {
                id: data[0],
                data:
                  dynamicSelector.data.element[
                    configstepperSelector.data.active_step
                  ][data[0]] != ""
                    ? dynamicSelector.data.element[
                    configstepperSelector.data.active_step
                    ][data[0]]
                    : [],
              };
              setState("setOptionsDataList", optionsDataListData, data[0]);
            }
          }
          if (
            configstepperSelector.data.active_step == "mxie" &&
            data[0] == "mxie_server"
          ) {
            if (
              optionsData[configstepperSelector.data.active_step] &&
              optionsData[configstepperSelector.data.active_step][data[0]]
            ) {
              let optionDatalist = JSON.parse(JSON.stringify(optionsData)); //{...optionsData};
              optionDatalist[configstepperSelector.data.active_step][data[0]] =
                dynamicSelector.data.element[
                  configstepperSelector.data.active_step
                ][data[0]] != ""
                  ? dynamicSelector.data.element[
                  configstepperSelector.data.active_step
                  ][data[0]]
                  : [];
              setState("setOptionsData", optionDatalist);
              setState("setConsumptionGraph", true);
            }
          }
        }
      });
      if (validatedCheck.length === 0) {
        setPageFeedbacks("errorStatus", false);
      } else {
        setPageFeedbacks(
          "errorStatus",
          true,
          `Please add valid values for all the highlighted fields in ${getCurrentStep.name} section`
        );
      }
    }
  };

  /**  setApplicationCount */
  const setApplicationCount = () => {
    const currentStep = configstepperSelector.data.active_step;
    if (_.includes(["applications", "use_cases_apps"], currentStep)) {
      const countappVariable = "count_of_applications_selected";
      const elem = `element.${currentStep}.` + countappVariable;
      const packageData =
        configstepperSelector.data.solution_form_data["packages"][currentStep];
      let formData = getPackageFieldList(
        configstepperSelector.data.active_step
      );

      if (packageData["field_details"]["applications"]) {
        const packagedata =
          packageData["field_details"]["applications"]["field_details"];
        const packageFields = _.keys(packagedata);
        formData = _.filter(formData, (obj) =>
          _.includes(packageFields, obj.id)
        );
      }
      const count_apps = getSelectedApplicationCount(formData);
      const appcount = { key: count_apps, value: count_apps };
      setAppCount(appcount);
      dispatch(setProp({ slice: "dynamic", key: elem, value: appcount }));
    } else {
      setDummyAddCart(!dummyAddCart);
    }
  };

  /** setHiddenCaseDetails */
  const setHiddenCaseDetails = () => {
    const currstep = configstepperSelector.data.active_step;
    const currstep_keys = _.keys(store.getState().dynamic.data.element[currstep]);
    if (
      _.includes(currstep_keys, "case_type") &&
      _.includes(currstep_keys, "case_sub_type")
    ) {
      const casetype = {key : findCaseSelector.data.caseType, value : findCaseSelector.data.caseType};
      dispatch(
        setProp({
          slice: "dynamic",
          key: `element.${currstep}.case_type`,
          value: casetype,
        })
      );
      const case_subtype = {key : findCaseSelector.data.subType, value: findCaseSelector.data.subType};
      dispatch(
        setProp({
          slice: "dynamic",
          key: `element.${currstep}.case_sub_type`,
          value: case_subtype,
        })
      );
    }
    // check if the current step has the field from caseDetails then set the value
    prepareCaseDetailsData(true);
  };

  /** setHiddenField */
  const setHiddenField = () => {
    if (_.includes(["NDAC", "MPW"], solutionCategory)) {
      if (
        dynamicSelector.data.element[configstepperSelector.data.active_step] &&
        !dynamicSelector.data.element[configstepperSelector.data.active_step][
        "country"
        ]
      ) {
        const country = {
          key: caseDetailsSelector.data.country,
          value: caseDetailsSelector.data.country,
        };
        dispatch(
          setProp({
            slice: "dynamic",
            key: `element.${configstepperSelector.data.active_step}.country`,
            value: country,
          })
        );
      }
    }
    // Set step state hidden field segment
    if (
      configstepperSelector.data.active_step &&
      dynamicSelector.data.element[configstepperSelector.data.active_step]
    ) {
      // Check active_step === first dynamic step (exclude business step)
      // if (configstepperSelector.data.active_step === steps[1]?.stepname) {
      // Check dynamicSelector > element > <active_step> has a field segement and it has not a valid value set
      if (configstepperSelector.data.active_step !== steps[0]?.stepname) {
        if (
          !dynamicSelector.data.element[configstepperSelector.data.active_step][
          "segment"
          ]
        ) {
          const segment = {
            key: configstepperSelector.data.solution_selected,
            value: configstepperSelector.data.solution_selected,
          };
          dispatch(
            setProp({
              slice: "dynamic",
              key: `element.${configstepperSelector.data.active_step}.segment`,
              value: segment,
            })
          );
        }
      }
    }
  };

  /** callDataCallHiddenField */
  const callDataCallHiddenField = () => {
    let packagesAllList =
      configstepperSelector.data.solution_form_data["packages"];
    Object.keys(packagesAllList).forEach(function (key) {
      const packageFieldDetails = getPackageFieldList(key);
      if (packageFieldDetails) {
        Object.entries(packageFieldDetails).map((data, _index) => {
          if (data[1]["type"] === "hidden" && data[1]["data_callback"]) {
            if (
              dynamicSelector.data.element[
              configstepperSelector.data.active_step
              ] &&
              dynamicSelector.data.element[
              configstepperSelector.data.active_step
              ][data[0]]
            ) {
              if (
                dynamicSelector.data.element[
                  configstepperSelector.data.active_step
                ][data[0]].key === 0
              ) {
                getHiddenFieldDataCallBack(data);
              }
            }
          }
        });
      }
    });
  };

  /** getDataTest */
  const getDataTest = () => {
    Object.entries(dynamicSelector.data.elementMMS).forEach(function (key) {
      const field_details = getPackageFieldList(key[0]);
      if (field_details) {
        Object.entries(field_details).map((data, _index) => {
          if (data[1]["type"] === "range" || data[1]["type"] === "number") {
            let dataMinMaxStep = ["min", "max", "steps"];
            dataMinMaxStep.forEach((mms) => {
              if (data[1][mms]["data_callback"]) {
                if (
                  dynamicSelector.data.elementMMS[key[0]] &&
                  dynamicSelector.data.elementMMS[key[0]][data[0]]
                ) {
                  if (
                    dynamicSelector.data.elementMMS[key[0]][data[0]][mms] === ""
                  ) {
                    getDataCallbackMMS(key[0], data, mms);
                  }
                }
              }
            });
          }
        });
      }
    });
  };

  const getFieldTypeSelect = () => {
    let test = {};
    Object.keys(activePackages).forEach(function (item) {
      let filedType = activePackages[item];
      if (filedType.type === "select") {
        test[item] = false;
      }
    });
    let newdata_list = { ...themeSelector.data.select.isOpen, ...test };
    dispatch(addTheme({ newdata_list }));
  };

  /** addCartButtonStatus */
  const addCartButtonStatus = () => {
    let cartButtons = [];
    //TODO: check this code - may be this code can be changed instead of looping
    Object.keys(activePackages).forEach(function (item) {
      if (activePackages[item]) {
        const allStatesForElement = getElementsStates(activePackages[item]);
        if (
          allStatesForElement.visibleValue !== "none" &&
          activePackages[item].required
        ) {
          cartButtons.push(item);
        }
      }
    });
    /** setCartButtons */
    setCartButtons(cartButtons);
    let testdata = [];
    if (dynamicSelector.data.element[configstepperSelector.data.active_step]) {
      if (cartButtons.length !== 0) {
        cartButtons.forEach(function (data) {
          if (
            dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ][data] === ""
          ) {
            testdata.push(data);
          }
        });
      } else {
        Object.keys(activePackages).forEach(function (item) {
          if (
            dynamicSelector.data.element[configstepperSelector.data.active_step]
          ) {
            if (
              dynamicSelector.data.element[
              configstepperSelector.data.active_step
              ][item] !== ""
            ) {
              testdata.push(item);
            }
          }
        });
      }
    }
    if (cartButtons.length === 0) {
      if (cartButtons.length === 0 && testdata.length !== 0) {
        setAddCart(false);
      } else {
        setAddCart(true);
        dispatch(
          setProp({ slice: "dynamic", key: "actionNext", value: false })
        );
      }
    } else {
      if (cartButtons.length !== 0 && testdata.length === 0) {
        setAddCart(false);
      } else {
        setAddCart(true);
        dispatch(setProp({ slice: "dynamic", key: "actionNext", value: true }));
      }
    }
  };

  /**  getAddCartButtonStatus */
  const getAddCartButtonStatus = () => {
    if (
      _.includes(["NDAC"], solutionCategory) &&
      (configstepperSelector.data.active_step === "devices" ||
        configstepperSelector.data.active_step === "connectivity" ||
        configstepperSelector.data.active_step === "mxie") &&
      solutionType !== "Standalone"
    ) {
      let isEnabled = false;
      let isEnabledSim;
      if (
        configstepperSelector.data.active_step === "devices" &&
        dynamicSelector.data.element[configstepperSelector.data.active_step]
      ) {
        // Check sim_cards are set and not zero
        const sim_cards =
          dynamicSelector.data.element &&
            dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ] &&
            dynamicSelector.data.element[configstepperSelector.data.active_step][
            "sim_cards"
            ]
            ? dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ]["sim_cards"]
            : {};

        isEnabledSim = sim_cards.value && sim_cards.value > 0;
      }

      let isEnabledTargets = getEnabledTargets();

      if (configstepperSelector.data.active_substep) {
        isEnabled = true;
      }
      const activeStep = configstepperSelector.data.active_step;
      const __emptyFields = checkRequiredFields(activeStep);
      // if all target is a required field and is set to true- has items checked
      let anyOneTargetsChecked = checkIfRequiredTargetsAreChecked(__emptyFields,isEnabledTargets);
      
      let isDisabled = __emptyFields.emptyFields.length > 0;
      isEnabled = !isDisabled && anyOneTargetsChecked;
      if ((isEnabledSim !== undefined && isEnabledSim)) {
        return false;
      }
      return !isEnabled;
    }
    if (
      _.includes(["NDAC", "MPW"], solutionCategory) &&
      _.includes(
        ["applications", "use_cases_apps"],
        configstepperSelector.data.active_step
      )
    ) {
      const __emptyFields = checkRequiredFields(configstepperSelector.data.active_step);
      if(__emptyFields?.emptyFields.length > 0){
        // If empty fields length is > 0 ; Means required fields are not filled
        return true;
      }
      //@ts-ignore
      const formData = getPackageFieldList(
        configstepperSelector.data.active_step
      );
      const count_apps = getSelectedApplicationCount(formData);
      return !(count_apps > 0);
    } else if (
      _.includes(["MPW"], solutionCategory) &&
      solutionType !== "Standalone"
    ) {
      let isEnabled = false;
      const targetFields = dynamicSelector.data.setTargetFields; //getState('targetFields');
      // For Device step ---- This section should generic for all the solution
      // Need to refactor and seperate the logic
      if (
        configstepperSelector.data.active_step === "devices" &&
        dynamicSelector.data.element[configstepperSelector.data.active_step]
      ) {
        // Check sim_cards are set and not zero
        const sim_cards =
          dynamicSelector.data.element &&
            dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ] &&
            dynamicSelector.data.element[configstepperSelector.data.active_step][
            "sim_cards"
            ]
            ? dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ]["sim_cards"]
            : {};

        isEnabled = sim_cards.value && sim_cards.value > 0;

        const currentTabTargetFields = targetFields[
          configstepperSelector.data.active_step
        ]
          ? targetFields[configstepperSelector.data.active_step]
          : [];
        currentTabTargetFields.forEach((target) => {
          const subItems = dynamicSelector.data.element[
            configstepperSelector.data.active_step
          ][target]
            ? Object.keys(
              dynamicSelector.data.element[
              configstepperSelector.data.active_step
              ][target]
            )
            : [];
          subItems.forEach((item) => {
            isEnabled =
              isEnabled ||
              (dynamicSelector.data.element[
                configstepperSelector.data.active_step
              ][target][item] &&
                dynamicSelector.data.element[
                  configstepperSelector.data.active_step
                ][target][item].length > 0 &&
                dynamicSelector.data.element[
                  configstepperSelector.data.active_step
                ][target][item].some((row) => row.checked));
          });
        });

        return !isEnabled;
      }

      const activeStep = configstepperSelector.data.active_step;
      const activeSubStep = configstepperSelector.data?.active_substep;
      const __emptyFields = checkRequiredFields(activeStep);

      let isDisabled = __emptyFields.emptyFields.length > 0;

      // Handle connectivity > radio_solution_connectivity && radio_solution_type == "Radio Blueprint"
      if (!isDisabled &&
        activeStep === "connectivity" &&
        mpwSolutionConfig.mpwSolutionSteps.includes(activeSubStep)
      ) {
        const mpwConnectivitySolutionType =
          mpwSolutionConfig.config[activeSubStep].solutionType;
        if (
          dynamicSelector.data.element[activeStep]?.[
          mpwConnectivitySolutionType
          ] &&
          mpwSolutionConfig.config.common.userConfig.includes(
            dynamicSelector.data.element[activeStep]?.[
              mpwConnectivitySolutionType
            ]?.key
          )
        ) {
          // Check whether configCountField from mpwSolutionConfig is set and not 0
          const configCountField =
            mpwSolutionConfig.config?.[activeSubStep].configCountField;
          const configCount =
            dynamicSelector.data.element[activeStep]?.[configCountField];
          if (typeof configCount !== "object") {
            isDisabled = true;
          } else {
            isDisabled =
              "key" in
                dynamicSelector.data.element[activeStep]?.[configCountField] &&
                dynamicSelector.data.element[activeStep]?.[configCountField]
                  .key === 0
                ? true
                : isDisabled;
          }
          // Check whether configuration completed or not
          if (
            !isDisabled &&
            dynamicSelector.data.element[activeStep]?.[configCountField].key > 0
          ) {
            isDisabled = true;
            const configCountStateField =
              mpwSolutionConfig.config?.[activeSubStep].configCountStateField;
            if (
              dynamicSelector.data.modal[activeStep] &&
              dynamicSelector.data.modal[activeStep]?.[configCountStateField] &&
              dynamicSelector.data.modal[activeStep]?.[
              configCountStateField
              ] === true
            ) {
              isDisabled = false;
            }
          }
        } else if (
          dynamicSelector.data.element[activeStep]?.[
          mpwConnectivitySolutionType
          ] &&
          mpwSolutionConfig.config.common.userExcelUpload.includes(
            dynamicSelector.data.element[activeStep]?.[
              mpwConnectivitySolutionType
            ]?.key
          )
        ) {
          isDisabled = true;
          // In case radio_solution_type === "Radio Excel Upload"
          // Check radioExcelJson is set and not empty
          const excelJsonObject = mpwSolutionConfig.config?.[activeSubStep].excelJsonObject;
          if (
            themeSelector.data.button.isToggle[
            `${stateMachineSelector.PTDAC.activeTab}_undo`
            ] ||
            (dynamicSelector.data.modal[activeStep] &&
              dynamicSelector.data.modal[activeStep][
              configstepperSelector.data.active_substep
              ] &&
              dynamicSelector.data.modal[activeStep][
              configstepperSelector.data.active_substep
              ][excelJsonObject] &&
              dynamicSelector.data.modal[activeStep][
                configstepperSelector.data.active_substep
              ][excelJsonObject].length > 0)
          ) {
            isDisabled = false;
          }
        }
      }

      // Need to remove below code after refactoring

      // if (
      //   !isDisabled &&
      //   activeStep === "connectivity" &&
      //   mpwSolutionConfig.mpwSolutionSteps.includes(activeSubStep) &&
      //   //configstepperSelector.data.active_substep === 'radio_solution_connectivity' &&
      //   dynamicSelector.data.element[activeStep].radio_solution_type &&
      //   dynamicSelector.data.element[activeStep].radio_solution_type.key ===
      //     "Radio Blueprint"
      // ) {
      //   // Check whether count_of_rrh_types is set and not 0
      //   const count_of_rrh_types =
      //     dynamicSelector.data.element[activeStep]?.count_of_rrh_types;
      //   if (typeof count_of_rrh_types !== "object") {
      //     isDisabled = true;
      //   } else {
      //     // isDisabled = (dynamicSelector.data.element[activeStep].count_of_rrh_types.key && dynamicSelector.data.element[activeStep].count_of_rrh_types.key === 0) ? true : isDisabled;
      //     isDisabled =
      //       "key" in
      //         dynamicSelector.data.element[activeStep].count_of_rrh_types &&
      //       dynamicSelector.data.element[activeStep].count_of_rrh_types.key ===
      //         0
      //         ? true
      //         : isDisabled;
      //   }
      //   // Check whether configuration completed or not
      //   if (
      //     !isDisabled &&
      //     dynamicSelector.data.element[activeStep].count_of_rrh_types.key > 0
      //   ) {
      //     isDisabled = true;
      //     if (
      //       dynamicSelector.data.modal[activeStep] &&
      //       dynamicSelector.data.modal[activeStep].isRrhTypesDisabled &&
      //       dynamicSelector.data.modal[activeStep].isRrhTypesDisabled === true
      //     ) {
      //       isDisabled = false;
      //     }
      //   }
      // } else if (
      //   !isDisabled &&
      //   activeStep === "connectivity" &&
      //   mpwSolutionConfig.mpwSolutionSteps.includes(activeSubStep) &&
      //   // configstepperSelector.data.active_substep === 'radio_solution_connectivity' &&
      //   dynamicSelector.data.element[activeStep].radio_solution_type &&
      //   dynamicSelector.data.element[activeStep].radio_solution_type.key ===
      //     "Radio Excel Upload"
      // ) {
      //   isDisabled = true;
      //   // In case radio_solution_type === "Radio Excel Upload"
      //   // Check radioExcelJson is set and not empty
      //   if (
      //     themeSelector.data.button.isToggle[
      //       `${stateMachineSelector.PTDAC.activeTab}_undo`
      //     ] ||
      //     (dynamicSelector.data.modal[activeStep] &&
      //       dynamicSelector.data.modal[activeStep][
      //         configstepperSelector.data.active_substep
      //       ] &&
      //       dynamicSelector.data.modal[activeStep][
      //         configstepperSelector.data.active_substep
      //       ]["radioExcelJson"] &&
      //       dynamicSelector.data.modal[activeStep][
      //         configstepperSelector.data.active_substep
      //       ]["radioExcelJson"].length > 0)
      //   ) {
      //     isDisabled = false;
      //   }
      // }
      return isDisabled;
    } else {
      return addCart;
    }
  };

  // common method : To get all the checked items in the target fields ( list items)
  const getEnabledTargets = () => {
    const targetFields = dynamicSelector.data.setTargetFields; //getState('targetFields');
    const currentTabTargetFields = targetFields[
      configstepperSelector.data.active_step
    ]
      ? targetFields[configstepperSelector.data.active_step]
      : [];
    let isEnabledTargets = [];
    currentTabTargetFields.forEach((target) => {
      const subItems = dynamicSelector.data.element[
        configstepperSelector.data.active_step
      ][target]
        ? Object.keys(
          dynamicSelector.data.element[
          configstepperSelector.data.active_step
          ][target]
        )
        : [];
      isEnabledTargets[target] = false;
      subItems.forEach((item) => {
        isEnabledTargets[target] =
          isEnabledTargets[target] ||
          (dynamicSelector.data.element[
            configstepperSelector.data.active_step
          ][target][item] &&
            dynamicSelector.data.element[
              configstepperSelector.data.active_step
            ][target][item].length > 0 &&
            dynamicSelector.data.element[
              configstepperSelector.data.active_step
            ][target][item].some((row) => row.checked));
      });
    });
    return isEnabledTargets;
  };

  // common method : To check if any one of the required fields are checked
  const checkIfRequiredTargetsAreChecked = (__emptyFields,isEnabledTargets) => {
    let anyOneTargetsChecked = false;
    if ((__emptyFields.requiredFields).length > 0) {
      anyOneTargetsChecked = (_.values(isEnabledTargets).length > 0) && _.some(_.map(Object.keys(isEnabledTargets), val => {
        if (_.includes(__emptyFields.requiredFields, val)) {
          return isEnabledTargets[val];
        }
        return false;
      }));
    } else {
      anyOneTargetsChecked = (_.values(isEnabledTargets).length > 0) && _.some(Object.keys(isEnabledTargets), val => isEnabledTargets[val]);
    }
    return anyOneTargetsChecked;
  }

  const checkRequiredFields = (activeStep) => {
    const allfieldData = getListOfFields(dynamicSelector.data.element);
    const __requiredFieldList = [];
    const activePackages = getPackageFieldList(configstepperSelector.data.active_step);
    Object.keys(activePackages).forEach((key) => {
      // Check whether current field is required based on condtion - State
      if (
        activePackages[key] &&
        activePackages[key]["states"] &&
        _.size(activePackages[key]["states"]) > 0 &&
        activePackages[key]["states"]["required"]
      ) {
        // Require field based on condition
        const allStates = getStateOfElement(activePackages[key], allfieldData);
        if (allStates.required) {
          __requiredFieldList.push(key);
        }
      } else {
        // Check field is required by default
        const allStatesForElement = getElementsStates(activePackages[key]);
        if (
          allStatesForElement.visibleValue !== "none" &&
          activePackages[key].required
        ) {
          __requiredFieldList.push(key);
        }
      }
    });
    const __emptyFields = [];
    __requiredFieldList.forEach((rfk) => {
      if (
        dynamicSelector.data.element[activeStep] &&
        dynamicSelector.data.element[activeStep][rfk]
      ) {
        if (
          dynamicSelector.data.element[activeStep][rfk].hasOwnProperty("value")
        ) {
          if (
            dynamicSelector.data.element[activeStep][rfk]["value"] === "" ||
            dynamicSelector.data.element[activeStep][rfk]["value"] === null
          ) {
            __emptyFields.push(rfk);
          } else if (
            activePackages[rfk] &&
            activePackages[rfk]["subtype"] === "toggle" &&
            dynamicSelector.data.element[activeStep][rfk]["value"] === 0
          ) {
            __emptyFields.push(rfk);
          }
        } else if (dynamicSelector.data.element[activeStep][rfk].length === 0) {
          __emptyFields.push(rfk);
        }
      } else {
        __emptyFields.push(rfk);
      }
    });
    return { 'emptyFields': __emptyFields, 'requiredFields': __requiredFieldList };
  }

  /** getSelectedApplicationCount */
  const getSelectedApplicationCount = (formData) => {
    const applicationFieldDetails = formData ?? {};
    const selectedApplicationFields = [];
    Object.keys(applicationFieldDetails).forEach((key) => {
      if (
        applicationFieldDetails[key].type !== "hidden" &&
        applicationFieldDetails[key].subtype === "toggle"
      ) {
        // check dynamic.data.element.application has selection for applicationFieldDetails[key].id
        if (
          dynamicSelector.data.element[
          configstepperSelector.data.active_step
          ] &&
          dynamicSelector.data.element[configstepperSelector.data.active_step][
          applicationFieldDetails[key].id
          ] &&
          dynamicSelector.data.element[configstepperSelector.data.active_step][
            applicationFieldDetails[key].id
          ].key
        ) {
          selectedApplicationFields.push(applicationFieldDetails[key].id);
        }
      }
    });
    // set the selected application count to dynamic element
    const count_apps = selectedApplicationFields.length;
    return count_apps;
  };

  /** getAddAccessoriesButtonStatus */
  const getAddAccessoriesButtonStatus = () => {
    let isDisabled = true;
    // Common condition for devices and connectivity
    if (
      _.includes(["NDAC", "MPW"], solutionCategory) &&
      (configstepperSelector.data.active_step === "devices" ||
        configstepperSelector.data.active_step === "connectivity") &&
      solutionType !== "Standalone"
    ) {
      // Check Connectivity,Bands and Duplex Mode are selected
      // And Search button has clicked

      isDisabled = !(
        dynamicSelector.data.setAccessoriesButton === false &&
        getState("searchButtonStatus") === false
      );
      // Check atleast one item has selected from the add to catalog popup and added in BoQ
      let catalogItems = {};
      if (
        dynamicSelector.data.element &&
        dynamicSelector.data.element[configstepperSelector.data.active_step]
      ) {
        if (
          configstepperSelector.data.active_step === "devices" &&
          dynamicSelector.data.element[configstepperSelector.data.active_step][
          "ndac_accessories"
          ]
        ) {
          catalogItems =
            dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ]["ndac_accessories"];
        }
        if (
          configstepperSelector.data.active_step === "connectivity" &&
          dynamicSelector.data.element[configstepperSelector.data.active_step][
          "catalog_items"
          ]
        ) {
          catalogItems =
            dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ]["catalog_items"];
        }
      }
      const BoQ =
        dynamicSelector.data.boq && dynamicSelector.data.boq.length > 0
          ? dynamicSelector.data.boq
          : [];
      const productIds = BoQ.map((row) => row.product_id);

      const catalogKeys = Object.keys(catalogItems);
      let isItemAdded = false;
      catalogKeys.forEach((key) => {
        isItemAdded =
          isItemAdded ||
          catalogItems[key].some((item) =>
            productIds.includes(item.product_code)
          );
      });

      const __emptyFields = checkRequiredFields(configstepperSelector.data.active_step);
      // check if all required fields have data in dynamic data element
      let isEnabledTargets = getEnabledTargets();
      let allTargetsChecked = checkIfRequiredTargetsAreChecked(__emptyFields,isEnabledTargets);
      // if all allTargetsChecked is true and isDisabled is false
      // Then check if any one of the required fields are not checked Or isItemAdded is true
      if(allTargetsChecked && !isDisabled){
        isDisabled = isItemAdded || __emptyFields.emptyFields.length > 0;
      } else {
        isDisabled = true;
      }
    }
    return isDisabled;
  };

  /** render action button */
  const renderActionButton = () => {
    return (
      <ActionButtons
        addAccessoriesButtonStatus={getAddAccessoriesButtonStatus()}
        prodCatalogApiLoad={getState("prodCatalogApiLoad")}
        addCartButtonStatus={getAddCartButtonStatus()}
        activePackages={activePackages}
        setApplicationCount={setApplicationCount}
        allRefs={allRefs}
      />
    );
  };

  if (configstepperSelector.data.active_step === "review") {
    return <ReviewIndex />;
  }

  if (getState("isLoading")) {
    return <p>Loading...</p>;
  }

  if (
    solutionCategory === "MPW" &&
    ["mpw_cdm_op", "mpw_reference_solution","pwml_mpw"].includes(
      configstepperSelector.data.solution_selected
    )
  ) {
    return (
      <MPW
        renderActionButton={renderActionButton}
        allRefs={allRefs}
        api_data_call={api_data_call}
      />
    );
  }

  if (isTabView) {
    return (
      <>
        {openProductDialogModal(api_data_call)}
        <GenericTabView
          fieldset={tabViewFieldList}
          renderDynamicContent={renderDynamicContent}
          renderActionButton={renderActionButton}
        />
      </>
    );
  }

  if (
    solutionCategory === "NDAC" &&
    configstepperSelector.data.active_step === "connectivity" &&
    solutionType !== "Standalone"
  ) {
    return (
      <>
        {openProductDialogModal(api_data_call)}
        <NDACPlanner
          renderDynamicContent={renderDynamicContent}
          activePackages={activePackages}
          renderActionButton={renderActionButton}
        />
      </>
    );
  }

  return (
    <>
      <PageInlineFeedback />
      {/** ProductCatalogDialog*/}
      {openProductDialogModal(api_data_call)}
      {/** renderDynamicContent */}
      {renderDynamicContent(activePackages)}
      {/** renderDynamicContent */}
      {/** Action button row */}
      {renderActionButton()}
      {/** Action button row */}
    </>
  );
};

export default memo(Options);
