import { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "Store/mainStore";
import { setLive } from "Store/general/theme";
import { loginRequest } from "Auth/authConfig";
import { stateMachine } from "Components/Misc/CaseDetails_System";
import CONFIG_API from "Constants/services/configurator";
import DATAMART_API from "Constants/services/datamart";
import OOT from "Constants/services/oot";
import { callMsGraph } from "Auth/graph";
import { changeAppState, setDatamartUserRole, setError, setUser, showLoader, showAuthLoader, setUserAuthorization } from "Store/general/auth";

const CryptoJS = require("crypto-js");

export const setDataByKey = (state: { data: {} }, action: {
  payload: { key: string, value: string | number };
  type: string;
}) => {
  state.data = {
    ...state.data,
    [action.payload.key]: action.payload.value,
  };
}

export function isValidPositiveNumber(input) {
  // Use regex to validate positive number format
  return /^\d+(\.\d+)?$/.test(input);
}
export const haltEvents = (event) => {
  event.stopPropagation();
  event.preventDefault();
};

export const moveElement = (arr, fromIndex, toIndex) => {
  const element = arr.splice(fromIndex, 1)[0];


  arr.splice(toIndex, 0, element);

  return arr;
};

export function encodeBase64(myString) {
  // INIT
  //const myString = '75322541'; // Utf8-encoded string

  // PROCESS
  const encodedWord = CryptoJS.enc.Utf8.parse(myString); // encodedWord Array object
  const encoded = CryptoJS.enc.Base64.stringify(encodedWord); // string: 'NzUzMjI1NDE='
  return encoded;
}

export function decodeBase64(encoded) {
  // INIT
  //const encoded = 'NzUzMjI1NDE='; // Base64 encoded string

  // PROCESS
  const encodedWord = CryptoJS.enc.Base64.parse(encoded); // encodedWord via Base64.parse()
  const decoded = CryptoJS.enc.Utf8.stringify(encodedWord); // decode encodedWord via Utf8.stringify() '75322541'
  return decoded;
}
export const useHandleLogout = (logoutType) => {
  const { instance } = useMsal();
  if (logoutType === "popup") {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  } else if (logoutType === "redirect") {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }
};
export const useRenderState = (renderType, field) => {
  const findCaseSelector = useSelector((state: RootState) => state.findCase);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const caseType = findCaseSelector.data.caseType;
  const subType = findCaseSelector.data.subType
    ? findCaseSelector.data.subType
    : "none";
  const dispatch = useDispatch();
  var found = stateMachine.find(
    (dna) => dna.caseType === caseType && dna.subType === subType
  );

  let display = false;
  if (found) {
    if (authSelector?.system.currentState?.caseType !== found.caseType) { //tofix -avoid userRenderstate-multiple-rerender
      dispatch(changeAppState(found));
    }
    switch (renderType) {
      case "form":
        if (found.allowedComp.form.includes(field)) {
          display = true; // this is not required at the mome
        }

        return display;

      case "slice":
        if (found.allowedComp.slice.includes(field)) {
          display = true;
        }
        return display;

      default:
        return display;
    }

    //caseDetails
  }
  return display;
};

export const useAuthState = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const authSelector = useSelector((state: RootState) => state.authSlice);

  useEffect(() => {
    // Show loader for user authentication
    dispatch(showAuthLoader(true));
    if (isAuthenticated) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          const adToken = response;
          const accessToken = response.accessToken;
          return callMsGraph(response.accessToken)
            .then((response) => {
              response["accessToken"] = accessToken;
              response["adToken"] = JSON.stringify(adToken);
              dispatch(setUser(response));
              OOT.init({
                accessToken: accessToken,
                email: response.mail,
                idtoken: response.adToken.idToken,
                redX: {
                  dispatch: dispatch,
                }
              });

              // init config
              CONFIG_API.init({
                accessToken: accessToken,
                email: response.mail,
                idtoken: response.adToken.idToken,
                redX: {
                  dispatch: dispatch,
                }
              });

              // Initialize datamart config
              DATAMART_API.init({
                accessToken: accessToken,
                email: response.mail,
                idtoken: response.adToken.idToken,
                redX: {
                  dispatch: dispatch,
                },
                userId: response.id
              });

              // Get user access role from datamart
              if ((response?.mail && response?.mail !== "") && (response?.id && response?.id !== "")) {
                // Call datamart api for user role
                getUserAccessRole(dispatch);
              }
            })
            .catch((error) => { });
        })
        .catch((e) => {
          dispatch(
            setError({
              key: "error",
              value: {
                errorCode: null,
                errorMsg: "Session Expired, re-Login to continue",
              },
            })
          );
          dispatch(setLive(false));
          setTimeout((e) => {
            instance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
          }, 3000);
        });
    } else {
      //alert("Not logged in");
    }
    return () => {
    };

  }, []);
};

export const useValidate = () => {
  const findCaseSelector = useSelector((state: RootState) => state.findCase);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const caseType = findCaseSelector.data.caseType;
  const subType = findCaseSelector.data.subType;
  var found = stateMachine.find(
    (dna) => dna.caseType === caseType && dna.subType === (subType || "none")
  );
  let failedChecks = [];
  if (found) {
    const currentState = (true) ? authSelector.system.currentState : found; //tofix-make it to current state only
    Object.entries(currentState.fieldsRequired).forEach((entry) => {
      var caseName = entry[0];
      var isRequired = entry[1];
      if (isRequired) {
        if (findCaseSelector.data[caseName]) {
          //passed the check
        } else if (caseDetailsSelector.data[caseName]) {
        } else {
          //failed the check
          failedChecks.push(caseName);
        }
      }
    });

    // found.fieldsRequired.sCRMID == findCaseSelector.data.sCRMID;
    // found.fieldsRequired.account == caseDetailsSelector.data.account;
    // found.fieldsRequired.caseName = caseDetailsSelector.data.caseName;
    // found.fieldsRequired.caseCategory = caseDetailsSelector.data.caseCategory;
    // found.fieldsRequired.public_funding_required == caseDetailsSelector.data.public_funding_required;
    // found.fieldsRequired.sales_channel=caseDetailsSelector.data.sales_channel,
    // found.fieldsRequired.endCustomer= caseDetailsSelector.data.endCustomer,
    // found.fieldsRequired.country=caseDetailsSelector.data.country,
    //   found.fieldsRequired.market= caseDetailsSelector.data.market,
    //   found.fieldsRequired.shipping_country=caseDetailsSelector.data.shipping_country,
    //   found.fieldsRequired.currency=caseDetailsSelector.data.currency,
    //   found.fieldsRequired.summary=caseDetailsSelector.data.summary,
    //   found.fieldsRequired.co_owner=caseDetailsSelector.data.co_owner,
  }
  return failedChecks;
};

export const handleGetKeyFromObj = (
  array,
  ref,
  refKey = "id",
  refValue = "value"
) => {
  try {
    if (array.length > 0 && ref) {
      var val = array.find((z) => z[refKey] === ref)[refValue];

      return val;
    }
    return "None";
  } catch (error) {
    return "None";
  }
};

export const handleGetValueFromArray = (array, ref) => {
  if (array.length > 0 && ref) {
    var val = array.find((z) => z[0] === ref)[1];
    return val;
  }
  return "None";
};

/**
 * Fetches the user's access role from the Datamart API and updates the application state.
 * 
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
const getUserAccessRole = async (dispatch) => {
  // Make the API call to get the user role
  DATAMART_API.getUserAccessRole().then((response) => {
    // Authentication is successful, hide the loader
    dispatch(showAuthLoader(false));
    if (response === undefined) {
      // Set the user authorization flag to false
      dispatch(setUserAuthorization(false));
    }
    else {
      // Set the user authorization flag to true
      dispatch(setUserAuthorization(true));
      // Based on the response, set the user role and isApprover flag
      dispatch(setDatamartUserRole({ datamartRole: response?.["role"]?.roles, isApprover: response?.["role"]?.roles.includes("approver") }));
    }
  }).catch((error) => {
    // Set the user authorization flag to false
    dispatch(setUserAuthorization(false));
  });
}