import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import { FilterField, Tooltip, VerticalDivider } from "@nokia-csf-uxr/ccfk";
import { FilterFieldIcon } from "@nokia-csf-uxr/ccfk/FilterField";
import { OFFER_SORT_OPTIONS } from "features/dashboard/utils/consstants";
import { setProperty } from "features/dashboard/store/dashboardSlice";
import SelectField from "Components/common/SelectField";
import { LabelHelpIcon } from "@nokia-csf-uxr/ccfk/Label";

const SearchOffer = () => {
  const inputRef = useRef(null);
  // Component state
  const [searchValue, setSearchValue] = useState("");
  // Sectors
  const dashboardSelector = useSelector((state: RootState) => state.dashboard);
  // Hooks
  const dispatch = useDispatch();

  // Reset the search options
  const resetSearchOptions = useCallback(() => {
    dispatch(setProperty({ key: "offerSearch", value: "" }));
    setSearchValue("");
    dispatch(
      setProperty({
        key: "pagination",
        value: {
          ...dashboardSelector.data?.pagination,
          currentPage: 1,
        },
      })
    );
    inputRef.current && inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (!dashboardSelector.data?.["selectedSortOption"]) {
      updateSortOption(OFFER_SORT_OPTIONS[0], true);
    }
  }, []);

  useEffect(() => {
    if (dashboardSelector.data.caseType) {
      resetSearchOptions();
    }
  }, [dashboardSelector.data.caseType, resetSearchOptions]);

  const updateSortOption = (option, isLoad = false) => {
    if (!isLoad) {
      dispatch(
        setProperty({
          key: "pagination",
          value: { ...dashboardSelector.data?.pagination, currentPage: 1 },
        })
      );
    }
    dispatch(setProperty({ key: "selectedSortOption", value: option }));
  };



  // const resetSearchOptions = () => {
  //   dispatch(setProperty({ key: "offerSearch", value: "" }));
  //   setSearchValue("");    
  //   dispatch(
  //     setProperty({
  //       key: "pagination",
  //       value: {
  //         ...dashboardSelector.data?.pagination,
  //         currentPage: 1,
  //       },
  //     })
  //   );
  //   inputRef.current && inputRef.current.focus();
  // };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FilterField
          value={searchValue}
          onChange={(e) => {
            console.log(e);
            setSearchValue(e.target.value);
            //dispatch(setProperty({ key: "offerSearch", value: e.target.value }));
          }}
          textInputProps={{
            placeholder: "Type search keyword and press enter to search",
            showClearButton: true,
            clearButtonProps: {
              onClick: () => {
                resetSearchOptions();
              },
            },
            inputProps: {
              ref: inputRef,
              "aria-label": "Search",
              onKeyUp: (e) => {
                if (e.key === "Enter" || e.keyCode === 13) {
                  dispatch(
                    setProperty({ key: "offerSearch", value: searchValue })
                  );
                  dispatch(
                    setProperty({
                      key: "pagination",
                      value: {
                        ...dashboardSelector.data?.pagination,
                        currentPage: 1,
                      },
                    })
                  );
                }
              },
            },
          }}
          renderIcon={<FilterFieldIcon />}
          variant="outlined"
          style={{ width: "350px" }}
        />
        <Tooltip 
          placement="top" 
          trigger="hover" 
          tooltip={"Serach by sCRM ID, Account name, or Case name. Hit enter to search"}
        >
          <LabelHelpIcon />
        </Tooltip>
      </div>
      <VerticalDivider />
      <div className="dsh-ft-sort">
        <SelectField
          labelText={"Sort by"}
          optionData={OFFER_SORT_OPTIONS}
          selectedItem={
            dashboardSelector?.data?.["selectedSortOption"]
              ? dashboardSelector?.data?.["selectedSortOption"]
              : OFFER_SORT_OPTIONS[0]
          }
          setSelectedItem={updateSortOption}
          style={{ width: "180px" }}
        />
      </div>
    </>
  );
};

export default SearchOffer;
