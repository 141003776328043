/**
 * Custom hook `useDatamart` to handle operations related to Datamart.
 *
 * This hook provides functionalities to interact with the Datamart API,
 * including saving Bill of Quantities (BoQ) data.
 *
 * @returns {Object} - An object containing the `saveBoQInDatamart` function.
 *
 * @function
 * @name useDatamart
 *
 * @example
 * const { saveBoQInDatamart } = useDatamart();
 */

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "Store/mainStore";

import DATAMART_API from "Constants/services/datamart";
import { setError } from "Store/general/auth";

const useDatamart = () => {
  // Selector
  const cartBoqSelector = useSelector((state: RootState) => state.cartBoq);
  const findCaseSelector = useSelector((state: RootState) => state.findCase);
  const caseDetailsSelector = useSelector(
    (state: RootState) => state.caseDetails
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);

  const dispatch = useDispatch();

  /*
   * @function
   * @name getCartSummary
   * @description Retrieves a summary of the cart by combining system and case details data.
   * @returns {CartSummary} - The summary of the cart.
   * 
   * @typedef {Object} CartSummary
   * @property {Object} findCase data - System information from the findCase selector.
   * @property {Object} data - Case details data from the case details selector.
   */
  const getCartSummary = () => {
    const cartSummary = {
      ...findCaseSelector?.data,
      ...caseDetailsSelector?.data,
    };
    return cartSummary;
  };

  /**
   * @function
   * @name saveBoQInDatamart
   * @description Asynchronously saves the Bill of Quantities (BoQ) data in the Datamart.
   * If the BoQ data is available, it calls the Datamart API to save the data.
   * Handles errors and dispatches error messages if the API call fails.
   *
   * @typedef {Object} CartDataList
   * @property {Object} cart - Cart information including summary and items.
   * @property {string} temp_offer_id - sID.
   * @property {string} source - Source of the data, e.g., "cui".
   * @property {string} status - Status of the cart, e.g., "draft".
   * @property {Object} ui_json - user selection options JSON data - dynamic.element & dynamic.modal redux store.
   */
  const saveBoQInDatamart = async () => {
    // const boqData = dynamicSelector.data.boq;
    const boqData = cartBoqSelector?.data?.cart?.items;
    // Call API to save BoQ in datamart incase BoQ data is available
    if (boqData.length > 0) {
      try {
        // Call API to save BoQ in datamart
        const temp_offer_id = dynamicSelector?.data?.sid;
        const cartDataList = {
          cart: {
            cart_summary: getCartSummary()
            // cart_items: boqData,----------- commented to store only cart_summary from cart column as ui_json has already cart boq items data
          },
          // bookmark_items: [],
          temp_offer_id: temp_offer_id,
          source: "cui", // yet to add this column in table
          status: "draft", //draft or submitted
          ui_json : getAllStates_uiJson()
        };
        const datamartResp = await DATAMART_API.saveCartDetails(cartDataList);
        if (!datamartResp.status) {
          dispatch(
            setError({
              key: "warn",
              value: {
                errorCode: null,
                errorMsg: datamartResp?.errorMessage
                  ? `The BoQ has not been saved in the datamart: ${datamartResp?.errorMessage}`
                  : "Something went wrong while saving BoQ in Datamart",
              },
            })
          );
        }
      } catch (e) {
        dispatch(
          setError({
            key: "warn",
            value: {
              errorCode: null,
              errorMsg: "Something went wrong while saving BoQ in Datamart",
            },
          })
        );
      }
    }
  };


  const getAllStates_uiJson = () => {
    const allStateData = _.cloneDeep(store.getState());
    delete allStateData['authSlice'];
    return allStateData;
  }

  return {
    saveBoQInDatamart,
  };
};

export default useDatamart;
