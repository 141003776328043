import React from "react";
import { useSelector } from "react-redux";
import { Button, ButtonsRow } from "@nokia-csf-uxr/ccfk";
import { RootState } from "Store/mainStore";
import _ from "lodash";
import { ButtonIcon } from "@nokia-csf-uxr/ccfk/IconButton";
import AddIcon from "@nokia-csf-uxr/ccfk-assets/legacy/AddIcon";
import { ButtonLoadingIndicator, ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import AddCartBtn from "Components/Elements/AddCartBtn";
import { useProductDataList } from "../hooks/useProductDataList";

interface IActionButtons {
  addAccessoriesButtonStatus: boolean;
  prodCatalogApiLoad: boolean;
  addCartButtonStatus: boolean;
  activePackages: {};
  // getDataProductCataLog: (string) => void;
  setApplicationCount: () => void;
  allRefs: {}
}

const ActionButtons = ({
  addAccessoriesButtonStatus,
  prodCatalogApiLoad,
  addCartButtonStatus,
  activePackages,
  setApplicationCount,
  allRefs
}: IActionButtons) => {
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const { getDataProductCataLog } = useProductDataList();
  let showActionButtons = !["cmu"].includes(configstepperSelector.data.solution_selected);

  const solutionCategory = configstepperSelector.data.solutionCategory;
  const solutionType = configstepperSelector.data.solutionType;

  if (solutionCategory === 'MPW' && solutionType !== 'Standalone' && _.includes(["connectivity"], configstepperSelector.data.active_step)) {
    showActionButtons = false;
  }
  const getDisplayStyle = () => {
    return (
      (configstepperSelector.data.solution_selected !== "ndac_compact" &&
        _.includes(["devices", "connectivity"], configstepperSelector.data.active_step)) ||
      (configstepperSelector.data.solution_selected === "ndac_compact" &&
        configstepperSelector.data.active_step === "devices")
    ) ? "block" : "none";
  };

  return (
    <ButtonsRow>
      {showActionButtons && (
        <div className="mt">
          <Button
            variant="neutral"
            disabled={addAccessoriesButtonStatus || prodCatalogApiLoad}
            style={{ display: getDisplayStyle() }}
            onClick={() => {
              getDataProductCataLog("");
            }}
          >
            <ButtonIcon>
              <AddIcon />
            </ButtonIcon>
            <ButtonText>
              {configstepperSelector.data.active_step === "devices"
                ? "Add Accessories"
                : "Add from Catalog"}
            </ButtonText>
            {prodCatalogApiLoad && <ButtonLoadingIndicator variant="neutral" />}
          </Button>
        </div>
      )}
      <AddCartBtn
        isLoading={addCartButtonStatus}
        activePackage={activePackages}
        allRefs={allRefs}
        onAction={() => {
          setApplicationCount();
        }}
      />
    </ButtonsRow>
  );
};

export default ActionButtons;
