import React, { useEffect } from "react";
import { Button } from "@nokia-csf-uxr/ccfk";
import { RootState } from "Store/mainStore";
import { useDispatch, useSelector } from "react-redux";
import { useLoadUI } from "./useLoadUI";
import { ButtonIcon } from "@nokia-csf-uxr/ccfk/IconButton";
import FindIcon from "@nokia-csf-uxr/ccfk-assets/legacy/FindIcon";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import { useConfigurator } from "./useConfigurator";
import { useDataCallBack } from "./useDataCallback";
import { useLocalStateManagement } from "./useLocalStateManagement";
import { setProp } from "Store/Actions";
import _ from "lodash";
import { useActivePackages } from "./useActivePackages";

export const useSeachBlock = (allRefs) => {
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const themeSelector = useSelector((state: RootState) => state.theme);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const fieldsDisableEnable = themeSelector.data.button.isDisable.options_global;
  const dispatch = useDispatch();
  const solutionCategory = configstepperSelector.data.solutionCategory;
  const solutionType = configstepperSelector.data.solutionType;

  const { getClassBlock, getFieldStateBasedOnStepState } = useConfigurator();
  const { setDataCallBack_Targets, checkDataCallback } = useDataCallBack(allRefs);
  const { setState } = useLocalStateManagement();
  const { LoadElements } = useLoadUI(allRefs);
  const { getActivePackagesData } = useActivePackages();

  let searchBlock = getClassBlock();
  let searchButtonStyle = {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
  };

  useEffect(() => {
    const activePackages = getActivePackagesData();
    setDataCallBack_Targets(activePackages);
  }, [configstepperSelector.data.active_step]);

  const getSearchDetails = (activePackages) => {
    dispatch(setProp({ slice: "dynamic", key: `setAccessoriesButton`, value: false }));

    Object.entries(activePackages).forEach(function (item) {
      if (item[1]['data_callback'] && (item[1]['data_callback']['button'] || (item[1]['data_callback']['event'] && item[1]['data_callback']['event']['type'] === 'onchange'))) {
        let value = dynamicSelector.data.element[configstepperSelector.data.active_step][item[0]];

        // hard coded - we dont want to call api for catalog items here
        if (!_.includes(["catalog_items", "ndac_accessories"], item[0])) {
          checkDataCallback(item, value, item[0], false);
        }
      }
    });
  }

  /** search_submitButton_status */
  const search_submitButton_status = (deviceBlock, fieldId?) => {
    // Check step state before considering the fieldsDisableEnable
    const isStepDisabled = getFieldStateBasedOnStepState();
    let buttonStatus = isStepDisabled && isStepDisabled=== true ? isStepDisabled : fieldsDisableEnable;
    if (configstepperSelector.data.solution_selected === 'cdm_ug_ndac' && configstepperSelector.data.active_step === 'connectivity' && fieldId === 'das_equipment') {
      const searchBlockFieldsGroup1 = configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step] && configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step].miningSearchGroup1;
      const buttonStatus1 = getButtonStatusMiningGroup(searchBlockFieldsGroup1, buttonStatus);

      const searchBlockFieldsGroup2 = configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step] && configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step].miningSearchGroup2;

      const buttonStatus2 = getButtonStatusMiningGroup(searchBlockFieldsGroup2, buttonStatus);
      // if either of the group is filled then enable the search button
      buttonStatus = !buttonStatus1 || !buttonStatus2 ? false : true;
    }
    else {
      Object.entries(JSON.parse(JSON.stringify(deviceBlock))).forEach(function (item) {
        if (dynamicSelector.data.element[configstepperSelector.data.active_step]) {
          if (!dynamicSelector.data.element[configstepperSelector.data.active_step][item[0]] || dynamicSelector.data.element[configstepperSelector.data.active_step][item[0]].length === 0) {
            buttonStatus = true
          }
        }
      });
      setState('setSearchButtonStatus', buttonStatus);
    }
    return buttonStatus;
  }

  const getButtonStatusMiningGroup = (searchBlockFields, searchBtn) => {
    Object.entries(JSON.parse(JSON.stringify(searchBlockFields))).forEach(function (item) {
      if (dynamicSelector.data.element[configstepperSelector.data.active_step] && !dynamicSelector.data.element[configstepperSelector.data.active_step][item[1]]) { 
        if(_.isObject(dynamicSelector.data.element[configstepperSelector.data.active_step][item[1]]) && _.isEmpty(dynamicSelector.data.element[configstepperSelector.data.active_step][item[1]])) {
          searchBtn = true;
        } else if(dynamicSelector.data.element[configstepperSelector.data.active_step][item[1]].length === 0) {
          searchBtn = true;
        }
      }
    });
    return searchBtn;
  }

  /** Rerender search block */
  const renderSearchBlock = (activePackages, renderConfig = undefined, dasSearchButton = undefined, fieldId = undefined) => {
    const deviceBlock = dynamicSelector.data.dataFromPackage['deviceBlock'];
    const searchElements = JSON.parse(JSON.stringify(deviceBlock));
    const wrapPresent = configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step] && configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step].wrapAfter && (configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step].wrapAfter).some(r => (_.includes(_.keys(deviceBlock), r)));

    // Make a copy of the search block
    const __searchBlock = _.cloneDeep(searchBlock);
    // if wrapAfter is set for the grouping then add the css properties to that element
    if (configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step] && configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step].wrapAfter && wrapPresent) {
      __searchBlock['flexWrap'] = 'wrap';
    }

    return (
      <div style={__searchBlock}>
        <>{!dasSearchButton && LoadElements(searchElements, activePackages, renderConfig)}</>
        <div style={searchButtonStyle}>
          <Button
            disabled={search_submitButton_status(deviceBlock, fieldId)}
            variant="neutral"
            onClick={() => {
              if (
                _.includes(["NDAC", "MPW"], solutionCategory) &&
                (configstepperSelector.data.active_step === "devices" ||
                  configstepperSelector.data.active_step === "connectivity")
                && solutionType !== 'Standalone'
              ) {
                getSearchDetails(activePackages);
              }
            }}
          >
            <ButtonIcon>
              <FindIcon />
            </ButtonIcon>
            <ButtonText>Search</ButtonText>
          </Button>
        </div>
      </div>
    );
  };

  return {
    renderSearchBlock
  }
}